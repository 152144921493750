<template>
  <div class="login-complete">
    <div>{{examName}}</div>
    <Button style="margin-bottom: .2rem; padding: .2rem;height: auto"
            @click="completed"
            type="success">完成
    </Button>
  </div>
</template>

<script>
  import {Button, Message} from "view-design";
  import {apiWxBind, apiWxLogin} from "../../request/api";
  // 判断是否为公众号模拟器环境
  const isWechat = () => {
    return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
  }
  export default {
    components: {
      Button,
    },
    data() {
      return {
        code: '',
        examName: {},
      }
    },
    created() {
      this.$emit('header', false);
      this.$emit('footer', false);
      this.examName = localStorage.getItem("exam_name");

      this.getWxCode();

    },
    mounted() {

    },
    methods: {

      getWxCode() {
        if (isWechat()) {
          let appid = this.GLOBAL.appid; //为测试号id
          let code = this.$route.query.code;//是否存在code
          let local = window.location.href;
          // let local = 'http://h5.x*****o.com/'		//测试路径
          if (code == null || code === "") {
            //不存在就打开上面的地址进行授权
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          } else {
            this.code = code
          }
        }
      },
      completed() {
        apiWxLogin({
          code: this.$route.query.code,
          exam_id: this.$route.query.exam_id,
          client_id: this.$route.query.random
        }).then((res) => {

          if (res.code == 1) {
            Message.info(res.msg);
          } else {
            Message.info(res.msg);
          }
        })
      }
    },
    destroyed() {
    }
  }
</script>

<style lang="less" scoped>
  .login-complete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem .1rem;

    & > div {
      text-align: center;
      padding-bottom: .3rem;
    }
  }
</style>
