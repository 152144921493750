<template>
  <div class="post-inquiry" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->


    <!--    tabBar-->
    <div class="post-inquiry-tabs">
      <div v-for="(item,index) in tabs" class="post-inquiry-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'岗位查询'" style="margin:0 0 .3rem"></PageTitle>

    <!--    <div class="post-inquiry-select_input">-->
    <!--      <input placeholder="请输入岗位代码、岗位名称、考试科目等进行查询"></input>-->
    <!--      <img src="../../assets/images/search_icon.png">-->
    <!--    </div>-->
    <div class="select-card">
      <!--      <div class="select-card-item">-->
      <!--        <div class="select-card-item-tip">所属地区</div>-->
      <!--        <div class="select-card-item-box">-->
      <!--          <SelectPage :selectList="cityNameList" :no-border="'none'" @changeSelectedItem="changeCityName"></SelectPage>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="select-card-item">
        <div class="select-card-item-tip">单位代码</div>
        <input placeholder="" v-model="unitCode"></input>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">单位名称</div>
        <input v-model="unitName"></input>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">单位性质</div>
        <div class="select-card-item-box" style="overflow: inherit">
          <!--          <Select v-model="unitNature" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding: 0 0 .1rem .04rem;">-->
          <!--            <Option v-for="item in unitNatureList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="unitNatureList" :no-border="'none'"
                      @changeSelectedItem="changeUnitNatureItem"></SelectPage>
        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">岗位代码</div>
        <input v-model="postCode" placeholder=""></input>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">岗位名称</div>
        <input v-model="postName"></input>
      </div>
      <!--      <div class="select-card-item">-->
      <!--        <div class="select-card-item-tip">考试科目</div>-->
      <!--        <div class="select-card-item-box">-->
      <!--          <Select v-model="examinationSubjects" placeholder="未选择"-->
      <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
      <!--            <Option v-for="item in examinationSubjectsList" :value="item" :key="item">{{ item }}</Option>-->
      <!--          </Select>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="select-card-item">
        <div class="select-card-item-tip">学历类别</div>
        <div class="select-card-item-box">
          <!--          <Select v-model="educationType" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
          <!--            <Option v-for="item in educationTypeList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="educationTypeList" :no-border="'none'"
                      @changeSelectedItem="changeEducationType"></SelectPage>

        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">学历要求</div>
        <div class="select-card-item-box">
          <!--          <Select v-model="educationRequirements" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
          <!--            <Option v-for="item in educationRequirementsList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="educationRequirementsList" :no-border="'none'"
                      @changeSelectedItem="changeEducationRequirements"></SelectPage>

        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">学位要求</div>
        <div class="select-card-item-box">
          <!--          <Select v-model="degreeRequirements" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
          <!--            <Option v-for="item in degreeRequirementsList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="degreeRequirementsList" :no-border="'none'"
                      @changeSelectedItem="changeDegreeRequirements"></SelectPage>

        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">性别要求</div>
        <div class="select-card-item-box">
          <!--          <Select v-model="sex" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
          <!--            <Option v-for="item in sexList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="sexList" :no-border="'none'" @changeSelectedItem="changeSex"></SelectPage>

        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">民族要求</div>
        <div class="select-card-item-box">
          <!--          <Select v-model="nationItem" placeholder="未选择"-->
          <!--                  style="width: 1.44rem; height: .28rem;padding-left: .04rem;">-->
          <!--            <Option v-for="item in nationList" :value="item" :key="item">{{ item }}</Option>-->
          <!--          </Select>-->
          <SelectPage :selectList="nationList" :no-border="'none'" @changeSelectedItem="changeNationItem"></SelectPage>

        </div>
      </div>
      <div class="select-card-item">
        <div class="select-card-item-tip">专业名称</div>
        <input v-model="majorName"></input>
      </div>
      <div class="select-card-item more">
        <div class="select-card-item-tip">招收人数要求</div>
        <input style="display: inline-block;width: 50%" v-model="moreThan" placeholder="数组"></input>
      </div>
      <div class="select-card-item more more-right">
        <div class="select-card-item-tip">考生年龄要求</div>
        <!--        <input v-model="candidatesOlderThan" placeholder="2位数字"></input>-->
        <div class="select-card-item-box">
          <SelectPage :selectList="ageLimitList" :no-border="'none'" style="width: 100%"
                      @changeSelectedItem="changeAgeLimitItem"></SelectPage>
        </div>
      </div>
      <img src="../../assets/images/start_query_btn.png" @click="selectJobList">
    </div>
    <div v-if="jobList.length!==0" class="post-inquiry-tables">

      <table border="1" cellspacing="0" v-for="(item,index) in jobList" :key="index">
        <tbody>
        <tr>
          <td class="post-inquiry-tables-tip" style="white-space:nowrap">单位代码</td>
          <td>{{item.unit_code}}</td>
          <td class="post-inquiry-tables-tip" style="white-space:nowrap">单位名称</td>
          <td>{{item.unit_name}}</td>
        </tr>
        <tr>
          <td class="post-inquiry-tables-tip">单位性质</td>
          <td>{{item.unit_nature_text}}</td>
          <td class="post-inquiry-tables-tip">岗位代码</td>
          <td>{{item.position_code}}</td>
        </tr>
        <tr>

          <td class="post-inquiry-tables-tip">岗位名称</td>
          <td>{{item.job_title}}</td>
          <td class="post-inquiry-tables-tip">招收人数</td>
          <td>{{item.job_count}}</td>
        </tr>
        <tr>

          <td class="post-inquiry-tables-tip">户籍要求</td>
          <td>{{item.household_register}}</td>
          <td class="post-inquiry-tables-tip">性别要求</td>
          <td>{{item.sex_text}}</td>
        </tr>
        <tr>

          <td class="post-inquiry-tables-tip">民族要求</td>
          <td>{{item.nation_limit_text}}</td>
          <td class="post-inquiry-tables-tip">学历类别</td>
          <td>{{item.degree_category_text}}</td>
        </tr>
        <tr>

          <td class="post-inquiry-tables-tip">政治面貌</td>
          <td>{{item.politics_text}}</td>
          <td class="post-inquiry-tables-tip">学历要求</td>
          <td>{{item.academic_degre_text}}</td>
        </tr>
        <tr>


          <td class="post-inquiry-tables-tip">学位要求</td>
          <td>{{item.degree_limit_text}}</td>
          <td class="post-inquiry-tables-tip">应届要求</td>
          <td>{{item.is_current_text}}</td>
        </tr>
        <tr>

          <td class="post-inquiry-tables-tip">年龄要求</td>
          <td>{{item.age_limit_text}}</td>
          <td class="post-inquiry-tables-tip">专门岗位</td>
          <td colspan="3">{{item.special_job_text}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td class="post-inquiry-tables-tip">所需专业</td>
          <td colspan="3">{{item.major_limit}}
          </td>
        </tr>
        <tr>
          <td class="post-inquiry-tables-tip">其他要求</td>
          <td colspan="3">{{item.other_limit}}</td>
        </tr>
        <tr>
          <td class="post-inquiry-tables-tip">备 注</td>
          <td colspan="3">{{item.remark}}</td>
        </tr>
        <tr>
          <td class="post-inquiry-tables-tip">联系方式</td>
          <td colspan="3">{{item.contact}}</td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: center" @click="openNewPostpopup(item.id)">
            <Button type="primary" style="background: #1f4db6;">报考此岗位</Button>
          </td>
        </tr>
        </tfoot>
      </table>

      <!-------------------------------------报考此岗位弹窗---------------------------------------------->
      <Modal
        v-model="isOpenNewPostpopup"
        title="报考此岗位"
        width="940">
        <table border="1" cellspacing="0" class="registration-position">
          <tr>
            <td class="post-inquiry-tables-tip" width="auto" style="white-space:nowrap">单位名称(代码)</td>
            <td>
              {{jobInfo.unit_name}}
              <span v-if="jobInfo.unit_code!=='' && jobInfo.unit_code!=null">
                    ({{jobInfo.unit_code}})</span>
            </td>
            <td class="post-inquiry-tables-tip">姓名</td>
            <td width="auto" style="white-space:nowrap">{{userInfo.real_name}}</td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip" width="auto" style="white-space:nowrap">岗位名称(代码)</td>
            <td>
              {{jobInfo.job_title}}
              <span v-if="jobInfo.position_code!=='' && jobInfo.position_code!=null">
                    ({{jobInfo.position_code}})</span>
            </td>
            <td colspan="2">
              <div>
                身份证号：{{userInfo.id_cards}}
              </div>
              <span v-if="userInfo.birthday">
                生日：{{userInfo.birthday.slice(0,4)+'-'+userInfo.birthday.slice(4,6)+'-'+userInfo.birthday.slice(6,8)}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">
              户籍要求
            </td>
            <td>{{jobInfo.household_register}}</td>
            <td>{{userInfo.huji}}</td>
            <td
              :class="{'pass':checkInfo.household_register==='满足要求','not-pass':checkInfo.household_register!=='满足要求'}">
              {{checkInfo.household_register}}
            </td>
          </tr>

          <tr>
            <td class="post-inquiry-tables-tip">性别要求</td>
            <td>{{jobInfo.sex_text}}</td>
            <td>{{userInfo.sex_text}}</td>
            <td :class="{'pass':checkInfo.sex_limit==='满足要求','not-pass':checkInfo.sex_limit!=='满足要求'}">
              {{checkInfo.sex_limit}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">民族要求</td>
            <td>{{jobInfo.nation_limit_text}}</td>
            <td>{{userInfo.minzu_text}}</td>
            <td :class="{'pass':checkInfo.nation_limit==='满足要求','not-pass':checkInfo.nation_limit!=='满足要求'}">
              {{checkInfo.nation_limit}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">政治面貌要求</td>
            <td>{{jobInfo.politics_text}}</td>
            <td>{{userInfo.politics_text}}</td>
            <td :class="{'pass':checkInfo.politics==='满足要求','not-pass':checkInfo.politics!=='满足要求'}">
              {{checkInfo.politics}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">学历类别要求</td>
            <td>{{jobInfo.degree_category_text}}</td>
            <td>{{userInfo.degree_category_text}}</td>
            <td :class="{'pass':checkInfo.degree_category==='满足要求','not-pass':checkInfo.degree_category!=='满足要求'}">
              {{checkInfo.degree_category}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">学历要求</td>
			<td>{{jobInfo.academic_degre_text}}</td>
            
            <td>{{userInfo.education_text}}</td>
           
			<td :class="{'pass':checkInfo.academic_degre==='满足要求','not-pass':checkInfo.academic_degre!=='满足要求'}">
			  {{checkInfo.academic_degre}}
			</td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">学位要求</td>
            <td>{{jobInfo.degree_limit_text}}</td>
            <td>{{userInfo.academic_degree_text}}</td>
           <td :class="{'pass':checkInfo.degree_limit==='满足要求','not-pass':checkInfo.degree_limit!=='满足要求'}">
             {{checkInfo.degree_limit}}
           </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">应届要求</td>
            <td>{{jobInfo.is_current_text}}</td>
            <td>{{userInfo.user_origin_text}}</td>
            <td :class="{'pass':checkInfo.is_current==='满足要求','not-pass':checkInfo.is_current!=='满足要求'}">
              {{checkInfo.is_current}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">年龄要求</td>
            <td>{{jobInfo.age_limit_text}}</td>
            <td>{{userInfo.age}}</td>
            <td :class="{'pass':checkInfo.age_limit==='满足要求','not-pass':checkInfo.age_limit!=='满足要求'}">
              {{checkInfo.age_limit}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">专门岗位要求</td>
            <td>{{jobInfo.special_job_text}}</td>
            <td>{{userInfo.user_origin_text}}</td>
            <td width="auto" style="white-space:nowrap"
                :class="{'pass':checkInfo.special_job==='满足要求','not-pass':checkInfo.special_job!=='满足要求'}">
              {{checkInfo.special_job}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">专业要求</td>
            <td>{{jobInfo.major_limit}}</td>
            <td>{{userInfo.major}}</td>
            <td :class="{'pass':checkInfo.major_limit==='满足要求','not-pass':checkInfo.major_limit!=='满足要求'}">
              {{checkInfo.major_limit}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">其他要求</td>
            <td colspan="2">{{jobInfo.other_limit}}</td>
            <td :class="{'pass':checkInfo.other_limit==='满足要求','not-pass':checkInfo.other_limit!=='满足要求'}">
              {{checkInfo.other_limit}}
            </td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">备 注</td>
            <td colspan="3">{{jobInfo.remark}}</td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">联系方式</td>
            <td colspan="3">{{jobInfo.contact}}</td>
          </tr>
          <tr>
            <td class="post-inquiry-tables-tip">报考补充信息</td>
            <td colspan="3">
              <textarea maxlength="200" v-model="supplementaryInformation"></textarea>
              <div>请填写获得的有关资质材料（如职称证书，通过司法考试，英语、计算机等级等），若是少数民族请填写具体民族，若是民主党派请说明具体党派，限200字</div>
            </td>
          </tr>
          <tr>
            <td>选择考试地</td>
            <td colspan="3">
              <!--                            <Select v-model="examinationLocation" placeholder="&#45;&#45;选择考试地	&#45;&#45;"-->
              <!--                                    style="width: 25%;padding-top:.04rem;">-->
              <!--                              <Option v-for="item in examinationLocationList" :value="item" :key="item">{{ item }}</Option>-->
              <!--                            </Select>-->
              <div class="address-select">
                <SelectPage :selectList="examinationLocationList" :no-border="'none'"
                            @changeSelectedItem="changeExaminationLocation"></SelectPage>
              </div>

            </td>
          </tr>
        </table>
        <div slot="footer">
          <Button :loading="isSwitchStatus"  :disabled="isSwitchStatus" type="primary" style="background: #1f4db6;"
                  size="large" long @click="toExamineeInformation(jobInfo.id)">
            添加到报考岗位列表
          </Button>
        </div>
      </Modal>
    </div>
    <div v-if="jobList.length!==0" class="choice-page-number">
      <PageBtns :pageLength="totalPage"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
    <NoData v-if="jobList.length===0" :dataTip="'暂无数据'"></NoData>
  </div>
</template>

<script>
  import {Button, Drawer, Message, Modal, Option, Select} from 'view-design';
  import {apiAddJobList, apiApplyForExam, apiGetJobConfig, apiGetJobList} from "../../request/api";
  import PageBtns from "../../components/PageBtns";
  import PageTitle from "../../components/PageTitle";
  import NoData from "../../components/NoData";
  import SelectPage from "../../components/SelectPage";
  import menuListOpen from "../../components/menuListOpen";

  export default {
    components: {
      Select,
      Option,
      PageBtns,
      Button,
      Modal,
      Drawer,
      NoData,
      PageTitle,
      SelectPage,
      menuListOpen,
    },
    data() {
      return {

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        tabs: this.GLOBAL.examHeaderTabs,
        jobList: [],
        jobListNodata: false,
        currentTables: [],
        nowIndex: 5,

        //总页数
        totalPage: 0,
        //所属地区
        cityName: "",

        //所属地区
        cityNameList: "",

        //单位代码
        unitCode: "",

        //单位名称
        unitName: "",

        //单位性质
        unitNature: "",

        unitNatureId: '',

        //单位性质列表
        unitNatureList: [],

        //岗位代码
        postCode: "",

        //岗位名称
        postName: "",

        //考试科目
        // examinationSubjects: "",

        //考试科目列表
        // examinationSubjectsList: [],

        //学历类别
        educationType: "",
        educationTypeId: "",

        //学历类别列表
        educationTypeList: [],

        //学历要求
        educationRequirements: "",
        educationRequirementsId: "",

        //学历要求列表
        educationRequirementsList: [],

        //学位要求
        degreeRequirements: "",
        degreeRequirementsId: "",

        //学位要求列表
        degreeRequirementsList: [],

        //性别要求
        sex: "",
        sexId: "",

        //性别要求列表
        sexList: [],

        //民族要求
        nationItem: "",
        nationId: "",

        //民族要求列表
        nationList: [],

        //专业名称
        majorName: "",

        //招收人数大于
        moreThan: 0,

        //年龄要求列表
        ageLimitList: [],
        //年龄要求
        ageLimitListItem: "",
        //考生年龄大于
        candidatesOlderThan: 0,

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 2,
        isOpenNewPostpopup: false,

        //考试地列表
        examinationLocationList: [],
        examinationLocation: "--选择考试地--",
        userInfo: {},
        checkInfo: {},
        jobInfo: {},

        //报考补充信息
        supplementaryInformation: "",
        isSwitchStatus: false,
      }
    },
    created() {

      // this.userInfo=JSON.parse(sessionStorage.getItem("userInfo"));
      this.pageInit();
      this.backToTop();
    },
    computed: {
      //改变当前页显示的列表
      // changeCurrentJobList() {
      //   // this.selectJobList();
      //   let {currentPage, pageSize} = this;
      //   return this.jobList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      // },
    },
    methods: {
      //获取配置项
      getConfig() {
        apiGetJobConfig().then((res) => {
          this.unitNatureList = [];
          this.sexList = [];
          this.educationTypeList = [];
          this.educationRequirementsList = [];
          this.nationList = [];
          this.degreeRequirementsList = [];
          this.ageLimitList = [];
          res.data.unit_nature.forEach((item, index) => {
            this.unitNatureList.push({
              value: item,
              id: index
            })
          });
          res.data.sexList.forEach((item, index) => {
            this.sexList.push({
              value: item,
              id: index
            })
          });
          res.data.degree_category.forEach((item, index) => {
            this.educationTypeList.push({
              value: item,
              id: index
            })
          });
          res.data.academic_degre.forEach((item, index) => {
            this.educationRequirementsList.push({
              value: item,
              id: index
            })
          });
          res.data.nation_limit.forEach((item, index) => {
            this.nationList.push({
              value: item,
              id: index
            })
          });
          res.data.degree_limit.forEach((item, index) => {
            this.degreeRequirementsList.push({
              value: item,
              id: index
            })
          });
          res.data.age_limit.forEach((item, index) => {
            this.ageLimitList.push({
              value: item,
              id: index
            })
          });
          // this.sexList = res.data.sexList;
          // this.educationTypeList = res.data.degree_category;
          // this.educationRequirementsList = res.data.academic_degre;
          // this.nationList = res.data.nation_limit;
          // this.degreeRequirementsList = res.data.degree_limit;
        });
      },
      //页面初始化
      pageInit() {
        this.getConfig();
        this.getJobList(1);
      },
      changeTabsIndex(index) {
        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:
            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:
            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:
            this.pageInit();

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:

            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      //所属地区
      changeCityName(item) {
        this.cityName = item.value
      },
      //单位性质
      changeUnitNatureItem(item) {
        this.unitNature = item.value;
        this.unitNatureId = item.id
      },
      //学历类别
      changeEducationType(item) {
        this.educationType = item.value;
        this.educationTypeId = item.id;
      },
      //学历要求
      changeEducationRequirements(item) {
        this.educationRequirements = item.value;
        this.educationRequirementsId = item.id;
      },
      //学位要求
      changeDegreeRequirements(item) {
        this.degreeRequirements = item.value;
        this.degreeId = item.id;
      },
      //性别
      changeSex(item) {
        this.sex = item.value;
        this.sexId = item.id;
      },
      //民族
      changeNationItem(item) {
        this.nationItem = item.value;
        this.nationId = item.id;
      },
      //民族
      changeAgeLimitItem(item) {
        this.ageLimitListItem = item.value;
        this.ageLimitListItemId = item.id;
      },
      //考试地
      changeExaminationLocation(item) {
        this.examinationLocation = item.value;
        this.examinationLocationId = item.id;
      },
      //点击开始查询按钮
      selectJobList() {
        // for (let key in this.educationTypeList) {
        //   if (this.educationTypeList[key] === this.educationType) {
        //     var educationTypeId = key
        //   }
        // }
        this.currentPage = 1;
        apiGetJobList({
          exam_id: this.$route.query.exam_id,
          shop_id: this.$route.query.shop_id,
          unit_code: this.unitCode,
          unit_name: this.unitName,
          unit_nature: this.unitNatureId,
          position_code: this.postCode,
          job_title: this.postName,
          degree_category: this.educationTypeId,
          academic_degre: this.educationRequirementsId,
          degree_limit: this.degreeId,
          sex: this.sexId,
          nation_limit: this.nationId,
          major_limit: this.majorName,
          job_count: this.moreThan,
          age_limit: this.ageLimitListItemId,
          page: 1
        }).then((res) => {
          this.getConfig();

          this.jobList = res.data.list;
          this.totalPage = res.data.page;

        })
      },
      toExamineeInformation(jobId) {
        this.isSwitchStatus = true;
        console.log('2121221221')
        if (localStorage.getItem('token' + "_" + this.$route.query.shop_id + "_"
          + this.$route.query.exam_id)) {

          apiAddJobList({
            user_id: localStorage.getItem('user_id' + "_" + this.$route.query.shop_id + "_"
              + this.$route.query.exam_id),
            job_id: jobId,
            content: this.supplementaryInformation,
            address: this.examinationLocationId,
          }).then((res) => {
            setTimeout(()=>{
              this.isSwitchStatus = false;
            },1000)
            if (res.code == 0) {
              Message.info(res.msg)
            }
            if (res.code == 1) {
              this.examinationLocationList = [];
              Message.info(res.msg)
              localStorage.setItem("isPostInquiryEnter", 'true')
              // this.$router.push({
              //   name: "examineeInformation",
              // })
              this.$router.push(`/examineeInformation/examineeInformation?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}&isPostInquiryEnter=true`)

            }
          })
        } else {

          this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

        }

      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        let _page = index + 1;
        apiGetJobList({
          exam_id: this.$route.query.exam_id,
          shop_id: this.$route.query.shop_id,
          page: _page
        }).then((res) => {

          this.jobList = res.data.list;
        });
        this.backToTop();
      },
      //点击报考此岗位按钮
      openNewPostpopup(job_id) {
        if (localStorage.getItem('token' + "_" + this.$route.query.shop_id + "_"
          + this.$route.query.exam_id)) {
          apiApplyForExam({
            user_id: localStorage.getItem('user_id' + "_" + this.$route.query.shop_id + "_"
              + this.$route.query.exam_id),
            exam_id: this.$route.query.exam_id,
            job_id: job_id,
          }).then((res) => {
            if (res.code == 0) {
              Message.info('考生信息未填写');

              this.$router.push(`/examineeInformation/examineeInformation?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            if (res.code == 1) {
              this.isOpenNewPostpopup = !this.isOpenNewPostpopup;

              if (res.data.switch == 1) {
                this.isSwitchStatus = true;
              } else {
                this.isSwitchStatus = false;
              }
              this.examinationLocationList = [];
              this.checkInfo = res.data.check;
              this.userInfo = res.data.user_info;
              this.jobInfo = res.data.job_info;

              Object.keys(res.data.address).forEach((key) => {
                this.examinationLocationList.push({
                  value: res.data.address[key],
                  id: key
                })
              })
            }
          })
        } else {
          Message.info('未登录不能报考，请登录后再报考');

          this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

        }

      },

      getJobList(_currentPage) {
        apiGetJobList({
          shop_id: this.$route.query.shop_id,
          exam_id: this.$route.query.exam_id,
          page: _currentPage
        }).then((res) => {
          if (res.data.list.length == 0 && res.data.list == null) {
            this.jobListNodata = true;
          }
          this.jobList = res.data.list;
          this.totalPage = res.data.page
        });
      },

      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen;
      },
    },
  }
</script>

<style lang="less" scoped>
  .post-inquiry {
    padding: 0 2rem .46rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    .select-card {
      display: flex;
      flex-wrap: wrap;
      /*padding: 0 .11rem;*/

      &-item {
        display: flex;
        align-items: center;
        width: 25%;
        height: .28rem;
        /*margin-right: .02rem;*/
        margin-bottom: .2rem;

        &-tip {
          display: flex;
          white-space: nowrap;
          /*justify-content: center;*/
          /*flex: 1 0 auto;*/
          padding-right: .08rem;
          font-size: .14rem;
        }

        &-box {
          display: flex;
          width: 60%;
          align-items: center;
          height: .28rem;
          font-size: .14rem;
          margin-right: .02rem;
          /*overflow: hidden;*/
          border: .02rem solid #C6CED4;
        }

        & > input {
          width: 60%;
          height: .28rem;
          font-size: .12rem;
          color: #515a6e;
          padding: 0 .04rem;
          border: .02rem solid #C6CED4;
          /*border-width: .02rem;*/
        }
      }

      .more {
        /*width: auto;*/
        /*padding-left: .1rem;*/
      }

      .more-right {
        /*margin-left: .2rem;*/
        display: flex;
        width: 30%;
        align-items: center;

        .select-card-item-tip {
          display: flex;
          /*flex: 1 0 auto;*/
        }

        .select-card-item-box {
          width: 1.5rem;
        }
      }

      & > img {
        width: 1.3rem;
        height: 0.3rem;
        margin-left: auto;
        padding-right: .28rem;
      }
    }

    &-tables {
      font-size: .14rem;
      color: #666666;

      table {
        width: 100%;
        margin-bottom: .14rem;

        td {
          padding: .1rem;
        }
      }

      &-tip {
        width: 1rem;
        background: #C5EBD8;
      }


    }


    .choice-page-number {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .registration-position {
    tr {
      td {
        font-size: .14rem;
        padding: .02rem .13rem;

        .address-select {
          width: 16%;
        }

        & > textarea {
          width: 40%;
          color: #666666;
          border-radius: .04rem;
          padding: .06rem;
        }
      }

      .pass {
        color: #23e181;
      }

      .not-pass {
        color: #F32547;
      }
    }

  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .post-inquiry {
      padding: .19rem .24rem .32rem;

      .post-inquiry-tabs {
        display: none;
      }

      /*&-select_input {*/
      /*  display: flex;*/
      /*}*/

      .post-inquiry-title {
        padding-bottom: .12rem;
        margin-bottom: .1rem;
      }

      .select-card {
        display: flex;
        flex-wrap: wrap;

        &-item {
          width: 50%;
          /*&-tip {*/
          /*  display: flex;*/
          /*  width: 25%;*/
          /*  justify-content: flex-start;*/
          /*}*/

          &-box {
            flex: 1;
            margin-right: .1rem;
          }

          & > input {
            flex: 1;
            margin-right: .1rem;
          }
        }

        .more {
          padding-left: 0;
        }

        .more-right {
          width: 50%;
          margin-left: 0;
        }

        & > img {
          /*margin-left: 0;*/
          width: 1.2rem;
          padding-right: .1rem;
          margin-bottom: .2rem;
        }
      }

      .post-inquiry-tables table td {
        padding: .08rem .05rem;
      }

      /*.registration-position {*/
      /*  tr {*/
      /*    td{*/
      /*      .address-select {*/
      /*        width: 30%;*/
      /*      }*/
      /*    }*/
      /*  }*/
      /*}*/


    }

    .registration-position {
      tr {
        td {
          font-size: .14rem;
          padding: .02rem .06rem;

          .address-select {
            width: 30%;
          }

          & > textarea {
            width: 100%;
            color: #666666;
            border-radius: .04rem;
            padding: .06rem;
          }
        }
      }

    }
  }
</style>
