<template>
	<div class="score-inquiry" v-wechat-title="$route.meta.title">

		<menuListOpen></menuListOpen>
		<Drawer placement="left" :closable="false" v-model="istabPopupOpen">
			<div class="tabs-popup">
				<div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
					@click="changeTabsIndex(index)" :key="index">{{item}}
				</div>
			</div>
		</Drawer>


		<!--    tabBar-->
		<div class="score-inquiry-tabs">
			<div v-for="(item,index) in tabs" class="score-inquiry-tabs-item" :class="{item_click:nowIndex==index}"
				@click="changeTabsIndex(index)" :key="index">{{item}}
			</div>
		</div>
		<PageTitle :message="'成绩查询'" style="margin:0 0 .3rem"></PageTitle>
		<div class="score-inquiry-main">

			<div class="score-inquiry-main-item">
				<div class="score-inquiry-main-item-tip">考试期次</div>

				<div class="score-inquiry-main-item-box">
					<SelectPage :selectList="scoreList" :noBorder="'none'" @changeSelectedItem="changeSelectedItem">
					</SelectPage>
					<!--          <Select v-model="scoreItem" style="padding: 0 0 .08rem .04rem;height: .28rem"-->
					<!--                  placeholder="未选择">-->
					<!--            <Option v-for="item in scoreList" :value="item.exam_name" :key="item.exam_name">{{ item.exam_name }}-->
					<!--            </Option>-->
					<!--          </Select>-->
				</div>

			</div>
			<div class="score-inquiry-main-item">
				<div class="score-inquiry-main-item-tip">准考证号</div>
				<input placeholder="请输入11位数的准考证号" minlength="15" maxlength="15" v-model="signUpNo"></input>
			</div>
			<div class="score-inquiry-main-item">
				<div class="score-inquiry-main-item-tip">身份证号</div>
				<input placeholder="请输入您的身份证号" v-model="idCard"></input>
			</div>
			<div class="score-inquiry-main-btn">
				<img src="../../assets/images/score_inquiry_btn.png" @click="toSelectScore">
			</div>
		</div>
		<table v-for="(item,index) in selectedInfo" border="1" :key="index" cellspacing="0" width="100%"
			v-show="selectedInfo!==[]&&selectedInfo.length!==0">
			<tr>
				<td>考试名称</td>
				<td>{{item.exam_info.exam_name}}</td>
			</tr>
			<tr>
				<td>报考岗位</td>
				<td>{{item.exam_info.job_title}}</td>
			</tr>
			<tr>
				<td>考试成绩</td>
				<td>{{item.score}}</td>
			</tr>
			
			<template v-if="item.exam_info.is_show==1&&(!item.scores||item.scores.length==0)">
				<tr>
					<td>排名</td>
					<td>{{item.ranking}}</td>
				</tr>
				<tr>
					<td>是否拟进入面试</td>
					<td>{{isInterviewStatus[item.is_interview]}}</td>
				</tr>
			</template>
			<template v-if="item.scores&&item.scores.length>0">
				<tr v-for="(v,k) in item&&item.scores" :key="k">
					<td>{{v.name}}</td>
					<td>{{v.score}}</td>
				</tr>
				<!-- <tr>
					<td>是否拟进入面试</td>
					<td></td>
				</tr> -->
			</template>
			
		</table>

	</div>
</template>

<script>
	import PageTitle from "../../components/PageTitle";
	import {
		Drawer,
		Message
	} from 'view-design';
	import {
		apiGetPassExam,
		apiSelectScore
	} from "../../request/api";
	import SelectPage from "../../components/SelectPage";
	import menuListOpen from "../../components/menuListOpen";

	export default {
		components: {
			PageTitle,
			Drawer,
			SelectPage,
			menuListOpen,
		},
		data() {
			return {
				scoreList: [],
				scoreItem: "",
				tabs: this.GLOBAL.indexTabs,
				nowIndex: 5,

				//手机端的tab弹窗控制器
				istabPopupOpen: false,
				signUpNo: "",

				//选中的考试期次的id
				selectedExamId: '',
				idCard: "",

				//查询到的考试成绩信息
				selectedInfo: [],

				//控制是否增加classname  full-height,解决内容不足，高度不撑满
				fullHeightAdd: false,

				isInterviewStatus: ['否', '是']
			}
		},
		created() {

			apiGetPassExam().then((res) => {
				// let arr =[];
				res.data.forEach((item) => {
					this.scoreList.push({
						value: item.exam_name,
						id: item.id
					})
				});
			})
		},
		mounted() {
			if (document.getElementsByClassName("score-inquiry")[0].clientHeight < 400) {
				// this.fullHeightAdd = true
			}
		},
		methods: {
			changeTabsIndex(index) {

				this.nowIndex = index;
				switch (index) {
					case 0:
						this.$router.push({
							name: 'index'
						})
						break;
					case 1:
						this.$router.push({
							name: 'newsInformation'
						})
						break;
					case 2:

						this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

						// localStorage.setItem("isIndexTabEnter", "true");
						break;
					case 3:
						this.$router.push({
							name: 'previousRegistration'
						})
						break;
					case 4:
						this.$router.push({
							name: 'commonProblem'
						})
						break;
					case 5:
						this.$router.push(
							`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
						)

						break;
					case 6:
						this.$router.push({
							name: 'applicationForUse'
						})
						break;
					case 7:
						this.$router.push({
							name: 'contactUs'
						})
						break;
				}
			},
			//控制移动端tab是否显示
			changeIstabPopupOpen() {
				this.istabPopupOpen = !this.istabPopupOpen
			},
			//选择考试期次
			changeSelectedItem(item) {
				this.scoreItem = item;
				console.log()
			},
			//点击查询成绩按钮
			toSelectScore() {
				if (this.scoreItem.value === "" || this.scoreItem.value == null) {
					Message.info("考试期次未选择");
					return false;
				}
				if (this.signUpNo === "" || this.signUpNo == null) {
					Message.info("准考证号未填写");
					return false;
				}
				if (this.idCard === "" || this.idCard == null) {
					Message.info("身份证号未填写");
					return false;
				}
				apiSelectScore({
					sign_up_no: this.signUpNo,
					exam_id: this.scoreItem.id,
					id_card: this.idCard,
				}).then((res) => {
					if (res.code === 0) {
						Message.info(res.msg)
					} else {
						if (res.data == [] || res.data.length == 0) {
							Message.info('查询不到该考生的相关成绩')
						}
						this.selectedInfo = res.data;
					}
				})

			}
		}
	}
</script>

<style lang="less" scoped>
	.score-inquiry {
		font-size: .2rem;
		color: #1F4DB6;
		padding: 0 2rem .46rem;

		&-tabs {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			font-size: .18rem;
			color: #333333;
			margin-bottom: .3rem;

			&-item {
				padding: .16rem .03rem;
				border-bottom: .03rem solid #FFFFFF;
			}

			&-item:hover {
				cursor: pointer;
			}

			.item_click {
				color: #F93454;
				border-color: #F93454;
			}
		}

		&-main {
			font-size: .16rem;
			padding: .3rem;
			margin-bottom: .3rem;
			box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

			&-item {
				display: flex;
				align-items: center;
				margin-bottom: .3rem;

				&-tip {
					min-width: .8rem;
					margin-right: .15rem;
				}

				&-box {
					display: flex;
					width: 100%;
					/*overflow: hidden;*/
					border: .02rem solid #C6CED4;
				}

				&>input {
					display: inline-block;
					width: 100%;
					height: .28rem;
					line-height: .26rem;
					font-size: .13rem;
					color: #636363;
					border: .02rem solid #C6CED4;
					padding: 0 .1rem;
				}
			}

			&-btn {
				display: flex;
				justify-content: center;
				width: 100%;

				&>img {
					width: 1.3rem;
					height: .39rem;
				}
			}
		}

		&>table {
			margin-bottom: .1rem;

			&>tr>td {
				font-size: .16rem;
				padding: .1rem;
			}

		}
	}

	.tabs-popup {
		display: flex;
		flex-direction: column;

		&-item {
			padding: .4rem .1rem;
			border-bottom: .01rem solid #999999;
		}
	}

	/*-----------------------适配移动端-----------------*/
	@media screen and (max-width: 1024px) {
		.full-height {
			height: 50vh;
		}

		.score-inquiry {
			padding: .19rem .24rem .32rem;

			.score-inquiry-tabs {
				display: none;
			}

			.container-top-title {
				margin-bottom: .17rem !important;
			}

			.score-inquiry-main {
				padding: .17rem .1rem .24rem;

				.score-inquiry-main-item-tip {
					min-width: 1.2rem;
					margin-right: .08rem;
				}
			}

			/*.ivu-select-selection {*/
			/*  height: .3rem !important;*/
			/*}*/
			/*.ivu-select-dropdown {*/
			/*  .ivu-select-dropdown-list {*/
			/*    border: 2px solid #1F4DB6 !important;*/
			/*    border-radius: inherit;*/
			/*  }*/
			/*}*/

		}

	}
</style>