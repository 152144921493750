import Vue from 'vue'
import Router from 'vue-router';
import index from '../view/index/index'
import advListPage from '../view/advListPage/advListPage'
import noticeList from '../view/advListPage/noticeList'
import advDetailPage from '../view/advListPage/advDetailPage'
import register from "../view/register/register";
import login from "../view/login/login";
import confirmLogin from "../view/login/confirmLogin";
import loginComplete from "../view/login/loginComplete";
import queryRegistrationNumber from "../view/login/queryRegistrationNumber/queryRegistrationNumber";
import findPassword from "../view/login/findPassword/findPassword";
import currentHome from "../view/currentHome/currentHome";
import recruitmentNewsList from "../view/recruitmentNewsList/recruitmentNewsList";
import recruitmentNews from "../view/recruitmentNewsList/recruitmentNews/recruitmentNews";
import recruitmentAnnouncement from "../view/recruitmentAnnouncement/recruitmentAnnouncement";
import recruitmentAnnouncementItem
  from "../view/recruitmentAnnouncement/recruitmentAnnouncementItem/recruitmentAnnouncementItem";
import postInformationTable from "../view/postInformationTable/postInformationTable";
import postInquiry from "../view/postInquiry/postInquiry ";
import scoreInquiry from "../view/scoreInquiry/scoreInquiry";
import newsInformation from "../view/newsInformation/newsInformation";
import newsInformationDetail from "../view/newsInformation/newsInformationDetail/newsInformationDetail";
import policyList from "../view/policyList/policyList";
import policy from "../view/policyList/policy/policy";
import previousRegistration from "../view/previousRegistration/previousRegistration";
import commonProblem from "../view/commonProblem/commonProblem";
import applicationForUse from "../view/applicationForUse/applicationForUse";
import contactUs from "../view/contactUs/contactUs";
import examineeInformation from "../view/examineeInformation/examineeInformation";
import wxLoginBind from "../view/examineeInformation/wxLoginBind/wxLoginBind";
import wxLoginBindComplete from "../view/examineeInformation/wxLoginBind/wxLoginBindComplete";
import candidates from "../view/candidates/candidates";
import admissionCard from "../view/examineeInformation/admissionCard/admissionCard";
import interviewAdmissionList from "../view/interviewAdmissionList/interviewAdmissionList";
import interviewAdmission from "../view/interviewAdmissionList/interviewAdmission/interviewAdmission";
import testSelect from "../view/testSelect";


Vue.use(Router)

const router = new Router({

  mode: 'history',  //去掉url中的#
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
      meta: {
        title: '主站',
      }
    },
    {
      path: '/advListPage/advListPage',
      name: 'advListPage',
      component: advListPage,
      meta: {
        title: '国企专栏',
      }
    },
    {
      path: '/advListPage/noticeList',
      name: 'noticeList',
      component: noticeList,
      meta: {
        title: '招聘广告列表',
      }
    },
    {
      path: '/advListPage/advDetailPage',
      name: 'advDetailPage',
      component: advDetailPage,
      meta: {
        title: '广告详情',
      }
    },
    {
      path: '/register/register',
      name: 'register',
      component: register,
      meta: {
        title: '考生注册',
      }
    },
    {
      path: '/login/login',
      name: 'login',
      component: login,
      meta: {
        title: '考生登录',
      }
    },
    {
      path: '/wxlogin',
      name: 'wxlogin',
      component: confirmLogin,
      meta: {
        title: '确认微信登录',
      }
    },
    {
      path: '/loginComplete',
      name: 'loginComplete',
      component: loginComplete,
      meta: {
        title: '完成微信登录',
      }
    },
    {
      path: '/queryRegistrationNumber/queryRegistrationNumber',
      name: 'queryRegistrationNumber',
      component: queryRegistrationNumber,
      meta: {
        title: '查询报名序号',
      }
    },
    {
      path: '/findPassword/findPassword',
      name: 'findPassword',
      component: findPassword,
      meta: {
        title: '找回密码',
      }
    },
    {
      path: '/currentHome/currentHome',
      name: 'currentHome',
      component: currentHome,
      meta: {
        title: '当前首页',
      }
    },
    {
      path: '/recruitmentNewsList/recruitmentNewsList',
      name: 'recruitmentNewsList',
      component: recruitmentNewsList,
      meta: {
        title: '招聘动态列表',
      }
    },
    {
      path: '/recruitmentNewsList/recruitmentNews/recruitmentNews',
      name: 'recruitmentNews',
      component: recruitmentNews,
      meta: {
        title: '招聘动态详情',
      }
    },
    {
      path: '/recruitmentAnnouncement/recruitmentAnnouncement',
      name: 'recruitmentAnnouncement',
      component: recruitmentAnnouncement,
      meta: {
        title: '招聘公示列表',
      }
    },
    {
      path: '/recruitmentAnnouncement/recruitmentAnnouncementItem/recruitmentAnnouncementItem',
      name: 'recruitmentAnnouncementItem',
      component: recruitmentAnnouncementItem,
      meta: {
        title: '招聘公示详情',
      }
    },
    {
      path: '/postInformationTable/postInformationTable',
      name: 'postInformationTable',
      component: postInformationTable,
      meta: {
        title: '岗位信息表',
      }
    },
    {
      path: '/postInquiry/postInquiry',
      name: 'postInquiry',
      component: postInquiry,
      meta: {
        title: '岗位查询',
      }
    },
    {
      path: '/candidates/candidates',
      name: 'candidates',
      component: candidates,
      meta: {
        title: '报考人数统计',
      }
    },

    {
      path: '/scoreInquiry/scoreInquiry',
      name: 'scoreInquiry',
      component: scoreInquiry,
      meta: {
        title: '成绩查询',
      }
    },
    {
      path: '/newsInformation/newsInformation',
      name: 'newsInformation',
      component: newsInformation,
      meta: {
        title: '新闻动态列表',
      }
    },
    {
      path: '/newsInformationDetail/newsInformationDetail',
      name: 'newsInformationDetail',
      component: newsInformationDetail,
      meta: {
        title: '新闻动态详情',
      }
    },
    {
      path: '/policyList/policyList',
      name: 'policyList',
      component: policyList,
      meta: {
        title: '政策法规列表',
      }
    },
    {
      path: '/policy/policy',
      name: 'policy',
      component: policy,
      meta: {
        title: '政策法规详情',
      }
    },
    {
      path: '/previousRegistration/previousRegistration',
      name: 'previousRegistration',
      component: previousRegistration,
      meta: {
        title: '往期报名',
      }
    },
    {
      path: '/commonProblem/commonProblem',
      name: 'commonProblem',
      component: commonProblem,
      meta: {
        title: '常见问题',
      }
    },
    {
      path: '/applicationForUse/applicationForUse',
      name: 'applicationForUse',
      component: applicationForUse,
      meta: {
        title: '使用申请',
      }
    },
    {
      path: '/contactUs/contactUs',
      name: 'contactUs',
      component: contactUs,
      meta: {
        title: '联系我们',
      }
    },
    {
      path: '/examineeInformation/examineeInformation',
      name: 'examineeInformation',
      component: examineeInformation,
      meta: {
        title: '考生信息界面',
        requireAuth: true
      }
    },
    {
      path: '/wxloginbind',
      name: 'wxloginbind',
      component: wxLoginBind,
      meta: {
        title: '微信绑定',
      }
    },
    {
      path: '/wxLoginBindComplete',
      name: 'wxLoginBindComplete',
      component: wxLoginBindComplete,
      meta: {
        title: '完成微信绑定',
      }
    },
    {
      path: '/examineeInformation/admissionCard/admissionCard',
      name: 'admissionCard',
      component: admissionCard,
      meta: {
        title: '考试准考证',
      }
    },
    {
      path: '/interviewAdmissionList/interviewAdmissionList',
      name: 'interviewAdmissionList',
      component: interviewAdmissionList,
      meta: {
        title: '面试列表',
      }
    }, {
      path: '/interviewAdmissionList/interviewAdmission/interviewAdmission',
      name: 'interviewAdmission',
      component: interviewAdmission,
      meta: {
        title: '面试准考证',
      }
    }, {
      path: '/testSelect',
      name: 'testSelect',
      component: testSelect,
      meta: {
        title: '测试下拉',
      }
    },
  ]
})

// 设置路由守卫，在进页面之前，判断有token，才进入页面，否则返回登录页面
// router.beforeEach((to, from, next) => {
// if (window.location.href.indexOf('code') >= 0) {
//   //如果url中包含code,则保存到localStorage中
//   let code = window.location.href.split("?")[1];
//   code = code.substring(5, code.indexOf('&'));
//   localStorage.setItem("code", code)
// }

//   // 默认requiresAuth为false才不需要登录，其他都要
//   // to.matched.some(r => r.meta.requireAuth) or to.meta.requiresAuth
//   if (to.matched.some(r => r.meta.requireAuth) !== false) {
//     let tokenInfo = localStorage.getItem('token' + "_" + localStorage.getItem("shop_id") + "_"
//       + localStorage.getItem("exam_id"))
//     if (tokenInfo) {
//       console.log("有token")
//       next();
//
//     } else {
//       localStorage.removeItem('token' + "_" + localStorage.getItem("shop_id") + "_"
//         + localStorage.getItem("exam_id"))
//       next({
//         path: "/login/login",
//         query: {
//           redirect: to.fullPath
//         } // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由
//       });
//     }
//
//   } else {
//     next(); //如果无需token,那么随它去吧
//   }
// });
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//暴露router实例
export default router
// 1.将调用登录接口成功以后，把后端传过来的token放入本地缓存
// 2.路由跳转之前执行路由守卫，实例化一个Router对象，使用该对象内置方法beforeEach，在路由跳转前判断该页面是否设置了token，获取token
// 如果有token，next()继续执行路由跳转
// 如果没有token，跳转去登录界面
