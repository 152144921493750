<template>
  <Icon class="opentab" type="md-menu" size="28" @click="openMenuList"/>

</template>

<script>
    import {Icon} from "view-design";

    export default {
        name: "menuListOpen",
        components: {
          Icon
        },
        data() {
            return {
                msg: "模板页面"
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
          openMenuList(){
            this.$parent.changeIstabPopupOpen()
          }
        },
        destroyed() {
        }
    }
</script>

<style lang="less" scoped>
  .opentab {
    width: .5rem;
    height: .5rem;
    position: fixed;
    top: .09rem;
    right: .15rem;
    color: #000000;
    display: none;
  }
  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .opentab {
      display: flex;
      align-items: center;
    }

  }
</style>
