import { render, staticRenderFns } from "./examineeInformation.vue?vue&type=template&id=521b4226&scoped=true&"
import script from "./examineeInformation.vue?vue&type=script&lang=js&"
export * from "./examineeInformation.vue?vue&type=script&lang=js&"
import style0 from "./examineeInformation.vue?vue&type=style&index=0&id=521b4226&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521b4226",
  null
  
)

export default component.exports