<template>
  <div class="policy" v-wechat-title="$route.meta.title">
<!--    <div class="opentab" @click="changeIstabPopupOpen">-->
<!--      <div class="opentab-line"></div>-->
<!--    </div>-->
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="policy-tabs">
      <div v-for="(item,index) in tabs" class="policy-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'政策法规'" style="margin: 0 0 .3rem;"></PageTitle>
    <h2>{{policyItem.title}}</h2>
    <h5 v-if="policyItem!==null" style="text-align: center;margin-bottom: .2rem">发布时间：{{policyItem.create_time}}</h5>

    <div v-html="policyItem.content"></div>

  </div>
</template>

<script>
  import {Drawer} from "view-design";
  import PageTitle from "../../../components/PageTitle";
import menuListOpen from "../../../components/menuListOpen";
  import {apiPolicyContent} from "../../../request/api";
  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 2,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        policyItem: {},
      }
    },
    created() {
      this.backToTop();
      apiPolicyContent({
        // id:localStorage.getItem('policyId')
        id:this.$route.query.policyId
      }).then((res)=>{

        this.policyItem = res.data;
      })

    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)
            break;
          case 3:
            this.$router.push({name: 'previousRegistration'})
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .policy {
    font-size: .18rem;
    padding: 0 2rem .3rem;

    h2 {
      font-size: .2rem;
      text-align: center;
      padding-bottom: .2rem;
    }

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-main {
      color: #666666;

      h2 {
        font-size: .18rem;
        color: #333333;
        font-weight: inherit;
        text-align: center;
        margin-bottom: .21rem;
      }

      &-time {
        font-size: .16rem;
        text-align: center;
        margin-bottom: .38rem;
      }

      &-content {
        margin-bottom: .46rem;

        & > div {
          text-align: right;
        }
      }

      &-headline {
        font-size: .18rem;
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin-bottom: .46rem;
      }
    }
  }


  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .policy {

      padding: .19rem .24rem .32rem;

      &-tabs {
        display: none;
      }


    }

  }
</style>
