<template>
  <div class="find-password" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="find-password-tabs">
      <div v-for="(item,index) in tabs" class="find-password-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="find-password-announcement">
      <div class="find-password-announcement-main">当前：{{employerInformation.exam_name}}</div>
    </div>

    <PageTitle :message="'找回密码'"></PageTitle>

    <div class="find-password-tip">
      <div>说明： 报名序号或身份证号和邮箱地址对应成功后，系统将发送重置好的密码到此邮箱；</div>
      <div>邮件通过 gzrcptfb@163.com 发送，1分钟内如未收到请检查邮件垃圾箱，建议将此邮箱加入你的邮件白名单列表中；</div>
    </div>
    <div class="find-password-main">
      <div class="find-password-main-item">
        <div>报名序号</div>
        <input placeholder="报名序号或身份证号选填一个（9位）" v-model="registrationNumber"></input>
      </div>
      <div class="find-password-main-item">
        <div>身份证号</div>
        <input placeholder="报名序号或身份证号选填一个" v-model="idNumber"></input>
      </div>
      <div class="find-password-main-item">
        <div>电子邮箱</div>
        <input placeholder="使用邮件找回密码时填写" v-model="email"></input>
      </div>
      <div class="find-password-main-item">
        <div>手机号码</div>
        <input placeholder="使用短信找回密码时填写" v-model="mobile"></input>
      </div>
    </div>
    <img src="../../../assets/images/login/submit_application_btn.png" @click="commit">

  </div>
</template>

<script>
  import {Drawer, Message} from "view-design";
  import PageTitle from "../../../components/PageTitle";
  import menuListOpen from "../../../components/menuListOpen";
  import {apiExamContent, apiFindPassword} from "../../../request/api";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: '',
        employerInformation: {},
        registrationNumber: '',
        idNumber: '',
        email: '',
        mobile: '',

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
      }
    },
    created() {
      apiExamContent({
        id:this.$route.query.exam_id
      }).then((res)=>{
        this.employerInformation = res.data;
      })
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:

            this.$router.push(`/currecruitmentNewsListrentHome/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:

            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      commit() {
        apiFindPassword({
          sign_up_no: this.registrationNumber,
          id_cards: this.idNumber,
          mobile: this.mobile,
          email: this.email,
          exam_id: this.$route.query.exam_id
        }).then((res) => {
          Message.info(res.msg)
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .find-password {
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      margin-bottom: .17rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    &-tip {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: .14rem;
      padding: .1rem 2rem;
      margin-bottom: .3rem;
      color: #246FB0;
      background: #fcefe7;
    }

    &-main {
      display: flex;
      flex-direction: column;
      padding: 0 2rem .3rem;

      &-item {
        display: flex;
        align-items: center;
        padding-bottom: .2rem;

        & > div {
          font-size: .16rem;
          color: #333333;
          padding-right: .2rem;
        }

        & > input {
          min-width: 4.7rem;
          padding: .04rem .1rem;
          font-size: .12rem;
          color: #636363;
          border: 2px solid #C6CED4;
        }
      }

    }

    & > img {
      width: 1.4rem;
      margin-left: 4rem;
      margin-bottom: .3rem;
    }

  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .find-password {
      padding: .22rem 0 0;


      .find-password-tabs {
        display: none;
      }

      .find-password-announcement {
        padding-top: 0;
      }

      &-container, .find-password-announcement-main, .find-password-tip {
        padding: .07rem .53rem;
      }

      .find-password-container {
        padding: 0;
      }

      .find-password-main {
        padding: .2rem .24rem;

        &-item > input {
          min-width: 5.4rem;
        }

        &-top {
          padding-bottom: .4rem;
        }
      }

      &-title {
        padding-bottom: .12rem;
        margin: 0 .24rem .2rem;
      }

    }

  }
</style>
