<template>
  <div class="page-title">
    <span>{{message}}</span>
  </div>
</template>

<script>
  export default {
    name: "PageTitle",
    props: ['message']
  }
</script>

<style lang="less" scoped>
  .page-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: .2rem;
    border-bottom: .02rem solid #b1b8c7;
    margin: 0 2rem .3rem;

    & > span {
      font-size: .18rem;
      color: #333333;
      font-family: PingFang-SC-Bold;
      padding-left: .14rem;
      border-left: .04rem solid #1f4db6;
    }
  }

  /*-------------------------------适配移动端--------------------*/
  @media screen and (max-width: 1024px) {
    .page-title {
      margin: 0 .2rem .2rem;
    }
  }
</style>
