<script>
  // 全局变量appid
  const appid = "wx536e2d9d505a79d0";
  //主站页面头部tab
  const indexTabs = ["首页", "新闻动态", "政策法规", "往期报名", "常见问题", "成绩查询", "使用申请", "联系我们"];
  //事业单位页面头部tab
  const examHeaderTabs = ["主站", "当前首页", "招聘动态", "招聘公示", "政策法规", "岗位查询", "成绩查询", "报考登录"];

  export default {
    appid,
    indexTabs,
    examHeaderTabs,
  };
</script>
