<template>
  <div class="recruitment-news-list" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <div class="recruitment-news-list-tabs">
      <div v-for="(item,index) in tabs" class="recruitment-news-list-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'招聘动态'"></PageTitle>
    <div v-if="recruitmentNewsList.length!==0" class="recruitment-news-list-main">
      <div class="recruitment-news-list-main-item" v-for="(item,index) in recruitmentNewsList"
           :key="index" @click="toRecruitmentNews(item.id,item.shop_id)">
        <div class="recruitment-news-list-main-item-main">◆{{item.title}}
        </div>
        <div class="recruitment-news-list-main-item-time">{{item.create_time}}</div>
      </div>
    </div>
    <NoData v-if="recruitmentNewsListNodata" :dataTip="'暂无数据'"></NoData>
    <!--   ---------------- 分页按钮-------------------------------->
    <div v-if="recruitmentNewsList.length!==0" class="recruitment-news-list-bottom">
      <PageBtns :pageLength="totalPage"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import PageBtns from "../../components/PageBtns";
  import NoData from "../../components/NoData";
  import menuListOpen from "../../components/menuListOpen";
  import {Drawer} from 'view-design';
  import {apiGetRecruitmentNewsList} from "../../request/api";

  export default {
    components: {
      PageTitle,
      PageBtns,
      Drawer,
      NoData,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: 2,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        recruitmentNewsList: [],

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 10,

        //总页数
        totalPage: 0,

        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,

        recruitmentNewsListNodata: false
      }
    },
    created() {
      this.getRecruitmentNewsList(1);
    },
    mounted() {

    },
    computed: {},
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)
            break;
          case 2:
            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            this.getRecruitmentNewsList(1);
            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            // localStorage.removeItem("isIndexTabEnter");
            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:

            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }

      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        this.getRecruitmentNewsList(index + 1);
        this.backToTop();
      },
      //跳转到招聘动态详情
      toRecruitmentNews(id, shopId) {
        this.$router.push(`/recruitmentNewsList/recruitmentNews/recruitmentNews?recruitmentNewsId=${id}&shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

      },
      getRecruitmentNewsList(_page) {
        apiGetRecruitmentNewsList({
          shop_id: this.$route.query.shop_id,
          exam_id: this.$route.query.exam_id,
          page: _page
        }).then((res) => {
          if (res.data.list.length == 0 || res.data.list == null){
            this.recruitmentNewsListNodata = true;
          }


          this.recruitmentNewsList = res.data.list;
          this.totalPage = res.data.page
        });
      },

      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .recruitment-news-list {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 0 0 .3rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin: 0 2rem .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem .63rem;

      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-main {
          width: 7.25rem;
          font-size: .16rem;
          color: #1F4DB6;
          font-family: PingFang-SC-Bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 auto;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: .46rem;
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .recruitment-news-list {
      padding: .19rem .24rem .32rem;

      .recruitment-news-list-tabs {
        display: none;
      }

      .recruitment-news-list-title {
        padding-bottom: .12rem;
        margin-bottom: .1rem;
      }

      .recruitment-news-list-main {
        padding: .17rem 0 .14rem;

        &-item-time {
          /*width: 4rem;*/
          transform: scale(.8);
        }

        .recruitment-news-list-main-item-main {
          width: 4.48rem;
        }
      }


    }

  }
</style>
