<template>
  <div class="contact-us" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <div class="contact-us-tabs">
      <div v-for="(item,index) in tabs" class="contact-us-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'联系我们'" style="margin:0 0 .3rem"></PageTitle>
    <div v-html="contactUsContent"></div>

  </div>
</template>

<script>
  import {Drawer} from 'view-design';
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";
  import {apiContactUs} from "../../request/api";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 7,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        contactUsContent: {},
        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      apiContactUs().then((res) => {
        this.contactUsContent = res.data;
      });
    },
    mounted() {
      if (document.getElementsByClassName("contact-us")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({name: 'previousRegistration'})
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'});
            apiContactUs().then((res) => {
              this.contactUsContent = res.data;
            });
            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .contact-us {
    padding: 0 2rem .3rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .contact-us {
      padding: .19rem .24rem .32rem;

      &-main {
        padding-top: .18rem;
      }

      .contact-us-title {
        display: none;
      }

      .list-title {
        display: block !important;
      }

      &-tabs {
        display: none;
      }

      &-title {
        padding-bottom: .18rem;
      }


    }

  }
</style>
