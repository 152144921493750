<template>
  <div class="post-table" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="post-table-tabs">
      <div v-for="(item,index) in tabs" class="post-table-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="post-table-announcement">
      <div class="post-table-announcement-main">当前：{{employerInformation.exam_name}}</div>
    </div>

    <PageTitle :message="'岗位信息表'"></PageTitle>
    <div class="post-table-main">
      <div class="post-table-main-headline">
        {{employerInformation.exam_name}} 招考岗位表
      </div>
      <!--      <div class="post-table-main-tip">-->
      <!--        点击查看:-->
      <!--        <i>福州轨道交通设计院有限公司社会招聘岗位需求表。pdf</i>-->
      <!--      </div>-->
      <div v-if="jobList.length!==0" class="post-table-main-body">
        <!--        style="white-space:nowrap"-->
        <table border="1" style="width: auto">
          <tr class="post-table-main-body-title">
            <td style="white-space:nowrap">单位代码</td>
            <td style="white-space:nowrap">单位名称</td>
            <td style="white-space:nowrap">单位性质</td>
            <td style="white-space:nowrap">岗位代码</td>
            <td style="white-space:nowrap">岗位名称</td>
            <!-- <td style="white-space:nowrap">地区</td> -->
            <td style="white-space:nowrap">招考人数</td>
            <td style="white-space:nowrap">考试类别</td>
            <td style="white-space:nowrap">学历类别</td>
            <td style="white-space:nowrap">专业</td>
            <td style="white-space:nowrap">学历</td>
            <td style="white-space:nowrap">学位</td>
            <td style="white-space:nowrap">应届要求</td>
            <td style="white-space:nowrap">性别</td>
            <td style="white-space:nowrap">最高年龄</td>
            <td style="white-space:nowrap">民族</td>
            <td style="white-space:nowrap">政治面貌</td>
            <td style="white-space:nowrap">户籍要求</td>
            <td style="white-space:nowrap">专门岗位</td>
            <td style="white-space:nowrap">联系方式</td>
            <td style="white-space:nowrap">其他要求</td>
            <td style="white-space:nowrap">备注</td>
          </tr>
          <tr v-for="(item,index) in jobList" :key="index" class="post-table-main-body-content">
            <td>{{item.unit_code}}</td>
            <td>{{item.unit_name}}</td>
            <td>{{item.unit_nature_text}}</td>
            <td>{{item.position_code}}</td>
            <td>{{item.job_title}}</td>
            <!-- <td>{{item.address}}</td> -->
            <td>{{item.job_count}}</td>
            <td>{{item.exam_subject}}</td>
            <td>{{item.degree_category_text}}</td>
            <td>{{item.major_limit}}</td>
            <td>{{item.academic_degre_text}}</td>
            <td>{{item.degree_limit_text}}</td>
            <td>{{item.is_current_text}}</td>
            <td>{{item.sex_text}}</td>
            <td>{{item.age_limit_text}}</td>
            <td>{{item.nation_limit_text}}</td>
            <td>{{item.politics_text}}</td>
            <td>{{item.household_register}}</td>
            <td>{{item.special_job_text}}</td>
            <td>{{item.contact}}</td>
            <td>{{item.other_limit}}</td>
            <td>{{item.remark}}</td>
          </tr>
        </table>
      </div>
      <NoData v-if="jobListNodata" :dataTip="'暂无数据'"></NoData>
    </div>
  </div>
</template>

<script>
  import {Drawer} from "view-design";
  import {apiExamContent, apiGetJobList1} from "../../request/api";
  import NoData from "../../components/NoData";
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";

  export default {
    components: {
      Drawer,
      NoData,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: '',
        employerInformation: {},

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        jobList: [],
        jobListNodata:false
      }
    },
    created() {


      this.backToTop();
      apiExamContent({
        // id:localStorage.getItem('exam_id')
        id:this.$route.query.exam_id
      }).then((res)=>{
        this.employerInformation = res.data;
      })
      apiGetJobList1({
        // shop_id: localStorage.getItem("shop_id"),
        //
        // exam_id:localStorage.getItem('exam_id'),
        shop_id: this.$route.query.shop_id,
        exam_id:this.$route.query.exam_id
      }).then((res) => {
        if(res.data.list.length==0){
          this.jobListNodata=true;
        }
        this.jobList = res.data.list;
      })
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:

            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .post-table {
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      margin-bottom: .17rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    &-main {
      color: #333333;
      padding: 0 0 .5rem;

      &-headline {
        font-size: .18rem;
        padding: 0 2rem;
        margin-bottom: .21rem;
      }

      &-body {
        display: flex;
        justify-content: center;
        /*width: 20rem;*/
        overflow-x: auto;
        padding: .1rem;
        &-title{
          &>td{
            font-weight: bold;
          }
        }
        & > table {
          /*width: 5rem;*/
          overflow-x: auto;

          tr {
            /*display: flex;*/

            td {
              /*flex: 1;*/
              font-size: .14rem;
              /*min-width: 1rem;*/
              /*height: .1rem;*/
              /*width: auto;*/
              padding: .04rem;
              text-align: center;
            }

          }
        }
      }
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .post-table {

      padding: .19rem 0 .32rem;

      &-title {
        padding: .07rem 0;
        margin: 0 .24rem;
      }

      &-tabs {
        display: none;
      }

      .post-table-announcement-main {
        padding: .07rem .53rem;
      }

      &-main {
        padding: 0 .21rem;

        &-headline {
          padding: 0;
        }

        &-body {
          padding: .2rem;
        }
      }

      .post-table-headline {
        padding: .18rem 0 .14rem;
        margin: 0;
      }
    }

  }
</style>
