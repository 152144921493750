<template>
  <div class="news-information" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <div class="news-information-tabs">
      <div v-for="(item,index) in tabs" class="news-information-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <PageTitle :message="'新闻动态'" style="margin: 0"></PageTitle>
    <div v-if="!newsInformationListNodata" class="news-information-list">
      <div class="news-information-list-item" v-for="(item,index) in newsInformationList"
           @click="toNewsInformationDetail(item.id)" :key="index">
        <div class="news-information-list-item-main">◆
          {{item.title}}
        </div>
        <div class="news-information-list-item-time">{{item.create_time}}</div>
      </div>
    </div>
    <NoData v-if="newsInformationListNodata" :dataTip="'暂无数据'"></NoData>
    <!--   ---------------- 分页按钮-------------------------------->
    <div v-if="newsInformationList.length!=0" class="news-information-bottom">
      <PageBtns :pageLength="totalPage"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import {Drawer, Icon} from 'view-design';
  import {apiNewsList} from "../../request/api";
  import PageBtns from "../../components/PageBtns";
  import menuListOpen from "../../components/menuListOpen";
  import NoData from "../../components/NoData";

  export default {
    props: ['activeIndex'],
    name: "newsInformation",
    components: {
      PageTitle,
      PageBtns,
      Drawer,
      Icon,
      menuListOpen,
      NoData,
    },
    data() {
      return {
        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 1,
        newsInformationList: [],
        newsInformationListNodata: false,

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 10,
        //总页数
        totalPage: 0,

        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      this.getNewsList(1);
    },
    mounted() {
      if (document.getElementsByClassName("news-information")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
    },
    computed: {
      // changeCurrentTables() {
      //   let {currentPage, pageSize} = this;
      //   return this.newsInformationList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      // },
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'});
            this.getNewsList(1);
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)


            break;
          case 3:
            this.$router.push({name: 'previousRegistration'})
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }
      },
      getNewsList(_currentPage) {
        apiNewsList({
          page: _currentPage
        })
          .then((res) => {
            if (res.data.list == null || res.data.list.length == 0) {
              this.newsInformationListNodata = true;
            }
            this.newsInformationList = res.data.list;
            this.totalPage = res.data.page
          });
      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        let _page = index + 1;
        apiNewsList({
          page: _page
        })
          .then((res) => {
            this.newsInformationList = res.data.list;
          });
        this.backToTop();
      },
      // 跳转到新闻动态详情
      toNewsInformationDetail(newsId) {

        localStorage.setItem("newsId", newsId)
        this.$router.push(`/newsInformationDetail/newsInformationDetail?newsId=${newsId}`);
      },
    }
  }
</script>

<style lang="less" scoped>
  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .news-information {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 0 2rem .3rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: .3rem 0;

      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: .05rem;

        &-main {
          width: 7.25rem;
          font-size: .16rem;
          color: #1F4DB6;
          font-family: PingFang-SC-Bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 auto;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: .1rem 0;
    }
  }


  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .news-information {
      padding: .19rem .24rem .32rem;

      .news-information-tabs {
        display: none;
      }

      .news-information-list {
        padding: .17rem 0 .14rem;

        &-item-time {
          font-size: .12rem;
          text-align: right;
        }

        .news-information-list-item-main {
          width: 4.48rem;
        }
      }
    }

  }
</style>
