<template>
  <div class="candidates" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="candidates-tabs">
      <div v-for="(item,index) in tabs" class="candidates-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="candidates-announcement">
      <div class="candidates-announcement-main">当前：{{employerInformation.exam_name}}</div>
    </div>

    <PageTitle :message="'报考人数统计'"></PageTitle>

    <!--    <div class="candidates-headline">-->
    <!--      更新时间：2020-12-21 15:06-->
    <!--    </div>-->
    <div v-if="jobsCountInfoList.length!==0" class="candidates-main">
      <table>
        <tr class="candidates-main-header">
          <td>单位</td>
          <td>岗位</td>
          <td>招考人数</td>
          <td>总报名数</td>
          <td>审核通过</td>
          <td>未审核</td>
        </tr>
        <tr v-for="(item,index) in jobsCountInfoList" :key="index">
          <td>
            {{item.unit_name}}
            <span v-if="item.unit_code!=='' && item.unit_code!=null">
                    ({{item.unit_code}})</span>
          </td>
          <td>
            {{item.job_title}}
            <span v-if="item.position_code!=='' && item.position_code!=null">
                    ({{item.position_code}})</span>
          </td>
          <td>{{item.job_count}}</td>
          <td>{{item.total_count}}</td>
          <td>{{item.pass_count}}</td>
          <td>{{item.no_check_count}}</td>
        </tr>
        <tr>
          <td colspan="2">合计</td>
          <td>{{jobsCountInfo.total_job_count}}</td>
          <td>{{jobsCountInfo.total_total_count}}</td>
          <td>{{jobsCountInfo.total_pass_count}}</td>
          <td>{{jobsCountInfo.total_no_check_count}}</td>
        </tr>
      </table>
    </div>
    <NoData v-if="jobsCountInfoList.length==0" :dataTip="'暂无数据'"></NoData>
  </div>
</template>

<script>
  import {Drawer} from "view-design";
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";
  import {apiExamContent, apiGetJobsCount} from "../../request/api";
  import NoData from "../../components/NoData";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
      NoData
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: '',
        employerInformation: {},

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        //报考人数信息
        jobsCountInfo: {},
        jobsCountInfoList: [],
        jobsCountInfoListNodata:false
      }
    },
    created() {
      this.backToTop();
      apiExamContent({
        // id:localStorage.getItem('exam_id')
        id:this.$route.query.exam_id
      }).then((res)=>{
        this.employerInformation = res.data;
      })
      apiGetJobsCount({
        // shop_id: localStorage.getItem("shop_id")
        shop_id: this.$route.query.shop_id,
        exam_id:this.$route.query.exam_id,
      }).then((res) => {
        if(res.data.list.length==0){
          this.jobsCountInfoListNodata=true;
        }
        this.jobsCountInfo = res.data.count;
        this.jobsCountInfoList = res.data.list;
      })
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:

            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .candidates {
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      margin-bottom: .17rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    &-headline {
      font-size: .18rem;
      color: #333333;
      text-align: center;
      margin: 0 auto .37rem;
    }

    &-main {
      padding: 0 2rem .5rem;

      & > table {
        width: 100%;

        & > tr {
          width: 100%;

          & > td {
            padding: .13rem 0;
            text-align: center;
          }
        }

        & > tr:nth-child(odd) {
          background: #e0ecf3;
        }

        & > tr:nth-child(even) {
          background: #f2faff;
        }

        .candidates-main-header {
          font-size: .16rem;
          color: #333333;
          font-weight: bold;
          background: #b2dffe !important;
        }

      }
    }

  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .candidates {

      padding: .19rem 0 .32rem;

      &-tabs {
        display: none;
      }

      &-title {
        display: none;
        padding: .07rem 0;
        margin: 0 .24rem;
      }

      .list-title {
        display: block !important;
      }

      .candidates-announcement-main {
        padding: .07rem .53rem;
      }

      &-main {
        padding: 0 .21rem;
      }

      .candidates-headline {
        padding: .18rem 0 .14rem;
        margin: 0;
      }

      .candidates-main > table {
        /*transform: scale(.8);*/
      }

      .candidates-main > table > tr > td {
        padding: .05rem 0;
        transform: scale(.8);
      }


    }

  }
</style>
