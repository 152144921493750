<template>
  <div class="select-page" @click="selectMainOpen" id="closeSelect1">
    <div class="select-page-input" :style="{'border-bottom': noBorder}" id="closeSelect11">
      {{selectedValue}}
    </div>
    <div v-show="isSelectMainOpen" class="select-page-main" id="closeSelect12">
      <div v-if="selectList.length!==0" class="select-page-main-first">
        <div class="select-page-main-first-list">
          <div class="select-page-main-first-list-item" :class="{'selected':selectedValue===item.value}"
               @click="firstListItemClick(item,index)" id="closeSelect13"
               v-for="(item,index) in selectList" :key="index">{{item.value}}
          </div>
        </div>
      </div>
      <div v-if="selectList.length===0" style="text-align: center;padding: .1rem;color: #C6CED4">暂无数据</div>

    </div>
  </div>
</template>

<script>

  export default {
    props: [
      'selectList',
      'noBorder',
      'padding'
    ],
    name: "SelectPage",
    data() {
      return {
        selectedValue: "未选择",
        firstList: [],
        firstItem: "",
        isSelectMainOpen: false,
      }
    },
    created() {
    },
    mounted() {
      document.addEventListener('click', (e) => {

        if (e.target.id !== 'closeSelect1'&&e.target.id !== 'closeSelect11'&&e.target.id !== 'closeSelect12'&&e.target.id !== 'closeSelect13') {
          this.isSelectMainOpen = false;

        }

      })
    },
    methods: {
      selectMainOpen() {

        this.isSelectMainOpen = !this.isSelectMainOpen;

      },
      firstListItemClick(firstListItem) {
        this.selectedValue = firstListItem.value;
        this.$emit("changeSelectedItem", firstListItem);
      },
    }
  }
</script>

<style lang="less" scoped>
  .select-page {
    position: relative;
    width: 100%;
  }

  .select-page-input {

    width: 100%;
    font-size: .12rem;
    height: .28rem;
    line-height: .26rem;
    color: #666666;
    border: none;
    /*border-bottom: 1px solid #CCCCCC;*/
    /*padding: .1rem 0 .12rem .08rem;*/
    padding-left: .1rem;
  }

  .select-page-input:hover {
    cursor: pointer;
  }

  @keyframes changeHeight {
    0% {
      max-height: 0;
      /*min-width: 0;*/
      /*transform: scaleY(0);*/
      opacity: 0;
    }
    100% {
      max-height: .8rem;
      /*transform: translateY(1%);*/
      /*transform: scaleY(1);*/
      /*min-width: 1rem;*/
      opacity: 1;
    }
  }

  @-webkit-keyframes changeHeight /* Safari 和 Chrome */ {
    0% {
      max-height: 0;
      /*min-width: 0;*/
      opacity: 0;
    }
    100% {
      max-height: .8rem;
      /*transform: translateY(1%);*/
      /*min-width: 1rem;*/
      opacity: 1;
    }
  }

  .select-page-main {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    font-size: .12rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    background: #FFFFFF;
    animation: changeHeight 1s infinite;
    -moz-animation: changeHeight 1s infinite; /* Firefox */
    -webkit-animation: changeHeight 1s infinite; /* Safari and Chrome */
    -o-animation: changeHeight 1s infinite; /* Opera */
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &-first {
      /*flex: 1;*/
      width: 100%;

      overflow-y: auto;
      /*animation: changeHeight 1s infinite;*/
      /*-moz-animation: changeHeight 1s infinite; !* Firefox *!*/
      /*-webkit-animation: changeHeight 1s infinite; !* Safari and Chrome *!*/
      /*-o-animation: changeHeight 1s infinite; !* Opera *!*/
      /*animation-iteration-count: 1;*/
      /*animation-fill-mode: forwards;*/
      &-list {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        /*animation: changeHeight 1s infinite;*/
        /*-moz-animation: changeHeight 1s infinite; !* Firefox *!*/
        /*-webkit-animation: changeHeight 1s infinite; !* Safari and Chrome *!*/
        /*-o-animation: changeHeight 1s infinite; !* Opera *!*/
        /*animation-iteration-count: 1;*/
        /*animation-fill-mode: forwards;*/
        /*box-shadow: 0 1px 6px rgba(0, 0, 0, .2);*/
        &-item {
          min-width: 1rem;
          padding: .04rem .08rem;
          color: #666666;
        }

        &-item:hover {
          cursor: pointer;
          background: #f3f3f3;
          color: #2d8cf0;
        }

        .selected {
          color: #2d8cf0;
        }
      }
    }

  }

  @media screen and (max-width: 1024px) {
    .select-page-input {
      padding-left: .02rem;
      padding-right: .02rem;
    }

    .select-page-main-first-list-item {
      padding-left: .02rem;
      padding-right: .02rem;
    }
  }
</style>
