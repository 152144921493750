<template>
  <div class="previous-registration" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <div class="previous-registration-tabs">
      <div v-for="(item,index) in tabs" class="previous-registration-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <PageTitle :message="'往期报名'"></PageTitle>
    <div class="previous-registration-announcement">
      <div class="previous-registration-announcement-main">说明：此列表中的报名均已结束，仅成绩可供查询，报名数据已下线，如考生需要调阅报考资料或准考证信息，请与用人单位联系。
      </div>
    </div>
    <div v-if="previousRegistrationList.length!==0" class="previous-registration-list">
      <div class="previous-registration-list-item" @click="toScoreInquiry"
           v-for="(item,index) in previousRegistrationList" :key="index">
        <div class="previous-registration-list-item-title">{{item.exam_title}}</div>
        <div class="previous-registration-list-item-main">
          <div>委托单位:{{item.exam_name}}</div>
          <div>报名时段：{{item.sign_up_time}} 至 {{item.sign_up_end}}</div>
          <div v-if="item.exam_time">考试时间：{{item.exam_time}}</div>
        </div>
        <div class="line"></div>
        <div class="previous-registration-list-item-btns">
          <div v-if="item.result_status==1" class="previous-registration-list-item-btns-btn btn-score-inquiry">成绩查询
          </div>
        </div>
      </div>
    </div>

    <!--   ---------------- 分页按钮-------------------------------->
    <!--    <div v-if="previousRegistrationList.length!==0" class="previous-registration-bottom">-->
    <!--      <PageBtns :pageLength="Math.ceil(previousRegistrationList.length / pageSize)"-->
    <!--                @changePageNumber="changeCurrentPage"></PageBtns>-->
    <!--    </div>-->
    <NoData v-if="previousRegistrationListNodata" :dataTip="'暂无数据'"></NoData>

    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import ListItem from "../../components/ListItem";
  import PageBtns from "../../components/PageBtns";
  import NoData from "../../components/NoData";
  import {Drawer, Page} from 'view-design';
  import menuListOpen from "../../components/menuListOpen";
  import {apiGetPassExam} from "../../request/api";


  export default {
    components: {
      PageTitle,
      ListItem,
      Page,
      Drawer,
      PageBtns,
      NoData,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 3,
        previousRegistrationList: [],
        previousRegistrationListNodata: false,

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 6,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
      }
    },
    created() {
      apiGetPassExam().then((res) => {
        if (res.data == null || res.data.length == 0) {
          this.previousRegistrationListNodata = true;
        }
        this.previousRegistrationList = res.data;
      });
    },
    computed: {
      // changeCurrentTables() {
      //   let {currentPage, pageSize} = this;
      //   return this.previousRegistrationList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      // },
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({name: 'previousRegistration'});
            apiGetPassExam().then((res) => {
              this.previousRegistrationList = res.data;
            });
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }
      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        this.backToTop();
      },
      toScoreInquiry() {
        this.$router.push({name: 'scoreInquiry'})
      },
    }
  }
</script>

<style lang="less" scoped>

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .previous-registration {
    /*padding-top: .46rem;*/
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      padding: 0 0 .3rem;

      &-main {
        width: 100%;
        font-size: .14px;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
        margin-bottom: .19rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding: 0 2rem .2rem;

      &-item {
        width: 100%;
        padding: .2rem;
        margin-bottom: .28rem;
        font-size: .14rem;
        color: #636363;
        box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

        &-title {
          font-size: .18rem;
          color: #1F4DB6;
          padding-left: .12rem;
          margin-bottom: .2rem;
          font-family: PingFang-SC-Medium;
        }

        &-main {
          display: flex;
          flex-direction: column;
          padding: 0 .12rem;
          margin-bottom: .25rem;
        }

        .line {
          width: 100%;
          height: 1px;
          background: #BFBFBF;
          margin-bottom: .24rem;
        }

        &-btns {
          display: flex;
          justify-content: flex-end;
          padding: .1rem .34rem;

          &-btn {
            min-width: .89rem;
            height: .28rem;
            line-height: .26rem;
            text-align: center;
            /*padding: .07rem .09rem;*/
            border: 1px solid #EB6100;
            border-radius: .03rem;
            cursor: pointer;
          }

          .btn-score-inquiry {
            color: #13CF21;
            border-color: #13CF21;
          }

          .btn-score-inquiry:hover {
            color: #FFFFFF;
            background: #13CF21;
          }

        }
      }

    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: .1rem;
    }

  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .previous-registration {
      padding: .19rem .24rem .32rem;

      .previous-registration-tabs {
        display: none;
      }

      .previous-registration-announcement {
        padding-top: .17rem;
        margin-bottom: .12rem;

        .previous-registration-announcement-main {
          padding: .07rem .53rem;
          margin-bottom: .12rem;
        }
      }


      .previous-registration-list {
        padding: 0 0 .14rem;

        .previous-registration-list-item-btns-btn {
          min-width: 1rem;
          height: 0.4rem;
          line-height: 0.34rem;
          border-radius: 0.08rem;
          padding: 0 .04rem;
        }

        .previous-registration-item-main {
          width: 4.48rem;
        }
      }

      .previous-registration-bottom {
        margin-bottom: 0;
      }
    }

  }
</style>
