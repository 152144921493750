const getToken = state => {
  return state.token
};
const getUserInfo = state => {
  return state.userInfo
};

export {
  getToken,
  getUserInfo
}
