<template>
  <div class="interview-list" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <div class="interview-list-tabs">
      <div v-for="(item,index) in tabs" class="interview-list-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <PageTitle :message="'面试列表'"></PageTitle>
    <div v-if="interviewList.length!==0" class="interview-list-list">
      <div class="interview-list-list-item"
           v-for="(item,index) in changeCurrentTables" :key="index" >
        <div class="interview-list-list-item-title">{{item.interview_title}}</div>
        <div class="interview-list-list-item-main">
          <div>面试地址:{{item.interview_address}}</div>
          <div>面试区域:{{item.interview_area}}</div>
          <div>面试开始时间：{{item.interview_begin_time}}</div>
          <div>面试结束时间：{{item.interview_end_time}}</div>
        </div>
        <div class="line"></div>
        <div class="interview-list-list-item-btns">
          <Button type="success" @click="toInterviewAdmission(item.id)">查看面试详情</Button>
          <!--          <div class="interview-list-list-item-btns-btn btn-score-inquiry" @click="toInterviewAdmission(item.id)">查看面试详情</div>-->
        </div>
      </div>
    </div>

    <!--   ---------------- 分页按钮-------------------------------->
    <div v-if="interviewList.length!==0" class="interview-list-bottom">
      <PageBtns :pageLength="Math.ceil(interviewList.length / pageSize)"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
    <NoData v-if="interviewList.length===0||interviewList===null" :dataTip="'暂无数据'"></NoData>

    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import ListItem from "../../components/ListItem";
  import PageBtns from "../../components/PageBtns";
  import NoData from "../../components/NoData";
  import menuListOpen from "../../components/menuListOpen";
  import {Drawer, Page, Button} from 'view-design';
  import {apiGetInterviewList, apiGetPassExam} from "../../request/api";


  export default {
    components: {
      PageTitle,
      ListItem,
      Page,
      Drawer,
      PageBtns,
      NoData,
      Button,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: '',
        interviewList: [],

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 3,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
      }
    },
    created() {
      this.$emit('header', true);
      this.$emit('footer', true);
      apiGetInterviewList({
        id: localStorage.getItem("exam_id")
      }).then((res) => {
        this.interviewList = res.data;
      })
    },
    computed: {
      changeCurrentTables() {
        let {currentPage, pageSize} = this;
        return this.interviewList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      },
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:

            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({name: 'previousRegistration'});
            apiGetPassExam().then((res) => {
              this.interviewList = res.data;
            });
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }
      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        this.backToTop();
      },
      toInterviewAdmission(id) {
        localStorage.setItem("interviewId", id)
        this.$router.push({name: 'interviewAdmission'})
      },
    }
  }
</script>

<style lang="less" scoped>

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .interview-list {
    /*padding-top: .46rem;*/
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      padding: 0 0 .3rem;

      &-main {
        width: 100%;
        font-size: .14px;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
        margin-bottom: .19rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding: 0 2rem .2rem;

      &-item {
        width: 100%;
        padding: .2rem;
        margin-bottom: .28rem;
        font-size: .14rem;
        color: #636363;
        box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

        &-title {
          font-size: .18rem;
          color: #1F4DB6;
          padding-left: .12rem;
          margin-bottom: .2rem;
          font-family: PingFang-SC-Medium;
        }

        &-main {
          display: flex;
          flex-direction: column;
          padding: 0 .12rem;
          margin-bottom: .25rem;
        }

        .line {
          width: 100%;
          height: 1px;
          background: #BFBFBF;
          margin-bottom: .24rem;
        }

        &-btns {
          display: flex;
          justify-content: flex-end;
          padding: .1rem .34rem;

          &-btn {
            min-width: .89rem;
            height: .28rem;
            line-height: .28rem;
            text-align: center;
            /*padding: .07rem .09rem;*/
            border: 1px solid #EB6100;
            border-radius: .03rem;
            cursor: pointer;
          }

          .btn-score-inquiry {
            color: #13CF21;
            border-color: #13CF21;
          }

          .btn-score-inquiry:hover {
            color: #FFFFFF;
            background: #13CF21;
          }

        }
      }

    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: .1rem;
    }

  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .interview-list {
      padding: .19rem .24rem .32rem;

      .interview-list-tabs {
        display: none;
      }

      .interview-list-announcement {
        padding-top: .17rem;
        margin-bottom: .12rem;

        .interview-list-announcement-main {
          padding: .07rem .53rem;
          margin-bottom: .12rem;
        }
      }


      .interview-list-list {
        padding: 0 0 .14rem;

        .interview-list-list-item-btns-btn {
          min-width: 1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          border-radius: 0.08rem;
          padding: 0 .04rem;
        }

        .interview-list-item-main {
          width: 4.48rem;
        }
      }

      .interview-list-bottom {
        margin-bottom: 0;
      }
    }

  }
</style>
