<template>
  <div class="confirm-login">
    <div>{{examName}}</div>
    <Button style="margin-bottom: .2rem; padding: .2rem;height: auto"
            @click="toWxLoginBindComplete"
            type="success">绑定微信
    </Button>
    <Button @click="loginCancel"
            style="margin-bottom: .2rem; padding: .2rem;height: auto;">取消
    </Button>
  </div>
</template>

<script>
  import {Button} from "view-design";
  import {apiWxBind} from "../../../request/api";
  import axios from "axios";


  export default {
    components: {
      Button,
    },
    data() {
      return {
        examName: "",
      }
    },
    created() {
      this.$emit('header', false);
      this.$emit('footer', false);
      // let exam_name = this.$route.query.exam_name;

      this.examName = this.$route.query.exam_name;
      localStorage.setItem("exam_name", this.$route.query.exam_name)
    },
    mounted() {

    },
    methods: {
      toWxLoginBindComplete() {
        let appid = this.GLOBAL.appid;
        let examId = this.$route.query.exam_id;
        let randomNumber = this.$route.query.random;

        var redirectUrl = 'http://bm.ptgzrc.com/wxLoginBindComplete?exam_id=' + examId + '&random=' + randomNumber + '&user_id=' + this.$route.query.user_id;

        const path = encodeURIComponent(redirectUrl)
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + path + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect')

      },
      loginCancel() {

      }
    },
    destroyed() {
    }
  }
</script>

<style lang="less" scoped>
  .confirm-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem .1rem;

    & > div {
      text-align: center;
      padding-bottom: .3rem;
    }
  }
</style>
