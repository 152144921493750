<template>
  <div id="app">

    <pageHeader v-if="header_show"></pageHeader>
    <router-view class="app-page"
                 v-if="isRouterAlive" v-on:header="header" v-on:footer="footer"></router-view>
    <!--    底部-->
    <pageBottom
      v-if="footer_show"></pageBottom>
  </div>
</template>

<script>
  import pageHeader from "@/components/pageHeader";
  import pageBottom from "@/components/pageBottom";
  import {apiGetBottom} from "./request/api";

  export default {
    name: 'App',
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        header_show: true,
        footer_show: true,
        isRouterAlive: true
      }
    },
    components: {
      pageHeader,
      pageBottom,
    },
    created() {
      // apiGetBottom().then((res) => {
      //   localStorage.setItem("bottomSystemInfo", JSON.stringify(res.data))
      // });

    },
    mounted() {

    },
    methods: {
      //是否显示头部
      header(bool) {
        this.header_show = bool;
      },
      //是否显示底部
      footer(bool) {
        this.footer_show = bool;
      },
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }

    }
  }
</script>

<style lang="less">
  @import "assets/css/reset.css";
  @import "assets/css/resetViewUi";
  /*@import "assets/css/flex";*/
  body {
    overflow: auto !important;
  }

  html, body, #app {
    font-family: '微软雅黑';
    box-sizing: border-box; /*为元素指定的任何内边距和边框都将在已设定的宽度和高度内进行绘制*/
    min-height: 100%;
  }
  #app{
    position: relative;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .app-page {
    box-sizing: border-box; /*为元素指定的任何内边距和边框都将在已设定的宽度和高度内进行绘制*/
    min-height: 100%;
    padding-bottom: 2.2rem !important;
    /*overflow: hidden;*/
  }
  @media screen and (max-width: 1024px) {
    .app-page{
      padding-bottom: 3.8rem !important;
    }
  }


</style>
