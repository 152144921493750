<template>
  <div class="page-register" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="page-register-container">
      <div class="page-register-tabs">
        <div v-for="(item,index) in tabs" class="page-register-tabs-item"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </div>

    <div class="register-announcement">
      <div class="register-announcement-main">当前：{{exam_name}}</div>
    </div>
    <PageTitle :message="'考生注册'"></PageTitle>
    <div class="register-tip">
      <div>
        注册提示：
        一、注册前请先仔细查阅招考公告及常见问题
        二、考生年龄满18周岁
        三、注册成功后会生成报名序号，此序号将用于登录，请考生及时记录生成的报名序号
        四、填写电子邮件地址，可用于登录及找回密码
      </div>
      <div class="register-tip-center">
        特别安全提示：
      </div>
      <div>
        近期不法分子窃取信息活动十分猖獗，因报考时需要填写大量敏感信息，特提醒考生注意以下几点：
        1、使用安装了杀毒软件的电脑，最好不使用安装过多游戏或视频插件的电脑；
        2、登录报考时最好不使用网吧等公共场所的电脑，尽量不使用公用WiFi；
        3、尽量不使用越狱破解过的智能手机(或平板)登录报考；
      </div>
    </div>

    <div class="register-card">
      <div class="register-card-main">
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">姓名</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" v-model="userName"
                   placeholder="必填，请您输入真实姓名"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">生日</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" v-model="birthday"
                   placeholder="必填，格式如：1995-12-01"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">身份证号</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" v-model="idNumber"
                   placeholder="必填，请输入身份证或港澳台"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">电子邮箱</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" v-model="email"
                   placeholder="必填，可作为登录用户名和找回密码验证邮箱"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">手机号码</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" type="number" minlength="11"
                   v-model="phoneNumber" placeholder="必填，请输入11位手机号码"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">登录密码</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" minlength="6" maxlength="18"
                   v-model="loginPassword" type="password" placeholder="必填，请输入登录密码，6-18位"></input>
          </div>
        </div>
        <div class="register-card-main-item">
          <div class="register-card-main-item-tip">确认密码</div>
          <div class="register-card-main-item-input">
            <input class="register-card-main-item-input-main" minlength="6" maxlength="18"
                   v-model="confirmPassword" type="password" placeholder="必填，请再次输入密码"></input>
          </div>
        </div>

      </div>
      <div class="register-card-btn" @click="confirmRegister">
        确认注册
      </div>
    </div>

  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";
  import {apiExamContent, apiRegister} from "../../request/api";
  import {Drawer, Message, Spin} from "view-design";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: 1,
        userName: "",
        birthday: "",
        idNumber: "",
        email: "",
        phoneNumber: "",
        loginPassword: "",
        confirmPassword: "",

        //手机端的tab弹窗控制器
        istabPopupOpen: false,

        //发布考试的单位的信息
        employer_information: {},

        exam_name: ''
      }
    },
    created() {
      this.backToTop();
      apiExamContent({
        id: this.$route.query.exam_id
      }).then((res) => {
        this.exam_name = res.data.exam_name;
        this.employer_information = res.data;

      })
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:
            this.$router.push(`/recruitmentNews/recruitmentNews?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:
            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:
            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:
            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:
            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      //点击注册按钮
      confirmRegister() {
        Spin.show({
          render: (h) => {
            return h('div', [
              h('div', 'Loading')
            ])
          }
        });
        setTimeout(() => {
          Spin.hide();
        }, 500);
        var userName = this.userName;
        var birthday = this.birthday;
        var idNumber = this.idNumber;
        var email = this.email;
        var phoneNumber = this.phoneNumber;
        var loginPassword = this.loginPassword;
        var confirmPassword = this.confirmPassword;

        // var shop_id = this.employer_information.shop_id;
        // var exam_id = this.employer_information.id;
        if (userName == "") {
          Message.info('用户名不能为空')
          return false
        }
        if (birthday == "") {
          Message.info('生日不能为空')
          return false
        }
        if (idNumber == "") {
          Message.info('身份证不能为空')
          return false
        }
        if (email == "") {
          Message.info('电子邮箱不能为空')
          return false
        }
        if (phoneNumber == "") {
          Message.info('手机号码不能为空')
          return false
        }
        if (loginPassword == "" || confirmPassword == "") {
          Message.info('密码不能为空')
          return false
        }
        if (loginPassword != confirmPassword) {
          Message.info('两次输入密码不一致')
          return false
        }

        if (this.$route.query.shop_id == '' || this.$route.query.shop_id == null) {
          return false;
        }
        if (this.$route.query.exam_id == '' || this.$route.query.exam_id == null) {
          return false;
        }
        apiRegister({
          username: userName,
          password: loginPassword,
          password_confirm: confirmPassword,
          email: email,
          mobile: phoneNumber,
          id_cards: idNumber,
          shop_id: this.$route.query.shop_id,
          exam_id: this.$route.query.exam_id,
          birthday: birthday
        }).then((res) => {
          if (res.code === 0) {
            Message.info(res.msg);//全局提示信息
            return false;
          } else {
            Message.info(res.msg);

            localStorage.setItem('exam_id', sessionStorage.getItem("exam_id"));

            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)
          }


        })
      },
    }
  }
</script>

<style lang="less" scoped>

  .page-register {
    &-container {
      padding: 0 2rem;
      color: #333333;
    }

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    .register-announcement {
      padding-top: .3rem;
      margin-bottom: .13rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    .register-tip {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: .16rem;
      padding: .15rem 2rem .19rem;
      color: #246FB0;
      background: #fcefe7;
      margin-bottom: .33rem;

      &-center {
        color: #FF5357;
      }
    }

    .register-card {
      padding: .36rem 1.4rem .53rem;
      margin: 0 2rem 1.48rem;
      background-image: url("../../assets/images/login/login_bg.png");
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      overflow: hidden;
      box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

      &-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: .5rem;

        &-item {
          display: flex;
          flex-direction: column;
          /*flex: 1.5;*/
          width: 100%;
          margin-bottom: .1rem;

          &-tip {
            font-size: .16rem;
            color: #666666;
            font-family: PingFang-SC-Medium;
          }

          &-input {
            width: 100%;
            font-size: .14rem;
            padding: .1rem 0.07rem;
            border-bottom: 1px solid #CCCCCC;

            &-main {
              display: inline-block;
              width: 100%;
              color: #666666;
              padding-left: .04rem;
              background: rgba(255, 255, 255, .1);
              border: 0;
            }
          }
        }
      }

      &-btn {
        width: 1.6rem;
        height: .3rem;
        line-height: .3rem;
        text-align: center;
        font-size: .15rem;
        color: #FFFFFF;
        background: #128bf1;
        margin: 0 auto;
      }

      &-btn:hover {
        cursor: pointer;
      }
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .page-register {
      padding: .22rem 0 0;

      .page-register-tabs {
        display: none;
      }

      .register-announcement {
        padding-top: 0;
      }

      &-container, .register-announcement-main, .register-tip {
        padding: .07rem .53rem;
      }

      .page-register-container {
        padding: 0;
      }

      /*&-title {*/
      /*  padding-bottom: .18rem;*/
      /*}*/

      .register-title {

        display: none;
      }

      .register-card {
        padding: .19rem .53rem;
        margin: 0 .36rem .45rem;

        &-main {
          margin-bottom: .42rem;

          &-item {
            width: 100%;
          }
        }
      }


    }

  }
</style>
