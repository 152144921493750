import Vue from 'vue'
import VueX from 'vuex'
import * as actions from './actions'
import * as getters from './getters'

Vue.use(VueX)

const state = {
  token: "",
  userInfo: {},
}

const mutations = {
  set_token(state, token) {
    state.token = token;
    localStorage.setItem('token', token)
  },
  set_user_info(state, userInfo) {
    state.userInfo = userInfo;
  },
  loginSuccess(){
    // this.set_token()
  }
}

//创建store 实例
export default new VueX.Store({
  actions,
  getters,
  state,
  mutations
})
