<template>
  <div class="page-index-bottom">
    <div class="page-index-bottom-main">
      <img src="../assets/images/logo.png" @click="toAddress">
      <div class="page-index-bottom-main-left">
        <div>
          <span style="padding-right: .2rem">运营单位: {{bottomSystemInfo.unit_name}}</span>
          <span>备案信息: {{bottomSystemInfo.beian}}</span>
          <span style="margin-left: 10px;">
            <img src="../assets/images/police.png"/>
            闽公网安备 35012802000227号</span>
        </div>

        <div class="page-index-bottom-main-left-item" style="display: flex;">
          <span class="page-index-bottom-main-left-item"
                style="padding-right: .2rem">联系电话: {{bottomSystemInfo.tel}}</span>
          <span style="padding-right: .2rem">{{bottomSystemInfo.email}}</span>
        </div>
       <!-- <div>
          <span class="page-index-bottom-main-left-item"
                style="padding-right: .2rem">{{bottomSystemInfo.address}}</span>
          <span>邮政编码: {{bottomSystemInfo.address_code}}</span>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script>
  import {apiGetBottom} from "../request/api";

  export default {
    name: "pageBottom",
    data() {
      return {
        bottomSystemInfo: {}
      }
    },
    created() {
      // if (!localStorage.getItem("bottomSystemInfo")) {
      //   apiGetBottom().then((res) => {
      //     this.bottomSystemInfo = res.data;
      //     localStorage.setItem("bottomSystemInfo", JSON.stringify(res.data));
      //   });
      //
      // } else {
      //   this.bottomSystemInfo = JSON.parse(localStorage.getItem("bottomSystemInfo"));
      // }
      var head = document.getElementsByTagName('head')[0];
      // var ico;
      // var title;
      //创建一个<link>标签
      var linkTag = document.createElement('link');
      apiGetBottom().then((res) => {
        this.bottomSystemInfo = res.data;
        localStorage.setItem("bottomSystemInfo", JSON.stringify(res.data));
        localStorage.setItem("page_name", res.data.name);
        //配置<link>标签的相关属性
        linkTag.setAttribute("rel", "Shortcut Icon")
        linkTag.setAttribute("type", "image/x-icon")
        //配置之前创建<link>标签的引用图标路径
        // ico = "./static/logo.png";
        // ico = res.data.icon;
        linkTag.href = res.data.icon;
        // linkTag.met = '福建省国资人才考试报名网';
        //添加到<head>头部中
        head.appendChild(linkTag)
      });

    },
    methods: {
      toAddress() {
        location.href = 'http://bszs.conac.cn/sitename?method=show&id=983C0119BE12A523E05310291AACEC84'
      },
      skip(url) {
        location.href = "http://" + url
      },
      // toTestSelect(){
      //   this.$router.push({
      //     name:"testSelect"
      //   })
      // }
    }
  }
</script>

<style lang="less" scoped>
  .page-index-bottom {
    position: absolute;
    bottom: 0;
    height: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    padding: .32rem 4rem;
    background: #1f4db6;

    &-main {
      display: flex;
      /*justify-content: space-between;*/
      align-items: center;
      width: 100%;
      font-size: .14rem;
      padding-bottom: .1rem;
      margin-bottom: .2rem;

      & > img {
        width: .8rem;
        /*height: 2.2rem;*/
        margin-right: .4rem;
      }

      &-left {
        min-width: 1.6rem;
        display: flex;
        flex-direction: column;
        /*line-height: .42rem;*/

        & > div {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: .1rem;
        }

        span{
          display: flex;
          align-items: center;
          img{
            width: .14rem;
            height: .14rem;
            margin-right: 4px;
          }
        }
      }

    }

    &-tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .12rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .page-index-bottom {
      font-size: .2rem;
      padding: .14rem .3rem;
      height: 180px;

      .page-index-bottom-main-right {
        width: auto;
        flex-direction: row;
        /*padding-left: .2rem;*/

        &-list {
          flex-direction: row;
          padding-left: .2rem;

          &-item {
            display: flex;
            flex-wrap: nowrap;
            margin-right: .1rem;
          }
        }
      }
    }
  }
  @media screen and (max-width:700px) {
    /*.page-index-bottom{*/
    /*  height: 2.4rem;*/
    /*}*/
  }
</style>
