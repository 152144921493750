<template>
  <div class="current-home" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <!--    tabBar-->
    <div class="current-home-tabs">
      <div v-for="(item,index) in tabs" class="current-home-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="current-home-announcement">
      <div class="current-home-announcement-main">当前：{{employerInformation.exam_name}}</div>
    </div>

    <PageTitle :message="'招考导航与时间安排'"></PageTitle>

    <div class="current-home-progress-bar">
      <div class="current-home-progress-bar-main">
        <img class="current-home-progress-bar-main-bg" src="../../assets/images/progress_bar_bg.png">
        <img class="current-home-progress-bar-main-img" src="../../assets/images/progress_bar.png">
        <div v-if="examStatus=='isSignUp'">在线报名中...</div>
        <div v-if="examStatus=='isPrint'">报名结束...</div>
        <div v-if="examStatus=='isFinish'">成绩已公布...</div>
      </div>
    </div>

    <div class="current-home-card">
      <div class="current-home-card-main">
        <div>注册报名时间段：{{employerInformation.sign_up_time+" 至 "+employerInformation.sign_up_end}}</div>
        <div class="current-home-card-main-right">考试时间：{{employerInformation.exam_time||'待通知'}}</div>
        <div>申诉时间段：{{employerInformation.appeal_time}} 至 {{employerInformation.appeal_end}}</div>
        <div class="current-home-card-main-right">成绩查询：{{employerInformation.result_time||'待确定'}}</div>
        <div>打印准考证：{{employerInformation.printing_time||'待通知'}}</div>
      </div>
      <div class="line"></div>
      <div class="current-home-card-btns">
        <img src="../../assets/images/registration_notice_btn.png"
             @click="toRecruitmentNewsDetail(employerInformation)">
        <img src="../../assets/images/post_information_table_btn.png" @click="toPostInformationTable">
        <img src="../../assets/images/post_inquiry_btn.png" @click="toPostInquiry">
        <img src="../../assets/images/statistics_of_candidates_btn.png" @click="toCandidates">
        <img src="../../assets/images/registration_btn.png" @click="toLogin">
      </div>
    </div>

    <div v-if="recruitmentNewsList.length !== 0" class="current-home-item">
      <div class="current-home-item-title">
        <div class="current-home-item-title-main">
          招聘动态
        </div>
        <div class="current-home-item-title-more" @click="toRecruitmentNewsList">更多</div>
      </div>
      <div class="current-home-item-main" @click="toRecruitmentNews(item)"
           v-for="(item,index) in recruitmentNewsList.slice(0,2)" :key="index">
        <div class="current-home-item-main-left">◆ {{item.title}}</div>
        <div class="current-home-item-main-time">{{item.create_time}}</div>
      </div>
      <!--      <NoData v-if="recruitmentNewsList.length === 0 || recruitmentNewsList === null" :dataTip="'暂无数据'"></NoData>-->
    </div>

    <div v-if="recruitmentAnnouncementList.length !== 0" class="current-home-item">
      <div class="current-home-item-title">
        <div class="current-home-item-title-main">
          招聘公示
        </div>
        <div class="current-home-item-title-more" @click="toRecruitmentAnnouncement">更多</div>
      </div>
      <div class="current-home-item-main" @click="toRecruitmentAnnouncementItem(item)"
           v-for="(item,index) in recruitmentAnnouncementList.slice(0,2)" :key="index">
        <div class="current-home-item-main-left">◆ {{item.title}}</div>
        <div class="current-home-item-main-time">{{item.create_time}}</div>
      </div>
      <!--      <NoData v-if="recruitmentAnnouncementList.length === 0 || recruitmentAnnouncementList === null"-->
      <!--              :dataTip="'暂无数据'"></NoData>-->
    </div>

    <div v-if="policyList.length !== 0" class="current-home-item">
      <div class="current-home-item-title">
        <div class="current-home-item-title-main">
          政策法规
        </div>
        <div class="current-home-item-title-more" @click="toPolicyList">更多</div>
      </div>
      <div class="current-home-item-main" @click="toPolicy(item.id)" v-for="(item,index) in policyList.slice(0,2)"
           :key="index">
        <div class="current-home-item-main-left">◆ {{item.title}}</div>
        <div class="current-home-item-main-time">{{item.create_time}}</div>
      </div>
      <!--      <NoData v-if="policyList.length === 0 || policyList === null" :dataTip="'暂无数据'"></NoData>-->

    </div>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import {Drawer} from 'view-design';
  import PageTitle from "../../components/PageTitle";
  import NoData from "../../components/NoData";
  import menuListOpen from "../../components/menuListOpen";
  import {
    apiExamContent,
    apiGetRecruitmentAnnouncement,
    apiGetRecruitmentNewsList,
    apiPolicyList
  } from "../../request/api";


  export default {
    components: {
      NoData,
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: 1,
        employerInformation: {},
        recruitmentNewsList: [],
        recruitmentAnnouncementList: [],
        policyList: [],
        examStatus: 'isSignUp',
      }
    },
    created() {
      this.backToTop();
      this.examStatus = localStorage.getItem('examStatus');
      // this.examStatus = this.$route.query.examStatus;

      apiExamContent({
        id:this.$route.query.exam_id
        // id:this.$route.query.exam_id
      }).then((res)=>{
        this.employerInformation = res.data;
      })

      apiGetRecruitmentNewsList({
        shop_id: this.$route.query.shop_id,
        exam_id:this.$route.query.exam_id,
        page:1
        // shop_id: this.$route.query.shop_id
      }).then((res) => {
        this.recruitmentNewsList = res.data.list;
      });
      apiGetRecruitmentAnnouncement({
        shop_id: this.$route.query.shop_id,
        exam_id:this.$route.query.exam_id,
        page:1
        // shop_id: this.$route.query.shop_id
      }).then((res) => {
        this.recruitmentAnnouncementList = res.data.list;
      });
      apiPolicyList({
        shop_id: this.$route.query.shop_id,
        exam_id:this.$route.query.exam_id,
        page:1
        // shop_id: this.$route.query.shop_id
      }).then((res) => {
        this.policyList = res.data.list;
      });
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            apiGetRecruitmentNewsList({
              // shop_id: localStorage.getItem("shop_id"),
              // exam_id:localStorage.getItem('exam_id'),
              shop_id: this.$route.query.shop_id,
              exam_id:this.$route.query.exam_id,
              page:1
            }).then((res) => {
              this.recruitmentNewsList = res.data.list
            });
            apiGetRecruitmentAnnouncement({
              shop_id: this.$route.query.shop_id,
              exam_id:this.$route.query.exam_id,
              page:1
            }).then((res) => {
              this.recruitmentAnnouncementList = res.data.list
            });
            apiPolicyList({
              shop_id: this.$route.query.shop_id,
              exam_id:this.$route.query.exam_id,
              page:1
            }).then((res) => {
              this.policyList = res.data.list;
            });
            break;
          case 2:
            this.toRecruitmentNewsList();
            break;
          case 3:
            this.toRecruitmentAnnouncement();

            break;
          case 4:
            this.toPolicyList();

            break;
          case 5:
            this.toPostInquiry();

            break;
          case 6:
            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.toLogin();

            break;
        }
      },
      toExamineeInformation() {
        this.$router.push(`/examineeInformation/examineeInformation?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      toLogin() {
        this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      toPostInquiry() {

        this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      // 跳转到招聘动态列表
      toRecruitmentNewsList() {

        this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      //报考公告
      toRecruitmentNewsDetail(info){
        this.$router.push(`/recruitmentNewsList/recruitmentNews/recruitmentNews?Notice=Notice&exam_id=${this.employerInformation.id}`)

      },
      // 跳转到招聘动态详情
      toRecruitmentNews(info) {
        localStorage.setItem('create_time',info.create_time)
        localStorage.setItem('Notice','Notice')
        localStorage.setItem("recruitmentNewsId", info.id);
        this.$router.push(`/recruitmentNewsList/recruitmentNews/recruitmentNews?recruitmentNewsId=${info.id}&exam_id=${this.employerInformation.id}&shop_id=${this.$route.query.shop_id}`)
      },
      // 跳转到岗位信息表
      toPostInformationTable() {

        this.$router.push(`/postInformationTable/postInformationTable?shop_id=${this.employerInformation.shop_id}&&exam_id=${this.employerInformation.id}`).then(()=>{
          this.backToTop();
        })
      },
      // 跳转到招聘公示列表
      toRecruitmentAnnouncement(id) {
        // localStorage.setItem("recruitmentAnnouncementItemId", id);


        this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      // 跳转到招聘公示详情
      toRecruitmentAnnouncementItem(item) {

        localStorage.setItem("recruitmentAnnouncementItemId", item.id)
        this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncementItem/recruitmentAnnouncementItem?recruitmentAnnouncementItemId=${item.id}&shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

      },
      // 跳转到报考人数统计
      toCandidates() {

        this.$router.push(`/candidates/candidates?shop_id=${this.employerInformation.shop_id}&&exam_id=${this.employerInformation.id}`)
          .then(()=>{
          this.backToTop();
        })
      },
      //政策列表
      toPolicyList() {

        this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      },
      //政策详情
      toPolicy(id) {
        localStorage.setItem("policyId", id);

        this.$router.push(`/policy/policy?policyId=${id}&shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

      },
    }
  }
</script>

<style lang="less" scoped>
  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .current-home {
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      margin-bottom: .17rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    &-progress-bar {
      width: 100%;
      height: .45rem;
      margin-bottom: .33rem;
      padding: 0 2rem;

      &-main {
        position: relative;
        width: 100%;

        &-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: .5rem;
          border-radius: .24rem;
        }

        &-img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 2.7rem;
          height: .5rem;

        }

        & > div {
          position: absolute;
          top: .13rem;
          left: 0.78rem;
          z-index: 3;
          color: #FFFFFF;
        }
      }
    }

    &-card {
      /*width: 100%;*/
      padding: .38rem .28rem .36rem;
      margin: 0 2rem .62rem;
      font-size: .16rem;
      color: #636363;
      box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);
      box-sizing: border-box;

      &-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 .12rem;
        margin-bottom: .25rem;

        & > div {
          display: flex;
          flex: 1 0 auto;
          /*width: 50%;*/
          margin-bottom: .16rem;
        }

        &-right {
          /*text-align: right;*/
        }
      }

      &-main > div:nth-child(odd) {
        /*width: 60%;*/
      }

      .line {
        width: 100%;
        height: 1px;
        background: #BFBFBF;
        margin-bottom: .24rem;
      }

      &-btns {
        display: flex;
        justify-content: space-between;
        padding: .1rem .34rem;

        & > img {
          width: 1rem;
          height: .39rem;
          cursor: pointer;
        }
      }
    }

    &-item {
      color: #1F4DB6;
      padding: 0 2rem;
      margin-bottom: .96rem;

      &-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: .3rem;
        padding-bottom: .2rem;
        border-bottom: .02rem solid #b1b8c7;
        font-size: .18rem;

        &-main {
          color: #333333;
          font-family: PingFang-SC-Bold;
          padding-left: .2rem;
          border-left: .04rem solid #1f4db6;
        }

      }


      &-main {
        display: flex;
        justify-content: space-between;
        font-size: .16rem;

        &-left {
          width: 7.24rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 auto;
          color: #000000;
        }
      }

      .current-home-title-more, &-main:hover {
        cursor: pointer;
      }
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .current-home {

      .current-home-tabs {
        display: none;
      }

      .current-home-announcement-main {
        padding: .07rem .2rem;
      }

      .current-home-title {
        padding-bottom: .2rem;
        margin: .07rem .22rem;
      }


      .current-home-progress-bar {
        padding: 0 .53rem;

        img {
          height: .4rem;
        }

        .current-home-progress-bar-main > div {
          font-size: .14rem;
          top: 0;
          left: .3rem;
          transform: scale(.8);
        }
      }

      .current-home-item-main-left {
        width: 4.4rem;
      }

      .current-home-card {
        margin: .07rem .22rem;

        .current-home-card-main {
          flex-wrap: wrap;
        }

        &-main > div:nth-child(odd) {
          width: 100%;
        }

        &-btns {
          padding: .09rem .05rem;

          img {
            width: 1rem;
            height: .4rem;
          }
        }
      }

      .current-home-item {
        padding: .07rem .22rem;
        margin-bottom: .3rem;

        .current-home-item-title {
          padding: .07rem 0;
        }
      }

    }

  }

  @media screen and (max-width:570px) {
    .current-home-progress-bar-main > div{
      top: 0;
      left: 0;
    }
  }
</style>
