<template>
  <div class="admission-card-page" v-wechat-title="$route.meta.title">

    <div id="admission" class="print">
      <div class="admission-card print-main">
        <div class="admission-card-main">
          <div class="admission-card-main-left">
            <h1 v-html="admissionCardOpenInfo.dmission_title"></h1>
            <h2>准 考 证</h2>
            <div class="admission-card-main-left-content">
              <div class="admission-card-main-left-content-body">
                <div>姓 名：{{admissionCardOpenInfo.real_name}}</div>
                <div>准考证号：{{admissionCardOpenInfo.admission_number}}</div>
                <div>身份证号：{{admissionCardOpenInfo.id_cards}}</div>
                <div>考试科目：{{admissionCardOpenInfo.exam_subject}}</div>
                <div>报考单位(代码)：{{admissionCardOpenInfo.job_unit_name}}
                  <span v-if="admissionCardOpenInfo.unit_code!=='' && admissionCardOpenInfo.unit_code!=null">
                    ({{admissionCardOpenInfo.unit_code}})</span>
                </div>
                <div>报考职位(代码)：{{admissionCardOpenInfo.job_name}}
                  <span v-if="admissionCardOpenInfo.position_code!=='' && admissionCardOpenInfo.position_code!=null">
                    ({{admissionCardOpenInfo.position_code}})</span>
                </div>
                <div>考点地区：{{examAddress}}</div>
                <div>考点名称：{{admissionCardOpenInfo.exam_area}}</div>

                <div>考试时间：{{admissionCardOpenInfo.exam_time}}</div>
              </div>
              <div class="admission-card-main-left-content-photo">
                <img v-if="imgUrl!==''" :src="'http://'+imgUrl">
                <div style="text-align: center">考室号：{{admissionCardOpenInfo.exam_room}}
<!--                  <div></div>-->
<!--                  <div></div>-->
                </div>
                <div style="text-align: center">座位号：{{admissionCardOpenInfo.exam_seat}}
<!--                  <div></div>-->
<!--                  <div></div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="admission-card-main-right">
            <h2>注意事项</h2>
            <div v-html="admissionCardOpenInfo.matters_needing_attention"></div>
          </div>
        </div>
        <div class="admission-card-bottom" v-html="admissionCardOpenInfo.footer"></div>
      </div>
    </div>
    <Button type="primary" style="background: #1f4db6;"
            @click="downLoad">下载
    </Button>
    <!--    <Button type="primary" style="background: #1f4db6;"-->
    <!--            v-print="printObj">下载-->
    <!--    </Button>-->
<!--    <Button type="primary" style="background: #1f4db6;"-->
<!--            @click="toInterviewList">查看面试列表-->
<!--    </Button>-->
  </div>
</template>

<script>
  import {Button} from "view-design";
  import {apiGetAdmissionInfo} from "../../../request/api";
  import downloadPdf from "../../../assets/js/downloadPdf";

  export default {
    components: {
      Button
    },
    data() {
      return {
        //考试准考证信息
        admissionCardOpenInfo: {},
        imgUrl: "",

        //是否显示打印面试准考证,
        interviewTicket: "",
        user_id: '',
        printObj: {
          id: "admission",
          popTitle: '考试准考证',
          extraCss: '',
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        }
      }
    },
    created() {

      this.$emit('header', false);
      this.$emit('footer', false);

      apiGetAdmissionInfo({
        user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_"
          + localStorage.getItem("exam_id")),
        // token: localStorage.getItem("token"),
      }).then((res) => {
        this.admissionCardOpenInfo = res.data;
        this.imgUrl = res.data.picture_url;

      })
    },
    computed:{
      examAddress(){
        let str =this.admissionCardOpenInfo.exam_address;
        return str.split(',').join('');
      }
    },
    methods: {
      downLoad() {

        //把数据嵌套在 一个 div 里，获得 div
        var printData = document.getElementById("admission").innerHTML; //获得 div 里的所有 html 数据

        window.document.body.innerHTML = printData;   //把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print(); // 开始打印

      },
      toInterviewList() {

        this.$router.push(`/interviewAdmissionList/interviewAdmissionList?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`)

      },
    },
  }
</script>

<style lang="less" scoped media="print">

  .admission-card-page {
    margin: .2rem 2rem;

    #admission {
      padding: .4rem 0 0;
    }
  }

  .admission-card {
    margin-bottom: .2rem;
    border: .01rem solid #000000;

    h1 {
      font-size: .2rem;
      text-align: center;
    }

    h2 {
      font-size: .2rem;
      text-align: center;
    }

    &-main {
      display: flex;
      width: 100%;

      &-left {
        flex: 1;
        padding: .1rem;
        border-right: .01rem solid #000000;

        & > h2 {
          margin-bottom: .2rem;
        }

        &-content {
          display: flex;
          justify-content: space-between;

          &-body {
            & > div {
              margin-bottom: .1rem;
            }
          }

          &-photo {
            /*&-image {*/
            /*  width: 1.8rem;*/
            /*  height: 2rem;*/
            /*  !*background-image: url("http://rencai.zhdcn.com/uploads/20210203/b930709a740a0e8ff7bf01bf3899fc5a.png");*!*/
            /*  background-position: 0 0;*/
            /*  background-repeat: no-repeat;*/
            /*  background-size: 100% 100%;*/
            /*  overflow: hidden;*/
            /*}*/

            & > img {
              width: 1.8rem;
              height: 2rem;
              border: .01rem solid #CCCCCC;
            }
          }
        }
      }

      &-right {
        flex: 1;
        padding: .1rem;

        & > h2 {
          margin-bottom: .2rem;
        }
      }
    }

    &-bottom {
      padding: .1rem;
      text-align: center;
      border-top: .01rem solid #000000;
    }
  }

</style>
<style media="print">
  @page {
    size: auto;  /* auto is the initial value */
    margin-bottom: 0; /* 去掉打印页脚的网址 */
  }
</style>
