// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueRouter from 'vue-router'
import router from './router'
import 'view-design/dist/styles/iview.css'
import Vuex from 'vuex'
import {Message} from "view-design"
import Print from 'vue-print-nb'
import _grobal from './view/global'
import backToTop from "./assets/js/backToTop";
import ViewUI from 'view-design';
Vue.use(ViewUI);
//设置title
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(Print);
Vue.use(backToTop)

Vue.use(Vuex)
Vue.use(VueRouter)

Vue.prototype.GLOBAL = _grobal;
Vue.config.productionTip = false

// 解决重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/* eslint-disable no-new */
new Vue({
	el: '#app',
  router,
 render: h => h(App)
}).$mount("#app")
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 750) {
    htmlWidth = 750;
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320;
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
};

window.onresize = setHtmlFontSize;
setHtmlFontSize();
Message.config({
  top: 100,
  duration: 3
});

