<template>
  <div class="nodata">
    <h2>{{dataTip}}</h2>
  </div>
</template>

<script>
  export default {
    props: ['dataTip'],
    name: "NoData",
  }
</script>

<style lang="less" scoped>
  .nodata {
    padding: 1rem 0;
    width: 100%;
    font-size: .14rem;
    text-align: center;
  }
</style>
