<template>
  <div class="policy-list" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <div class="policy-list-tabs">
      <div v-for="(item,index) in tabs" class="policy-list-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'政策法规'"></PageTitle>
    <div v-if="policyList.length!=0" class="policy-list-list">
      <div class="policy-list-list-item" @click="toPolicy(item.id)"
           v-for="(item,index) in policyList" :key="index">
        <div class="policy-list-list-item-main">
          ◆ {{item.title}}
        </div>
        <div class="policy-list-list-item-time">{{item.create_time}}</div>
      </div>
    </div>
    <NoData v-if="policyList.length===0||policyList==null" :dataTip="'暂无数据'"></NoData>
    <!--   ---------------- 分页按钮-------------------------------->
    <div v-if="policyList.length!=0" class="policy-list-bottom">
      <PageBtns :pageLength="totalPage"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import {Drawer, Icon, Page} from 'view-design';
  import {apiPolicyList} from "../../request/api";
  import PageBtns from "../../components/PageBtns";
  import menuListOpen from "../../components/menuListOpen";
  import NoData from "../../components/NoData";

  export default {
    props: ['activeIndex'],
    name: "newsInformation",
    components: {
      PageTitle,
      Page,
      Drawer,
      PageBtns,
      Icon,
      menuListOpen,
      NoData,
    },
    data() {
      return {
        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        tabs: this.GLOBAL.indexTabs,
        indexTabs: this.GLOBAL.indexTabs,
        currentHomeTabs: this.GLOBAL.examHeaderTabs,
        nowIndex: 2,
        policyList: [],
        policyListNodata: false,

        //当前页号
        currentPage: 1,

        //每页大小
        pageSize: 10,
        //总页数
        totalPage: 0,

        isIndexTabEnter: "",

        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      // let _token = localStorage.getItem("token")
      this.isIndexTabEnter = this.$route.query.isIndexTabEnter;
      if (this.$route.query.isIndexTabEnter) {
        this.tabs = this.indexTabs;
        apiPolicyList({
          page: this.currentPage
        })
          .then((res) => {
            if (res.data.list.length == 0 || res.data.list == null) {
              this.policyListNodata = true;
            }
            this.policyList = res.data.list;
            this.totalPage = res.data.page
          });
      } else {
        this.tabs = this.currentHomeTabs;
        this.nowIndex = 4;
        apiPolicyList({
          open_switch: 1,
          page: this.currentPage
        })
          .then((res) => {
            if (res.data.list.length == 0 || res.data.list == null) {
              this.policyListNodata = true;
            }
            this.policyList = res.data.list;
            this.totalPage = res.data.page
          });
      }
    },
    computed: {
      // changeCurrentTables() {
      //   let {currentPage, pageSize} = this;
      //   return this.policyList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      // },

    },
    mounted() {
      if (document.getElementsByClassName("policy-list")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
      if (this.$route.query.isIndexTabEnter) {
        this.tabs = this.indexTabs;
        this.homeEnterGetPolicyList(1);
      } else {
        this.tabs = this.currentHomeTabs;
        this.nowIndex = 4;
        this.examEnterGetPolicyList(1);
      }
    },
    methods: {
      //从首页进入时获取政策列表信息
      homeEnterGetPolicyList(_currentPage) {
        apiPolicyList({
          page: _currentPage
        })
          .then((res) => {
            if (res.data.list.length == 0 || res.data.list == null) {
              this.policyListNodata = true;
            }
            this.policyList = res.data.list;
            this.totalPage = res.data.page
          });
      },
      //从本场考试进入时获取政策列表信息
      examEnterGetPolicyList(_currentPage) {
        apiPolicyList({
          open_switch: 1,
          page: _currentPage
        })
          .then((res) => {
            if (res.data.list.length == 0 || res.data.list == null) {
              this.policyListNodata = true;
            }
            this.policyList = res.data.list;
            this.totalPage = res.data.page
          });
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            if (this.isIndexTabEnter) {
              this.$router.push({name: 'newsInformation'});
            } else {
              this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 2:
            if (this.isIndexTabEnter) {

              this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

              this.homeEnterGetPolicyList(1);
            } else {

              this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 3:
            if (this.isIndexTabEnter) {

              this.$router.push({name: 'previousRegistration'});
            } else {

              this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 4:
            if (this.isIndexTabEnter) {

              this.$router.push({name: 'commonProblem'});
            } else {

              this.examEnterGetPolicyList(1);

              this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 5:
            if (this.isIndexTabEnter) {
              this.$router.push({name: 'scoreInquiry'});
            } else {
              this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 6:
            if (this.isIndexTabEnter) {
              this.$router.push({name: 'applicationForUse'});
            } else {
              this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
          case 7:
            if (this.isIndexTabEnter) {
              this.$router.push({name: 'contactUs'});
            } else {

              this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            }
            break;
        }
      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        let _page = index + 1;
        if (this.$route.query.isIndexTabEnter) {
          this.tabs = this.indexTabs;

          this.homeEnterGetPolicyList(_page);
        } else {
          this.tabs = this.currentHomeTabs;
          this.nowIndex = 4;
          this.examEnterGetPolicyList(_page);
        }
        this.backToTop();
      },

      //跳转到政策法规详情
      toPolicy(id) {
        localStorage.setItem('policyId', id)
        // this.$router.push({
        //   name: 'policy',
        // });
        this.$router.push(`/policy/policy?policyId=${id}&shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

      }
    }
  }
</script>

<style lang="less" scoped>

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .policy-list {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 0 0 .46rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin: 0 2rem .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem .3rem;

      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: .1rem;

        &-main {
          width: 7.25rem;
          font-size: .16rem;
          color: #1F4DB6;
          font-family: PingFang-SC-Bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 auto;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: .3rem;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .policy-list {
      padding: .19rem .24rem .32rem;

      .policy-list-tabs {
        display: none;
      }

      .policy-list-list {
        padding: .17rem 0 .14rem;

        &-item-time {
          /*width: 4rem;*/
          text-align: right;
          transform: scale(.8);
        }

        .policy-list-list-item-main {
          width: 4.48rem;
        }
      }
    }

  }
</style>
