<template>
  <div class="page-btns">
    <img v-show="pageClickIndex!==0" @click="previousPageClick"
         src="../assets/images/arrow_left.png">
    <div v-show="pageClickIndex===0&&pageLength!==1" class="page-btns-box"></div>
    <div class="page-btns-list">
      <div class="page-btns-list-item" v-for="(item,index) in pageBtnList"
           :id="index"
           :class="{'page-btns-list-item-click': index===pageClickIndex}"
           @click="changeCurrentPage(index)" :key="index">{{index+1}}
      </div>
    </div>
    <img v-show="pageClickIndex!==pageLength-1" @click="nextPageClick"
         src="../assets/images/arrow_right.png">
  </div>
</template>

<script>
  export default {
    props: ['pageLength'],
    name: "PageBtns",
    data() {
      return {
        pageBtnList: [],
        pageClickIndex: 0,
        currentPage: 1,
      }
    },
    created() {
      if (this.pageLength !== 0 && this.length !== 1) {
        for (let i = 1; i <= this.pageLength; i++) {
          this.pageBtnList.push(i)
        }
      } else {
        this.pageBtnList.push(1)
      }

    },
    methods: {
      //点击上一页
      previousPageClick() {
        if (this.currentPage) {
          this.currentPage--;

          this.pageClickIndex = this.currentPage;
          this.$emit("changePageNumber", this.currentPage);
        }
        return false;
      },
      //点击对应的页码
      changeCurrentPage(index) {

        this.currentPage = index;
        this.pageClickIndex = index;
        this.$emit("changePageNumber", this.currentPage);
      },
      //点击下一页
      nextPageClick() {

        if (this.currentPage < this.pageBtnList.length) {

          this.currentPage++;

          this.pageClickIndex = this.currentPage;
          this.$emit("changePageNumber", this.currentPage);
        }
        return false;
      }
    }
  }
</script>

<style lang="less" scoped>
  .page-btns {
    display: flex;
    align-items: center;
    padding: .06rem .4rem;

    & > img, .page-btns-box {
      width: .18rem;
      /*height: .35rem;*/
      margin: 0 .12rem;
    }

    &-list {
      display: flex;
      /*width: 3rem;*/
      /*overflow-x: auto;*/
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;
        width: .3rem;
        height: .3rem;
        text-align: center;
        margin: 0 .1rem;
        border: .02rem solid #999999;
      }

      &-item, img:hover {
        cursor: pointer;
      }

      &-item-click {
        color: #1F4DB6;
        border-color: #1F4DB6;
      }
    }
  }

  /*------------------------------适配移动端----------------------------*/
  @media screen and (max-width: 1024px) {
    .page-btns {
      font-size: .12rem;
    }
  }
</style>
