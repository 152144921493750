<template>
  <div class="recruitment-news" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>

    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="recruitment-news-tabs">
      <div v-for="(item,index) in tabs" class="recruitment-news-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <PageTitle :message="'新闻动态'" style="margin: 0 0 .3rem;"></PageTitle>
    <h2 v-if="newsDetail!==null">{{newsDetail.title}}</h2>
    <h5 v-if="newsDetail!==null" style="text-align: center;margin-bottom: .2rem">发布时间：{{newsDetail.create_time}}</h5>

    <div v-if="newsDetail!==null" v-html="newsDetail.content"></div>
    <NoData v-if="newsDetail==null" :dataTip="'暂无数据'"></NoData>

  </div>
</template>

<script>
  import {Drawer, Icon} from "view-design";
  import {apiNewsDetail} from "../../../request/api";
  import NoData from "../../../components/NoData";
  import PageTitle from "../../../components/PageTitle";
  import menuListOpen from "../../../components/menuListOpen";

  export default {
    components: {
      Drawer,
      NoData,
      PageTitle,
      Icon,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 1,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        newsDetail: {},
      }
    },
    created() {
      this.backToTop();
      apiNewsDetail({
        // id: localStorage.getItem("newsId"),
        id: this.$route.query.newsId,
      }).then((res) => {
        this.newsDetail = res.data
      })
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:

            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({name: 'previousRegistration'})
            break;
          case 4:
            this.$router.push({name: 'commonProblem'})
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .recruitment-news {
    font-size: .18rem;
    padding: 0 2rem .3rem;

    h2 {
      text-align: center;
      padding-bottom: .2rem;
    }

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-title {
      margin-bottom: .28rem;
      padding-bottom: .4rem;
      border-bottom: .02rem solid #b1b8c7;

      &-main {
        font-size: .32rem;
        color: #333333;
        font-family: PingFang-SC-Bold;
        padding-left: .2rem;
        border-left: .06rem solid #1f4db6;

      }
    }

    &-main {
      color: #666666;

      h2 {
        font-size: .32rem;
        color: #333333;
        font-weight: inherit;
        text-align: center;
        margin-bottom: .21rem;
      }

      &-time {
        font-size: .16rem;
        text-align: center;
        margin-bottom: .38rem;
      }

      &-title {
        font-size: .21rem;
        color: #333333;
      }

      &-annex {
        display: flex;
        flex-direction: column;

        &-item {
          margin-right: auto;
          border-bottom: 1px solid #666666;
        }
      }
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .recruitment-news {
      padding: .19rem .24rem .32rem;

      &-tabs {
        display: none;
      }

      &-title {
        padding-bottom: .18rem;
      }


    }

  }
</style>
