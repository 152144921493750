<template>
	<div class="page-login" v-wechat-title="$route.meta.title">

		<menuListOpen></menuListOpen>
		<Drawer placement="left" :closable="false" v-model="istabPopupOpen">
			<div class="tabs-popup">
				<div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
					@click="changeTabsIndex(index)" :key="index">{{item}}
				</div>
			</div>
		</Drawer>

		<!--    ------------------------------------------------------------------------ -->

		<!--    tabBar-->
		<div class="page-login-container">
			<div class="page-login-tabs">
				<div v-for="(item,index) in tabs" class="page-login-tabs-item" :class="{item_click:nowIndex==index}"
					@click="changeTabsIndex(index)" :key="index">{{item}}
				</div>
			</div>
		</div>

		<div class="login-announcement">
			<div class="login-announcement-main">当前：{{exam_name}}</div>
		</div>
		<PageTitle :message="'考生登录'"></PageTitle>
		<div class="login-tip">
			<div>
				本网站
				<span>各报名期次数据相互隔离</span>
				，已经在本网站其他期次注册过的考生，如要报考此期次请
				<span class="login-tip-link" @click="toRegister">重新注册</span>
				！ 要使用微信登录需先在考生界面绑定账号。
			</div>
			<div>
				一台电脑同一时刻只能登录一个考生帐号，如果登录多个考生帐号，之前的登录会被退出。 如果一直无法成功登录，请换一台电脑或浏览器重试。
			</div>
			<div>
				<span>安全提示：</span>
				请使用安装了杀毒软件的电脑，尽量不要使用公用电脑或公用WiFi登录，尽量不使用越狱破解过的智能手机(或平板)登录。
			</div>
		</div>

		<div class="login-card">
			<div class="login-card-main">
				<div class="login-card-main-top">
					<div class="login-card-main-top-item" :class="{active: activeIndex==0}" @click="changeActive(0)">
						<img class="login-card-main-top-item-icon" src="../../assets/images/login/login_user_icon.png">
						<span>账号密码登录</span>
					</div>
					<div class="login-card-main-top-item" :class="{active:activeIndex==1}" @click="changeActive(1)">
						<img src="../../assets/images/login/login_qrcode_icon.png">
						<span>扫码登录</span>
					</div>
				</div>

				<div v-if="activeIndex==0" class="login-card-main-body">
					<div class="login-card-main-body-item">
						<div class="login-card-main-body-item-tip">序号或邮箱</div>
						<div class="login-card-main-body-item-input">
							<input placeholder="输入报名序号或邮箱" v-model="email"></input>
						</div>
					</div>
					<div class="login-card-main-body-item">
						<div class="login-card-main-body-item-tip">登录密码</div>
						<div class="login-card-main-body-item-input">
							<input placeholder="输入登录密码" type="password" v-model="password"></input>
						</div>
					</div>
					<div class="login-card-main-body-bottom">
						<span @click="toRegister">考生注册</span>
						<span @click="toQueryRegistrationNumber">查询报名序号</span>
						<span @click="toFindPassword">找回密码</span>
					</div>
				</div>
				<div v-if="activeIndex==0" class="login-card-main-btn" @click="loginClick">
					登录
				</div>
				<div v-if="activeIndex==1" class="main-box" @click="toConfirmLogin">
					<!--          <div class="qrcode" ref="qrCodeUrl"></div>-->
					<vue-qr :text="qrCodeUrl" :size="200"></vue-qr>
				</div>

			</div>
			<!--      <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>-->
		</div>

	</div>
</template>

<script>
	import {
		Drawer,
		Message,
		Spin,
		TabPane,
		Tabs,
		Icon
	} from 'view-design';
	import {
		apiExamContent,
		apiGetWxResult,
		apiLogin
	} from "../../request/api";
	import PageTitle from "../../components/PageTitle";
	import menuListOpen from "../../components/menuListOpen";
	import vueQr from 'vue-qr';
	import {
		setInterval,
		clearInterval
	} from "timers";
	import axios from 'axios'; // 引入axios
	export default {

		components: {
			Tabs,
			TabPane,
			Drawer,
			Spin,
			PageTitle,
			Icon,
			vueQr,
			menuListOpen,
		},
		data() {
			return {
				getWxRwsult: null,
				dataList: [],
				tabs: this.GLOBAL.examHeaderTabs,
				nowIndex: 7,
				activeIndex: 0,
				email: '',
				password: '',
				loggedNumber: 0,

				//手机端的tab弹窗控制器
				istabPopupOpen: false,
				employer_information: {},
				exam_name: '',
				isToConfirmLogin: false,
				examId: "",
				randomNumber: "",
				userId: "",
				qrCodeUrl: "",
			}
		},
		created() {
			let _exam_id = this.$route.query.exam_id;
			let _shop_id = this.$route.query.shop_id;
			// var shopId_examId = _shop_id + "_" + _exam_id;
			let that = this;
			let _examineeInformation = '';
			apiExamContent({
				id: _exam_id
			}).then((res) => {

				_examineeInformation = res.data;

				that.exam_name = res.data.exam_name;
				that.employer_information = res.data;

				if (localStorage.getItem('token' + "_" + _shop_id + "_" + _exam_id)) {
					let _loggedNumber = this.loggedNumber + 1;
					// localStorage.setItem('logged_number', _loggedNumber)
					// this.$router.push({name: 'examineeInformation'})
					this.$router.push(
						`/examineeInformation/examineeInformation?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
						)

				}

				let random = "";
				for (var i = 0; i < 6; i++) //6位随机数，用以替换时间戳前6位。
				{
					random += Math.floor(Math.random() * 10);
				}
				let _randomNumber = random + new Date().getTime().toString().slice(6)
				this.randomNumber = _randomNumber;
				// this.randomNumber = new Date().getTime() + random;
				localStorage.setItem("random", _randomNumber);
				this.qrCodeUrl = 'http://bm.ptgzrc.com/wxlogin?exam_id=' + _exam_id + '&random=' + _randomNumber +
					'&exam_name=' + res.data.exam_name

			})

			this.examId = this.$route.query.exam_id;

			this.backToTop();


			this.openTimer()
		},
		mounted() {

		},
		methods: {
			//定时器：做轮询
			openTimer() {
				this.getWxRwsult = window.setInterval(() => {
					apiGetWxResult({
						type: "wxlogin",
						client_id: this.randomNumber
					}).then((res) => {
						if (res.code == 1) {
							if (res.token) {
								localStorage.setItem('token' + "_" + this.$route.query.shop_id +
									"_" + this.$route.query.exam_id, res.token);

								this.$router.push({
									name: "examineeInformation"
								});
								Message.info("登录成功");
								if(this.getWxRwsult){
									window.clearInterval(this.getWxRwsult);
									this.getWxRwsult=null
								}
							}
						}
					})
				}, 1000)

			},
			//控制移动端tab是否显示
			changeIstabPopupOpen() {
				this.istabPopupOpen = !this.istabPopupOpen
			},
			changeTabsIndex(index) {
				this.nowIndex = index;

				switch (index) {
					case 0:
						this.$router.push({
							name: 'index'
						});
						break;
					case 1:
						this.$router.push(
							`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 2:

						this.$router.push(
							`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 3:

						this.$router.push(
							`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 4:

						this.$router.push(
							`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 5:

						this.$router.push(
							`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 6:

						this.$router.push(
							`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 7:
						this.$router.push(
							`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

						break;
				}
			},
			changeActive(index) {
				this.activeIndex = index;
				//      if (this.activeIndex == 1) {
				// this.openTimer()
				//      } 
				// else {
				//   clearInterval(this.getWxRwsult)
				// }
				// if(this.isActive){
				//   this.$router.push({
				//     name:"confirmLogin"
				//   })
				// }
			},
			toConfirmLogin() {
				// window.location.href='http://rencai.zhdcn.com/wxlogin?exam_id='+this.examId+'&random='+this.randomNumber+'&exam_name='+this.employer_information.exam_name
			},
			toRegister() {
				this.$router.push(
					`/register/register?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

			},
			//查询报名序号
			toQueryRegistrationNumber() {
				this.$router.push(
					`/queryRegistrationNumber/queryRegistrationNumber?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
					)

			},
			//找回密码
			toFindPassword() {
				this.$router.push(
					`/findPassword/findPassword?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
					)

			},
			loginClick() {
				Spin.show({
					render: (h) => {
						return h('div', [
							h('div', 'Loading')
						])
					}
				});
				setTimeout(() => {
					Spin.hide();
				}, 500);

				if (this.email == "") {
					Message.info('登录账号不能为空')
					return false
				}
				if (this.password == "") {
					Message.info('密码不能为空')
					return false
				}

				if (this.$route.query.exam_id == '' || this.$route.query.exam_id == null) {
					return false;
				}

				apiLogin({
						account: this.email,
						password: this.password,
						exam_id: this.$route.query.exam_id
					})
					.then((res) => {
						// if (localStorage.getItem('logged_exam_id')
						//   === localStorage.getItem("exam_id")) {
						if (res.code === 0) {
							//全局提示信息
							Message.info(res.msg);
							return false;
						}
						var _token = res.data.userinfo.token;
						// let _loggedNumber;
						let _userInfo = res.data.userinfo;
						let shopId_examId = this.$route.query.shop_id + "_" + this.$route.query.exam_id;
						localStorage.setItem('user_id' + "_" + shopId_examId, _userInfo.user_id);
						localStorage.setItem('token' + "_" + shopId_examId, _token);

						// localStorage.setItem('exam_id', localStorage.getItem("exam_id"));

						// sessionStorage.setItem('exam_id', localStorage.getItem("exam_id"));
						localStorage.setItem('logged_exam_id' + "_" + this.$route.query.exam_id, this.$route.query
							.exam_id);
						// localStorage.setItem('shop_id', localStorage.getItem("shop_id"));
						localStorage.setItem('member_logged', 'logged');
						// localStorage.setItem('logged_number' + '_' + _userInfo.user_id, _loggedNumber);

						Message.info(res.msg); //全局提示信息

						if (localStorage.getItem('token' + "_" + shopId_examId)) {
							localStorage.setItem('shop_id', this.$route.query.shop_id)
							localStorage.setItem('exam_id', this.$route.query.exam_id)

							this.$router.push(
								`/examineeInformation/examineeInformation?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`
								)

						} else {
							// Message.info("登录账号不正确，请重新登录");
						}

						// }
					}).catch((err) => {
						localStorage.removeItem('logged_exam_id' + this.$route.query.exam_id);
						// this.$router.push({
						//   name: "register",
						// })
					})

			},
		},
		destroyed() {
			if(this.getWxRwsult){
				window.clearInterval(this.getWxRwsult);
				this.getWxRwsult=null
			}
		}

	}
</script>

<style lang="less" scoped>
	.page-login {
		.qrcode {
			display: inline-block;

			img {
				width: 132px;
				height: 132px;
				background-color: #fff; //设置白色背景色
				padding: 6px; // 利用padding的特性，挤出白边
				box-sizing: border-box;
			}
		}

		.main-box {
			width: 200px;
			height: 200px;
			margin: auto;
		}

		.btn-show {
			margin-top: 40px;
		}

		canvas {
			margin-top: 40px;
		}

		&-container {
			padding: 0 2rem;
			color: #333333;
		}

		.page-login-tabs {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			font-size: .18rem;
			color: #333333;

			&-item {
				padding: .16rem .03rem;
				border-bottom: .03rem solid #FFFFFF;
			}

			&-item:hover {
				cursor: pointer;
			}

			.item_click {
				color: #F93454;
				border-color: #F93454;
			}
		}


		.login-announcement {
			padding-top: .3rem;
			margin-bottom: .17rem;

			&-main {
				width: 100%;
				font-size: .16rem;
				padding: .1rem 2rem;
				color: #F32547;
				background: #b8deff;
			}
		}

		.login-tip {
			display: flex;
			flex-direction: column;
			width: 100%;
			font-size: .16rem;
			padding: .14rem 2rem .16rem;
			margin-bottom: .33rem;
			color: #246FB0;
			background: #fcefe7;

			&>div>span,
			.login-tip-link {
				color: #FF5357;
			}

			.login-tip-link {
				cursor: pointer;
			}
		}

		.login-card {
			padding: .36rem 1.4rem .53rem;
			margin: 0 2rem 1.48rem;
			background-image: url("../../assets/images/login/login_bg.png");
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: 100% auto;
			overflow: hidden;
			box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

			&-main {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;

				&-top {
					display: flex;
					justify-content: space-between;
					font-size: .18rem;
					width: 100%;
					color: #666666;
					margin-bottom: .54rem;

					&-item {
						display: flex;
						align-items: center;
						padding-bottom: .06rem;
						border-bottom: .03rem solid rgba(255, 255, 255, .1);

						img {
							width: .25rem;
							height: .25rem;
							margin-right: .09rem;
						}
					}

					.active {
						color: #128BF1;
						border-bottom-color: #128BF1;
					}
				}

				&-body {
					display: flex;
					flex-direction: column;
					padding: 0 .28rem;
					width: 100%;

					&-item {
						display: flex;
						flex-direction: column;
						margin-bottom: .29rem;

						&-tip {
							font-size: .16rem;
							color: #666666;
							font-family: PingFang-SC-Medium;
						}

						&-input {
							width: 100%;
							font-size: .14rem;
							padding: .1rem 0.22rem;
							border-bottom: .01rem solid #CCCCCC;

							&>input {
								display: inline-block;
								width: 100%;
								color: #666666;
								padding-left: .04rem;
								background: rgba(255, 255, 255, .1);
								border: 0;
							}
						}
					}

					&-bottom {
						display: flex;
						justify-content: space-between;
						font-size: .16rem;
						color: #128BF1;
						padding-right: .7rem;
						margin-bottom: .56rem;

						&>span {
							cursor: pointer;
						}
					}
				}

				&-btn {
					width: 1.6rem;
					height: .3rem;
					line-height: .3rem;
					text-align: center;
					font-size: .15rem;
					color: #FFFFFF;
					background: #128bf1;
					margin: 0 auto;
				}

				&-btn:hover {
					cursor: pointer;
				}
			}
		}
	}

	.tabs-popup {
		display: flex;
		flex-direction: column;

		&-item {
			padding: .4rem .1rem;
			border-bottom: .01rem solid #999999;
		}
	}

	/*-----------------------适配移动端-----------------*/
	@media screen and (max-width: 1024px) {

		.page-login {
			padding: .19rem 0 .32rem;

			.page-login-tabs {
				display: none;
			}

			.login-announcement {
				padding-top: 0;
			}

			&-container,
			.login-announcement-main,
			.login-tip {
				padding: .07rem .53rem;
			}

			.page-login-container {
				padding: 0;
			}

			&-title {
				padding-bottom: .18rem;
			}

			.login-card {
				padding: .19rem .45rem;
				margin: 0 .36rem .45rem;
			}


		}

	}
</style>