<template>
  <div class="adv-list" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
          @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <div class="adv-list-tabs">
      <div v-for="(item,index) in tabs" class="adv-list-tabs-item" :class="{item_click:nowIndex==index}"
        @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="adv-list-box">
      <div class="adv-list-box-cell" v-for="(item,index) in advList"
      :style="{'background-image': 'url('+item.picture+')'}"
       :key="index" @click="toAdvDetail(item)">
        <span>{{item.area_name}}</span>
        <!-- <img :src="item.bg"/> -->
      </div>
    </div>

  </div>
</template>

<script>
  import {
    Drawer
  } from 'view-design';
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";
  import {
    apiAdvArea
  } from "../../request/api";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: -1,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        backgroundImage:'../../assets/images/index/adv_bg.jpg',

        advList: [
        //   {
        //   area_name: '福建省省属国有企业招聘栏目',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // }, {
        //   area_name: '福州市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // }, {
        //   area_name: '厦门市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/adv_xiamen.jpg'),
        //   url:"https://zph.iguopin.com/detail?jobfairId=405"
        // }, {
        //   area_name: '漳州市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // }, {
        //   area_name: '泉州市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '三明市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '莆田市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '南平市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '龙岩市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '宁德市人民政府国有资产监督管理委员会 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '平潭综合实验区国有资产管理局 国企专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },{
        //   area_name: '国资央企招聘专栏',
        //   bg:require('../../assets/images/index/totalAdv.jpg'),
        // },
        ],
        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      apiAdvArea().then((res) => {
        let arr =res.data.list;
        
        this.advList=arr.reverse()
      });
    },
    mounted() {

    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({
              name: 'index'
            })
            break;
          case 1:
            this.$router.push({
              name: 'newsInformation'
            })
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({
              name: 'previousRegistration'
            })
            break;
          case 4:
            this.$router.push({
              name: 'commonProblem'
            })
            break;
          case 5:
            this.$router.push({
              name: 'scoreInquiry'
            })
            break;
          case 6:
            this.$router.push({
              name: 'applicationForUse'
            })
            break;
          case 7:
            this.$router.push({
              name: 'contactUs'
            });

            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },

      toAdvDetail(item) {
        // window.open(item.url)
        if(item.links){

          window.open(item.links)
        }else{
          this.$router.push(`/advListPage/noticeList?id=${item.id}`)
        }

      }
    }
  }
</script>

<style lang="less" scoped>
  .adv-list {
    padding: 0 2rem .3rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .adv-list-box{

    display: flex;
    flex-direction: column;
    width: 100%;
    &-cell{
      display: flex;
      justify-content: center;
      align-items: center;
      height: .8rem;
      font-size: .3rem;
      margin-bottom: 0.14rem;
      cursor: pointer;
      border-radius: .15rem;
      overflow: hidden;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #fff;
      span{
        color: #fff;
        text-shadow: 0.01rem 0.01rem #b2c7dc;
      }
      // img{
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }
    .adv-list-box-cell{
      font-size: .14rem;
    }

    .adv-list {
      padding: .19rem .24rem .32rem;

      &-main {
        padding-top: .18rem;
      }

      .adv-list-title {
        display: none;
      }

      .list-title {
        display: block !important;
      }

      &-tabs {
        display: none;
      }

      &-title {
        padding-bottom: .18rem;
      }


    }

  }
</style>
