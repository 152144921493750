<template>
  <div class="query-registration-number" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="query-registration-number-tabs">
      <div v-for="(item,index) in tabs" class="query-registration-number-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>

    <div class="query-registration-number-announcement">
      <div class="query-registration-number-announcement-main">当前：{{employerInformation.exam_name}}</div>
    </div>

    <PageTitle :message="'查询报名序号'"></PageTitle>
    <div class="query-registration-number-main">
      <div class="query-registration-number-main-top">
        <div>身份证号</div>
        <input placeholder="输入身份证号" v-model="idNumber"></input>
      </div>
      <div class="query-registration-number-main-content">
        <div class="query-registration-number-main-content-tip">您的报名序号是:</div>
        <div>{{signUpNo}}</div>
      </div>
      <img src="../../../assets/images/login/btn.png" @click="findSignNo">
    </div>
  </div>
</template>

<script>
  import {Drawer, Message} from "view-design";
  import {apiExamContent, apiFindSignNo} from "../../../request/api";
  import PageTitle from "../../../components/PageTitle";
  import menuListOpen from "../../../components/menuListOpen";

  export default {
    components: {
      Drawer,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: '',
        employerInformation: {},
        idNumber: '',
        signUpNo: "",

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      apiExamContent({
        id: this.$route.query.exam_id
      }).then((res) => {
        this.employerInformation = res.data;
      })
    },
    mounted() {
      if (document.getElementsByClassName("query-registration-number")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:

            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:

            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      findSignNo() {
        apiFindSignNo({
          id_cards: this.idNumber,
          exam_id: this.$route.query.exam_id
        }).then((res) => {
          this.signUpNo = res.data.sign_up_no;
          Message.info(res.data.msg)
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .query-registration-number {
    padding-bottom: .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      padding: 0 2rem;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-announcement {
      margin-bottom: .17rem;

      &-main {
        width: 100%;
        font-size: .14rem;
        padding: .1rem 2rem;
        color: #F32547;
        background: #b8deff;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      padding: 0 2rem .3rem;

      &-top {
        display: flex;
        align-items: center;
        padding-bottom: .2rem;

        & > div {
          font-size: .16rem;
          color: #333333;
          padding-right: .2rem;
        }

        & > input {
          min-width: 4.7rem;
          padding: .04rem .1rem;
          font-size: .12rem;
          color: #636363;
          border: 2px solid #C6CED4;
        }
      }

      &-content {
        display: flex;
        align-items: center;
        padding-bottom: .3rem;
        font-size: .16rem;

        &-tip {
          padding-right: .2rem;
        }
      }

      & > img {
        width: 1.4rem;
      }
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .query-registration-number {
      padding: .22rem 0 0;

      .query-registration-number-tabs {
        display: none;
      }

      .query-registration-number-announcement {
        padding-top: 0;
      }

      &-container, .query-registration-number-announcement-main, .query-registration-number-tip {
        padding: .07rem .53rem;
      }

      .query-registration-number-container {
        padding: 0;
      }

      .query-registration-number-main {
        padding: .2rem;

        &-top {
          padding-bottom: .4rem;
        }
      }

      &-title {
        padding-bottom: .12rem;
        margin: 0;
      }


    }

  }
</style>
