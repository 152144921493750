const setToken = ({commit}, token) => {
  commit('set_token', token)
}

const setUserInfo = ({commit}, userInfo) => {
  commit('set_user_info', userInfo)
}

export {
  setToken,
  setUserInfo
}
