<template>
  <div class="recruitmentAnnouncementItem" v-wechat-title="$route.meta.title">

    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->

    <!--    tabBar-->
    <div class="recruitmentAnnouncementItem-tabs">
      <div v-for="(item,index) in tabs" class="recruitmentAnnouncementItem-tabs-item"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'招聘公示'" style="margin: 0 0 .3rem;"></PageTitle>
    <h2 v-if="jobNotice!==null">{{jobNotice.title}}</h2>
    <h5 v-if="jobNotice!==null" style="text-align: center;margin-bottom: .2rem">发布时间：{{jobNotice.create_time}}</h5>
    <div v-if="jobNotice!==null" v-html="jobNotice.content"></div>
    <NoData v-if="jobNotice==null" :dataTip="'暂无数据'"></NoData>
  </div>
</template>

<script>
  import {Drawer} from "view-design";
  import {apiNewsDetail} from "../../../request/api";
  import NoData from "../../../components/NoData";
  import PageTitle from "../../../components/PageTitle";
  import menuListOpen from "../../../components/menuListOpen";

  export default {
    components: {
      Drawer,
      NoData,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: "",

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        jobNotice: {},
      }
    },
    created() {
      this.backToTop();
      apiNewsDetail({
        // id: localStorage.getItem("recruitmentAnnouncementItemId"),
        id: this.$route.query.recruitmentAnnouncementItemId,
      }).then((res) => {
        this.jobNotice = res.data;
      })
      // this.jobNotice=JSON.parse(localStorage.getItem("recruitmentAnnouncementItem"))
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:

            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:

            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 4:

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:

            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:

            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .recruitmentAnnouncementItem {
    font-size: .18rem;
    padding: 0 2rem .3rem;

    h2 {
      font-size: .2rem;
      text-align: center;
      padding-bottom: .2rem;
    }

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-main {
      color: #666666;

      h2 {
        /*font-size: .28rem;*/
        color: #333333;
        font-weight: inherit;
        text-align: center;
        margin-bottom: .21rem;
      }

      &-time {
        font-size: .16rem;
        text-align: center;
        margin-bottom: .38rem;
      }

      &-title {
        font-size: .21rem;
        color: #333333;
      }

      &-annex {
        display: flex;
        flex-direction: column;

        &-item {
          margin-right: auto;
          border-bottom: 1px solid #666666;
        }
      }
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {

    .recruitmentAnnouncementItem {

      padding: .19rem .24rem .32rem;

      &-tabs {
        display: none;
      }

      &-title {
        padding-bottom: .18rem;
      }


    }

  }
</style>
