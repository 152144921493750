import { render, staticRenderFns } from "./menuListOpen.vue?vue&type=template&id=387f0608&scoped=true&"
import script from "./menuListOpen.vue?vue&type=script&lang=js&"
export * from "./menuListOpen.vue?vue&type=script&lang=js&"
import style0 from "./menuListOpen.vue?vue&type=style&index=0&id=387f0608&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387f0608",
  null
  
)

export default component.exports