<template>
  <div class="common-problem" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->
    <div class="common-problem-tabs">
      <div v-for="(item,index) in tabs" class="common-problem-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'常见问题'" style="margin: 0"></PageTitle>
    <div class="common-problem-list">
      <div v-if="questionList.length!=0" class="common-problem-list-item" v-for="(item,index) in questionList"
           :key="index">
        <div class="common-problem-list-item-title">
          {{item.title}}
          <div v-if="clickList.indexOf(index)!=-1" @click="contentHidden(index)">
            <img src="../../assets/images/down.png">
          </div>
        </div>
        <div v-html="item.content" class="common-problem-list-item-content"
             :class="{one_line:clickList.indexOf(index)!=-1}">
        </div>
        <div class="common-problem-list-item-bottom" @click="contentHidden(index)"
             v-if="clickList.indexOf(index)==-1">
          <img src="../../assets/images/up.png">
        </div>
      </div>
      <NoData v-if="questionListNodata" :dataTip="'暂无数据'"></NoData>
      <!--   ---------------- 分页按钮-------------------------------->
      <!--      <div v-if="questionList.length!=0" class="common-problem-bottom">-->
      <!--        <PageBtns :pageLength="Math.ceil(questionList.length / this.pageSize)"-->
      <!--                  @changePageNumber="changeCurrentPage"></PageBtns>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
  import PageTitle from "../../components/PageTitle";
  import {apiGetQuestionList} from "../../request/api";
  import {Drawer, Page} from 'view-design';
  import PageBtns from "../../components/PageBtns";
  import menuListOpen from "../../components/menuListOpen";
  import NoData from "../../components/NoData";

  export default {
    name: "CommonProblem",
    components: {
      PageTitle,
      Page,
      Drawer,
      PageBtns,
      menuListOpen,
      NoData,
    },
    data() {
      return {
        questionList: [],
        questionListNodata: false,
        tabs: this.GLOBAL.indexTabs,
        clickList: [],
        isContentHidden: false,
        clickItemIndex: '',
        nowIndex: 4,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        pageSize: 8,
        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      apiGetQuestionList()
        .then((res) => {
          if (res.data == null || res.data.length == 0) {
            this.questionListNodata = true;
          }
          this.questionList = res.data
        });
    },
    mounted() {
      if (document.getElementsByClassName("common-problem")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
    },
    methods: {
      // changeCurrentPage(index) {
      //   this.currentPage = index;
      // },
      contentHidden(index) {
        if (this.clickList.indexOf(index) == -1) {
          this.clickList.push(index)
        } else {

          this.arrayDelete(this.clickList, index)
        }

        this.clickItemIndex = index;
      },
      arrayDelete(arr, item) {
        var index = arr.indexOf(item);
        if (index > -1) {
          arr.splice(index, 1);
        }
      },
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'})
            break;
          case 1:
            this.$router.push({name: 'newsInformation'})
            break;
          case 2:
            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`)

            break;
          case 3:
            this.$router.push({name: 'previousRegistration'})
            break;
          case 4:
            this.$router.push({name: 'commonProblem'});
            apiGetQuestionList()
              .then((res) => {
                this.questionList = res.data
              });
            break;
          case 5:
            this.$router.push({name: 'scoreInquiry'})
            break;
          case 6:
            this.$router.push({name: 'applicationForUse'})
            break;
          case 7:
            this.$router.push({name: 'contactUs'})
            break;
        }

      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    },
    beforeDestroy() {
      this.clickList = [];
    }
  }
</script>

<style lang="less" scoped>
  .common-problem {
    padding: 0 2rem .3rem;

    &-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin-bottom: .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding: .3rem 0;

      &-item {
        width: 100%;
        padding: .2rem;
        margin-bottom: .28rem;
        font-size: .14rem;
        color: #636363;
        box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

        &-title {
          display: flex;
          justify-content: space-between;
          font-size: .18rem;
          color: #1F4DB6;
          margin-bottom: .2rem;

          & > div {
            display: flex;
            justify-content: flex-end;
            width: 2rem;

            & > img {
              width: .17rem;
              height: .09rem;
            }
          }
        }

        &-content {
          font-size: .18rem;
          color: #636363;
          margin-bottom: .2rem;
        }

        .one_line {
          width: 100%;
          height: .19rem;
          line-height: .17rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-bottom {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          & > img {
            width: .17rem;
            height: .09rem;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: .1rem;
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .common-problem {
      /*height: 50vh;*/
      padding: .19rem .24rem .32rem;

      .common-problem-tabs {
        display: none;
      }

      .common-problem-list {
        padding: .17rem 0 .14rem;
        margin-bottom: 0;

        .common-problem-list-item {
          padding: .11rem .16rem .16rem;

          .one_line {
            height: .32rem;
            line-height: .28rem;
          }

          &-title {
            margin-bottom: .08rem;
          }

          .common-problem-list-item-main {
            width: 4.48rem;
          }

          &-content {
            margin-bottom: .14rem;
            transform: scale(.9);
          }
        }

      }
    }

  }
</style>
