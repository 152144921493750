/**
 * api接口统一管理
 */
import {get, post} from './http';
//考试公告
export const apiNewExamContent = p => post('/api/exam/new_exam_content', p);

//国企列表
export const apiAdvArea = p => post('/api/adv_area/advAreaList', p);

//广告列表
export const apiAdvList = p => post('/api/adv/advList', p);

//广告详情
export const apiAdvDetail = p => post('/api/adv/advDetail', p);

//获取当前考试的基本信息
export const apiExamContent = p => post('/api/exam/exam_content', p);

//政策法规详情
export const apiPolicyContent = p => post('/api/policy/policy_content', p);

//注册
export const apiRegister = p => post('/api/user/register', p);

//登录
export const apiLogin = p => post('/api/user/login', p);

//获取考试列表
export const apiGetExam = p => post('/api/exam/getExam', p);

// 获取新闻动态列表
export const apiNewsList = p => post('/api/news/newsList', p);

//新闻动态详情
export const apiNewsDetail = p => post('/api/news/newsDetail', p);

// 获取招聘动态列表
export const apiGetRecruitmentNewsList = p => post('/api/recruitment_news/getList', p);

// 获取招聘动态详情
// export const apiGetRecruitmentNewsDetail = p => post('/api/recruitment_news/getDetail', p);

//招聘公示旧
// export const apiGetJobNotice = p => post('/api/exam/get_job_notice', p);

//招聘公示列表及详情
export const apiGetRecruitmentAnnouncement = p => post('/api/publicity/getList', p);

//考生信息
export const apiGetUserInfo = p => post('/api/exam_user_info/getUserInfo', p);

//考生总体情况
export const apiGetUserTotal = p => post('/api/exam_user_info/getUserTotal', p);

//创建支付订单
export const apiCreateOrder = p => get('/api/pay/create_order', p);

//获取政策法规列表
export const apiPolicyList = p => post('/api/policy/policy_list', p);

// 获取用人单位信息
export const apiGetAdmin = p => post('/api/admin/getAdmin', p);

// 常见问题列表
export const apiGetQuestionList = p => post('/api/question/getQuestionList', p);

//使用申请
export const apiGetApplyForUse = p => post('/api/apply_for_use/index', p);

//联系我们
export const apiContactUs = p => post('/api/contact_us/index', p);

//岗位查询列表
export const apiGetJobList = p => post('/api/jobs/get_job_list', p);

//岗位表
export const apiGetJobList1 = p => post('/api/jobs/get_job_list1', p);

//岗位查询配置
export const apiGetJobConfig = p => post('/api/jobs/get_job_config', p);

//报考岗位
export const apiApplyForExam = p => post('/api/jobs/apply_for_exam', p);

//报考岗位列表
export const apiGetUserJobList = p => post('/api/jobs/get_user_job', p);

//查看报考信息（申诉）
export const apiGetApplyDetail = p => post('/api/jobs/getApplyDetail', p);

//报考核对数据接口
export const apiCheckApplyJob = p => post('/api/jobs/check_apply_job', p);

//添加到报考岗位列表
export const apiAddJobList = p => post('/api/jobs/add_job', p);

//核对数据完成 提交报考 进行支付流程
export const apiSubmitApply = p => post('/api/jobs/submit_apply', p);

//修改考试地
export const apiChangeAddress = p => post('/api/jobs/change_address', p);

//岗位申诉
export const apiAppealJob = p => post('/api/jobs/appeal_job', p);

//获取考试准考证详情
export const apiGetAdmissionInfo = p => post('/api/exam/get_admission_info', p);

//删除报考岗位
export const apiDeleteUserJob = p => post('/api/jobs/delete_user_job', p);

//地区接口
export const apiGetArea = p => post('/api/area/getArea', p);

// 岗位户籍设置
export const apiJobAddressLimit = p => post('/api/area/job_address_limit', p);

//考生信息录入
export const apiAddExamUser = p => post('/api/exam_user_info/add_exam_user', p);

//查询微信socket返回内容
export const apiGetWxResult = p => post('/api/user/getWxResult', p);

//微信绑定
export const apiWxBind = p => post('/api/user/wxbind', p);

//解除微信绑定
export const apiRemoveWxBind = p => post('/api/user/removdeWxBind', p);

//微信扫码登录
export const apiWxLogin = p => post('/api/user/wxlogin', p);

// 图片信息上传
export const apiUpLoadFile = p => post('/api/user/uploadfile', p);

//获取图片
export const apiGetImg = p => post('/api/user/get_img', p);

//留言咨询
export const apiSaveUserMsg = p => post('/api/exam/save_user_msg', p);

//留言类型
export const apiGetMsgType = p => post('/api/exam/get_user_msg_type', p);

// 获取考生资讯留言列表
export const apiGetMsgList = p => post('/api/exam/get_user_msg_list', p);

//操作记录
export const apiGetUserLog = p => post('/api/user/get_user_log', p);

//修改密码
export const apiResetPwdExam = p => post('/api/user/resetPwdExam', p);

//退出登录
export const apiLogout = p => post('/api/user/logout', p);

//获取岗位报考人数
export const apiGetJobsCount = p => post('/api/jobs/get_jobs_count', p);

//往期记录
export const apiGetPassExam = p => post('/api/exam/getPassExam', p);

//找回密码
export const apiFindPassword = p => post('/api/user/findPassword', p);

//查询报名序号
export const apiFindSignNo = p => post('/api/user/findSignNo', p);

//获取系统信息
export const apiGetBottom = p => post('/api/common/getBottom', p);

//考生信息页面接口
export const apiGetUserPage = p => post('/api/common/getUserPage', p);

//面试列表
export const apiGetInterviewList = p => post('/api/interview/get_interview_list', p);

//面试详情
export const apiGetInterview = p => post('/api/interview/get_interview', p);

//获取面试准考证
export const apiGetInterviewAdmissionTicket = p => post('/api/interview/get_interview_admission_ticket', p);

//成绩查询
export const apiSelectScore = p => post('/api/exam/select_score', p);

//考生信息界面的配置项
export const apiGetConfig = p => post('/api/exam_user_info/getConfig', p);

// 获取账号信息（user）
export const apiGetWxUser = p => post('/api/user/getUserInfo', p);
