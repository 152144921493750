<template>
	<div>
		<pdf
			v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
			style="display: inline-block; width: 100%"
		></pdf>
	</div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
    name: 'AppPdf',
	components: {
		pdf
	},
    props: {
	    fileSrc: {
	        type: String,
            require: true
        }
    },
	data() {
		return {
			src: '',
			numPages: undefined,
		}
	},
	mounted() {
	   this.loadUPDf(this.fileSrc)
	},
	methods:{
		loadUPDf(url){
			this.src=pdf.createLoadingTask(url)
			this.src.promise.then(pdf => {
				this.numPages = pdf.numPages;//获取pdf页数
			});
		},
		//很重要,父组件关闭前,清空子组件的值,不然第二次预览打开空白
		resetPageNum(){
			this.numPages=undefined
		}
	}
}
</script>
