<template>
  <div class="interview-page" v-wechat-title="$route.meta.title">

    <div id="interviewAdmission">
      <div class="interview">
        <div class="interview-main">
          <div class="interview-main-left">
            <h1>{{interviewInfo.interview_title}}</h1>
            <h2>准 考 证</h2>
            <div class="interview-main-left-content">
              <div class="interview-main-left-content-body">
                <div>姓 名：{{interviewInfo.nickname}}</div>
                <div>身份证号：{{interviewInfo.id_cards}}</div>
                <div>面试准考证号：{{interviewInfo.interview_admission_ticket}}</div>
                <div>报考职位(代码)：{{interviewInfo.job_title}}
                  <span v-if="interviewInfo.position_code">({{interviewInfo.position_code}})</span>
                </div>
                <div>报考单位(代码)：{{interviewInfo.unit_name}}
                  <span v-if="interviewInfo.unit_code">({{interviewInfo.unit_code}})</span>
                </div>
                <div>面试考点：{{interviewInfo.interview_area}}</div>
                <div>面试地点：{{interviewInfo.interview_address}}</div>
                <div>面试开始时间：{{interviewInfo.interview_begin_time}}</div>
                <div>面试结束时间：{{interviewInfo.interview_end_time}}</div>
              </div>
              <img v-if="interviewInfo.avatar!==''" :src="interviewInfo.avatar">
            </div>
          </div>
          <div class="interview-main-right">
            <h2>注意事项</h2>
            <div v-html="interviewInfo.matters_needing_attention"></div>
          </div>
        </div>
        <!--        <div class="interview-bottom" v-html="interviewInfo.footer"></div>-->
      </div>
    </div>
    <!--    <Button type="info" @click="downLoad" class="download-btn">下载</Button>-->
    <Button type="info" @click="downloadInterview">打印面试准考证</Button>
    <!--    <Button type="primary" style="background: #1f4db6;"-->
    <!--            v-print="printObj">打印面试准考证-->
    <!--    </Button>-->
  </div>
</template>

<script>
  import {Button} from "view-design";
  import {apiGetInterview, apiGetInterviewAdmissionTicket} from "../../../request/api";
  import * as Message from "autoprefixer";

  export default {
    components: {
      Button
    },
    data() {
      return {
        //考试准考证信息
        admissionCardOpenInfo: {},
        // imgUrl: "",

        //面试准考证信息
        interviewInfo: {},

        //是否显示打印面试准考证
        interviewTicket: "",
        printObj: {
          id: "interviewAdmission",
          popTitle: '面试准考证',
          // extraCss: 'https://www.google.com,https://www.google.com',
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
        }
      }
    },
    created() {

      this.$emit('header', false);
      this.$emit('footer', false);
      let _user_id = localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" + localStorage.getItem("exam_id"));
      apiGetInterviewAdmissionTicket({
        id: _user_id,
        exam_id:localStorage.getItem('exam_id'),
        interview_id: localStorage.getItem("interviewId")
      }).then((res) => {
        if (res.data.is_enter == 1) {
          this.interviewInfo = res.data;
        } else {
          Message.info("该考生未有面试通知")
        }
      })
      // apiGetInterview({
      //   id: localStorage.getItem("interviewId")
      // }).then((getInterviewRes) => {
      //   this.interviewInfo = getInterviewRes.data;
      // })
    },
    methods: {
      downloadInterview() {
        //把数据嵌套在 一个 div 里，获得 div
        var printData = document.getElementById("interviewAdmission").innerHTML; //获得 div 里的所有 html 数据

        window.document.body.innerHTML = printData;   //把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print(); // 开始打印
      }
    },
  }
</script>

<style lang="less" scoped media="print">


  .interview-page {
    margin: .2rem 2rem;

    #interviewAdmission {
      padding: 3rem .24rem 0;
    }
  }

  .interview {
    margin-bottom: .2rem;
    border: .01rem solid #000000;

    h1 {
      font-size: .2rem;
      text-align: center;
    }

    h2 {
      font-size: .2rem;
      text-align: center;
    }

    &-main {
      display: flex;

      &-left {
        flex: 1;
        padding: .1rem;
        border-right: .01rem solid #000000;

        & > h2 {
          margin-bottom: .2rem;
        }

        &-content {
          display: flex;
          justify-content: space-between;

          &-body {
            & > div {
              margin-bottom: .1rem;
            }
          }

          & > img {
            width: 1.8rem;
            height: 2rem;
            border: .01rem solid #CCCCCC;
          }
        }
      }

      &-right {
        flex: 1;
        padding: .1rem;

        & > h2 {
          margin-bottom: .2rem;
        }
      }
    }

    &-bottom {
      padding: .1rem;
      text-align: center;
      border-top: .01rem solid #000000;
    }
  }

</style>
<style media="print">
  @page {
    size: auto;  /* auto is the initial value */
    margin-bottom: 0; /* 去掉打印页脚的网址 */
  }
</style>
