export default {
  install(Vue, options) {
    //回到顶部
    Vue.prototype.backToTop = function () {
      // chrome
      document.body.scrollTop = 200;
      // firefox
      document.documentElement.scrollTop = 200;
      // safari
      window.pageYOffset = 200;
    }
  }
}
