<template>
  <div class="container-top-title">
    <img class="container-top-title-icon" src="../assets/images/index/title_left_icon.png">
    <span>{{message}}</span>
    <img class="container-top-title-icon" src="../assets/images/index/title_right_icon.png">
  </div>
</template>

<script>
  export default {
    name: "ListTitle",
    props: ['message']
  }
</script>

<style lang="less" scoped>
  .container-top-title {
    width: 100%;
    text-align: center;
    font-size: .2rem;
    font-family: PingFang-SC-Bold;

    &-icon {
      width: .18rem;
      /*height: .2rem;*/
    }

    & > span {
      padding: 0 .2rem;
    }

    & > img {
      height: auto;
    }
  }

</style>
