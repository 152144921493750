<template>
  <div class="select-page" id="closeSelect21">
    <div class="select-page-input" id="closeSelect22"
         @click="selectMainOpen(disabled)" :style="{'border-bottom': noBorder}">
      {{selectedValue}}
    </div>
    <div v-show="isSelectMainOpen" class="select-page-main" id="closeSelect23">
      <div class="select-page-main-first">
        <div class="select-page-main-first-list">
          <div class="select-page-main-first-list-item" id="closeSelect24"
               @click="firstListItemClick(item,index)"
               v-for="(item,index) in firstList">{{item.name}}
          </div>
        </div>
      </div>
      <div v-show="secondListOpen" class="select-page-main-first">
        <div class="select-page-main-first-list">
          <div class="select-page-main-first-list-item" id="closeSelect25"
               @click="secondListItemClick(item,index)"
               v-for="(item,index) in secondList">{{item.name}}
          </div>
        </div>
      </div>
      <div v-show="thirdListOpen" class="select-page-main-first">
        <div class="select-page-main-first-list">
          <div class="select-page-main-first-list-item" id="closeSelect26"
               @click="thirdListItemClick(item,index)"
               v-for="(item,index) in thirdList">{{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {apiGetArea} from "../request/api";

  export default {
    props: [
      'noBorder',
      'selectedValueIdStr',
      'disabled'
    ],
    name: "ThreeSelectPage",
    data() {
      return {
        selectedValue: "未选择",
        firstList: [],
        secondList: [],
        thirdList: [],
        firstItem: "",
        firstItemId: "",
        secondItem: "",
        secondItemId: "",
        thirdItem: "",
        thirdItemId: "",
        isSelectMainOpen: false,
        secondListOpen: false,
        thirdListOpen: false,
        idStr: "",
      }
    },
    created() {
    },
    mounted() {
      document.addEventListener('click', (e) => {
        if (e.target.id !== 'closeSelect21' && e.target.id !== 'closeSelect22'&& e.target.id !== 'closeSelect23'&&e.target.id !== 'closeSelect24' && e.target.id !== 'closeSelect25'&& e.target.id !== 'closeSelect26') {
          this.isSelectMainOpen = false
        }
      })
    },
    methods: {
      getInitInfo() {
        let firstItemStr = "";
        let secondItemStr = "";
        let thirdItemStr = "";

        if (localStorage.getItem("selectedIdStr") !== "" && localStorage.getItem("selectedIdStr") != null) {

          this.idStr = localStorage.getItem("selectedIdStr");
          var _selectedIdStr = localStorage.getItem("selectedIdStr");
          apiGetArea({
            id: 0
          }).then((firstRes) => {
            this.firstList = firstRes.data;
            firstRes.data.forEach((item) => {
              if (item.id == _selectedIdStr.split(",")[0]) {
                firstItemStr = item.name;
                this.firstItem = item.name;
                this.selectedValue = firstItemStr + secondItemStr + thirdItemStr;
                let firstSelectedItem = {
                  value: firstItemStr + secondItemStr + thirdItemStr,
                  id: item.id
                }
                this.$emit("changeFirstListItem", firstSelectedItem);
              }
            })
          });
          apiGetArea({
            id: _selectedIdStr.split(",")[0]
          }).then((secondRes) => {
            this.secondList = secondRes.data;
            secondRes.data.forEach((item) => {
              if (item.id == _selectedIdStr.split(",")[1]) {
                secondItemStr = item.name;
                this.secondItem = item.name;
                this.selectedValue = firstItemStr + secondItemStr + thirdItemStr;
                let secondSelectedItem = {
                  value: firstItemStr + secondItemStr + thirdItemStr,
                  id: item.id
                }
                this.$emit("changeSecondListItem", secondSelectedItem);
              }
            })
          });
          apiGetArea({
            id: _selectedIdStr.split(",")[1]
          }).then((thirdRes) => {
            this.thirdList = thirdRes.data;
            thirdRes.data.forEach((item) => {
              if (item.id == _selectedIdStr.split(",")[2]) {
                thirdItemStr = item.name;
                this.thirdItem = item.name;
                this.selectedValue = firstItemStr + secondItemStr + thirdItemStr;
                let thirdSelectedItem = {
                  value: firstItemStr + secondItemStr + thirdItemStr,
                  id: item.id
                }
                this.$emit("changeThirdListItem", thirdSelectedItem);
              }
            })
          });

        } else {
          apiGetArea({
            id: 0
          }).then((res) => {
            this.firstList = res.data;
          });
        }
      },
      selectMainOpen(disabled) {
        if (!disabled) {
          this.isSelectMainOpen = !this.isSelectMainOpen;
        }
      },
      firstListItemClick(firstListItem) {
        this.secondListOpen = true;
        this.isSelectMainOpen = true;
        this.firstItem = firstListItem.name;
        this.selectedValue = this.firstItem + this.secondItem + this.thirdItem;
        let firstSelectedItem = {
          value: this.firstItem + this.secondItem + this.thirdItem,
          id: firstListItem.id
        }
        this.$emit("changeFirstListItem", firstSelectedItem);
        apiGetArea({
          id: firstListItem.id
        }).then((res) => {
          this.secondList = res.data;
        });
      },
      secondListItemClick(secondListItem) {
        this.thirdListOpen = true;
        this.isSelectMainOpen = true;
        this.secondItem = secondListItem.name;
        apiGetArea({
          id: secondListItem.id
        }).then((res) => {
          let secondSelectedItem = {
            value: this.firstItem + this.secondItem + this.thirdItem,
            id: secondListItem.id
          }
          this.selectedValue = this.firstItem + this.secondItem + this.thirdItem;
          this.thirdList = res.data;
          this.$emit("changeSecondListItem", secondSelectedItem);
        });
      },
      thirdListItemClick(thirdListItem) {
        this.thirdItem = thirdListItem.name;
        this.isSelectMainOpen = false;
        apiGetArea({
          id: thirdListItem.id
        }).then((res) => {
          let thirdSelectedItem = {
            value: this.firstItem + this.secondItem + this.thirdItem,
            id: thirdListItem.id
          }
          this.selectedValue = this.firstItem + this.secondItem + this.thirdItem;
          this.$emit("changeThirdListItem", thirdSelectedItem);
        });
      }
    },
    destroyed() {
      localStorage.removeItem("selectedIdStr")
    },
  }
</script>

<style lang="less" scoped>
  .select-page {
    position: relative;
    width: 100%;
  }

  .select-page-input {
    width: 100%;
    font-size: .12rem;
    min-height: .4rem;
    color: #666666;
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding: .1rem 0 .12rem .08rem;
  }

  .select-page-input:hover {
    cursor: pointer;
  }

  @keyframes changeHeight {
    0% {
      height: 0;
      min-width: 0;
      opacity: 0;
    }
    100% {
      height: 150%;
      min-width: 1rem;
      opacity: 1;
    }
  }

  @-webkit-keyframes changeHeight /* Safari 和 Chrome */ {
    0% {
      height: 0;
      min-width: 0;
      opacity: 0;
    }
    100% {
      height: 150%;
      min-width: 1rem;
      opacity: 1;
    }
  }

  .select-page-main {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    font-size: .12rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    background: #FFFFFF;
    animation: changeHeight 1s infinite;
    -moz-animation: changeHeight 1s infinite; /* Firefox */
    -webkit-animation: changeHeight 1s infinite; /* Safari and Chrome */
    -o-animation: changeHeight 1s infinite; /* Opera */
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &-first {
      flex: 1;
      height: 150%;
      overflow-y: auto;
      box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
      background: #ffffff;
      animation: changeHeight 1s infinite;
      -moz-animation: changeHeight 1s infinite; /* Firefox */
      -webkit-animation: changeHeight 1s infinite; /* Safari and Chrome */
      -o-animation: changeHeight 1s infinite; /* Opera */
      animation-iteration-count: 1;
      animation-fill-mode: forwards;

      &-list {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        /*animation: changeHeight 1s infinite;*/
        /*-moz-animation: changeHeight 1s infinite; !* Firefox *!*/
        /*-webkit-animation: changeHeight 1s infinite; !* Safari and Chrome *!*/
        /*-o-animation: changeHeight 1s infinite; !* Opera *!*/
        /*animation-iteration-count: 1;*/
        /*animation-fill-mode: forwards;*/

        &-item {
          flex: 1;
          min-width: 1rem;
          padding: .04rem .08rem;
          color: #666666;
        }

        &-item:hover {
          cursor: pointer;
          background: #f3f3f3;
          color: #2d8cf0;
        }

        .selected {
          color: #2d8cf0;
        }
      }
    }

  }
</style>
