<template>
	<div class="recruitment-news">

		<menuListOpen></menuListOpen>
		<Drawer placement="left" :closable="false" v-model="istabPopupOpen">
			<div class="tabs-popup">
				<div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
					@click="changeTabsIndex(index)" :key="index">{{item}}
				</div>
			</div>
		</Drawer>

		<!--    ------------------------------------------------------------------------ -->

		<!--    tabBar-->
		<div class="recruitment-news-tabs">
			<div v-for="(item,index) in tabs" class="recruitment-news-tabs-item" @click="changeTabsIndex(index)"
				:key="index">{{item}}
			</div>
		</div>
		<PageTitle v-if="!isIndexEnter" :message="'招聘动态'" style="margin: 0 0 .3rem;"></PageTitle>
		<PageTitle v-if="isIndexEnter" :message="'招聘公告'" style="margin: 0 0 .3rem;"></PageTitle>
		<!--    <h2 v-if="recruitmentNewsDetail!==null" style="font-size: .2rem;">{{recruitmentNewsDetail.title}}</h2>-->
		<h5 v-if="recruitmentNewsDetail!==null" style="text-align: center;margin-bottom: .2rem">
			发布时间：{{createTime}}</h5>
		<div v-if="recruitmentNewsDetail!==null" style="overflow: auto" v-html="recruitmentNewsDetailContent"></div>
		<NoData v-if="recruitmentNewsDetail==null" :dataTip="'暂无数据'"></NoData>

	</div>
</template>

<script>
	import {
		Drawer
	} from "view-design";
	import {
		apiGetBottom,
		apiNewsDetail,
		apiNewExamContent
	} from "../../../request/api";
	import NoData from "../../../components/NoData";
	import PageTitle from "../../../components/PageTitle";
	import menuListOpen from "../../../components/menuListOpen";

	export default {
		components: {
			Drawer,
			NoData,
			PageTitle,
			menuListOpen,
		},
		data() {
			return {
				tabs: this.GLOBAL.examHeaderTabs,
				nowIndex: 2,

				//手机端的tab弹窗控制器
				istabPopupOpen: false,
				recruitmentNewsDetail: {},
				recruitmentNewsDetailContent: "",
				createTime: '',

				//是否是首页点击广告按钮跳转进来的
				isIndexEnter: false,
			}
		},
		created() {
			this.backToTop();
			var head = document.getElementsByTagName('head')[0];
			// var ico;
			// var title;
			//创建一个<link>标签
			var linkTag = document.createElement('link');

			// apiGetBottom().then((res) => {
			//   // ico = res.icon;
			//   //更改标题文字
			//   // title = res.data.unit_name;
			//   // title = "hhhhj";
			//   //配置<link>标签的相关属性
			//   linkTag.setAttribute("rel", "Shortcut Icon")
			//   linkTag.setAttribute("type", "image/x-icon")
			//   // //更改标题文字
			//   document.getElementsByTagName("title")[0].innerText = "";
			//   document.getElementsByTagName("title")[0].innerText = res.data.name;
			//   //配置之前创建<link>标签的引用图标路径
			//   // ico = "./static/logo.png";
			//   // ico = res.data.icon;
			//   linkTag.href = res.data.icon;
			//   //添加到<head>头部中
			//   head.appendChild(linkTag)
			// })
			linkTag.setAttribute("rel", "Shortcut Icon")
			linkTag.setAttribute("type", "image/x-icon")
			// //更改标题文字
			document.getElementsByTagName("title")[0].innerText = "";

			if (localStorage.getItem('page_name')) {
				document.getElementsByTagName("title")[0].innerText = localStorage.getItem('page_name');
			}
			// document.getElementsByTagName("title")[0].innerText = localStorage.getItem('page_name');
			//配置之前创建<link>标签的引用图标路径
			// ico = "./static/logo.png";
			// ico = res.data.icon;
			// linkTag.href = res.data.icon;
			//添加到<head>头部中
			head.appendChild(linkTag)
			console.log(this.$route.query.Notice)
			if (this.$route.query.Notice) {
				this.isIndexEnter = true;
				// this.createTime = localStorage.getItem('create_time');

				apiNewExamContent({
					// id:localStorage.getItem('exam_id')
					id: this.$route.query.exam_id
				}).then((res) => {
					this.recruitmentNewsDetailContent = res.data.content;
					this.createTime = res.data.createTime
				})
			} else {
				apiNewsDetail({
					// id: localStorage.getItem("recruitmentNewsId")
					id: this.$route.query.recruitmentNewsId
				}).then((res) => {
					this.recruitmentNewsDetail = res.data;
					this.createTime = res.data.create_time;
					this.recruitmentNewsDetailContent = res.data.content
				})
			}

		},
		methods: {
			changeTabsIndex(index) {

				this.nowIndex = index;
				switch (index) {
					case 0:
						this.$router.push({
							name: 'index'
						})
						break;
					case 1:
						this.$router.push(
							`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 2:
						this.$router.push(
							`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 3:
						this.$router.push(
							`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 4:
						this.$router.push(
							`/policyList/policyList?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 5:
						this.$router.push(
							`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 6:
						this.$router.push(
							`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`
							)

						break;
					case 7:
						this.$router.push(
							`/login/login?shop_id=${this.$route.query.shop_id}&&exam_id=${this.$route.query.exam_id}`)

						break;
				}
			},
			//控制移动端tab是否显示
			changeIstabPopupOpen() {
				this.istabPopupOpen = !this.istabPopupOpen
			},
		},
		destroyed() {
			// localStorage.removeItem("Notice");
		}
	}
</script>

<style lang="less" scoped>
	.recruitment-news {
		font-size: .18rem;
		padding: 0 2rem .3rem;

		h2 {
			text-align: center;
			padding-bottom: .2rem;
			font-size: .18rem;
		}

		&-tabs {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			font-size: .18rem;
			color: #333333;
			margin-bottom: .3rem;

			&-item {
				padding: .16rem .03rem;
				border-bottom: .03rem solid #FFFFFF;
			}

			&-item:hover {
				cursor: pointer;
			}

			.item_click {
				color: #F93454;
				border-color: #F93454;
			}
		}

	}

	.tabs-popup {
		display: flex;
		flex-direction: column;

		&-item {
			padding: .4rem .1rem;
			border-bottom: .01rem solid #999999;
		}
	}

	/*-----------------------适配移动端-----------------*/
	@media screen and (max-width: 1024px) {

		.recruitment-news {

			padding: .19rem .24rem .32rem;

			&-tabs {
				display: none;
			}
		}

	}
</style>