<template>
  <div class="container-top-list-item">
    <div class="container-top-list-item-title">2011年福建船政交通职业学院公开招聘辅导员考试报名</div>
    <div class="container-top-list-item-main">
      <div>报名时间：2020年12月15日08 : 30至12月29日23 : 59</div>
      <div>打印准考证：待通知</div>
      <div>考试：待通知</div>
    </div>
    <div class="line"></div>
    <!--            Score inquiry-->
    <div class="container-top-list-item-btns">
      <div class="container-top-list-item-btns-btn btn-enter">进入</div>
      <div class="container-top-list-item-btns-btn btn-notice">公告（方案）</div>
      <div class="container-top-list-item-btns-btn btn-post-list">岗位表</div>
      <div class="container-top-list-item-btns-btn btn-population-statistics">人数统计</div>
      <div class="container-top-list-item-btns-btn btn-score-inquiry">成绩查询</div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "ListItemScoreInquiry"
  }
</script>

<style lang="less" scoped>
  .container-top-list-item {
    width: 100%;
    padding: .28rem .21rem .26rem;
    margin-bottom: .2rem;
    font-size: .12rem;
    color: #636363;
    box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

    &-title {
      font-size: .16rem;
      color: #1F4DB6;
      padding-left: .12rem;
      margin-bottom: .19rem;
      font-family: PingFang-SC-Medium;
    }

    &-main {
      display: flex;
      justify-content: space-between;
      padding: 0 .12rem;
      margin-bottom: .18rem;
    }

    .line {
      width: 100%;
      height: 1px;
      background: #BFBFBF;
      margin-bottom: .16rem;
    }

    &-btns {
      display: flex;
      justify-content: space-between;
      padding: 0 .12rem;

      &-btn {
        min-width: .89rem;
        height: .28rem;
        line-height: .28rem;
        text-align: center;
        /*padding: .07rem .09rem;*/
        border: 1px solid #EB6100;
        border-radius: .03rem;
        cursor: pointer;
      }

      .btn-enter {
        color: #EB6100;
      }

      .btn-enter:hover {
        color: #FFFFFF;
        background: #EB6100;
      }

      .btn-notice {
        padding-left: .08rem;
        color: #FF2E4C;
        border-color: #EB6100;
      }

      .btn-notice:hover {
        color: #FFFFFF;
        background: #FF2E4C;
      }

      .btn-post-list {
        color: #1F4DB6;
        border-color: #1F4DB6;
      }

      .btn-post-list:hover {
        color: #FFFFFF;
        background: #1F4DB6;
      }

      .btn-population-statistics {
        color: #657FFB;
        border-color: #657FFB;
      }

      .btn-population-statistics:hover {
        color: #FFFFFF;
        background: #657FFB;
      }

      .btn-score-inquiry {
        color: #13CF21;
        border-color: #13CF21;
      }

      .btn-score-inquiry:hover {
        color: #FFFFFF;
        background: #13CF21;
      }

    }
  }

  /*-----------------------适配移动端-----------------*/
  /*@media screen and (max-width: 1024px) {*/
  /*  .container-top-list-item-btns-btn {*/
  /*    min-width: 1rem;*/
  /*    height: 0.4rem;*/
  /*    line-height: 0.34rem;*/
  /*    border-radius: .08rem;*/
  /*  }*/
  /*}*/
</style>
