<template>
  <div class="confirm-login">
    <div>{{examName}}</div>
    <Button style="margin-bottom: .2rem; padding: .2rem;height: auto"
            @click="toLoginComplete"
            type="success">我已绑定微信，使用微信登录PC端
    </Button>
    <Button @click="loginCancel"
            style="margin-bottom: .2rem; padding: .2rem;height: auto;">取消
    </Button>
  </div>
</template>

<script>
  import {Button} from "view-design";
  import * as Message from "autoprefixer";
  import axios from "axios";

  export default {
    components: {
      Button,
    },
    data() {
      return {
        examName: "",
      }
    },
    created() {
      this.$emit('header', false);
      this.$emit('footer', false);
      // this.employer_information = JSON.parse(localStorage.getItem("employer_information"));
      let examId = this.$route.query.exam_id;
      let exam_name = this.$route.query.exam_name;
      let random = this.$route.query.random;
      this.examName = exam_name;
      localStorage.setItem("exam_name", exam_name)
      localStorage.setItem("exam_id", examId)
      localStorage.setItem("random", random)

    },
    mounted() {
    },
    methods: {
      toLoginComplete() {
        let appid = this.GLOBAL.appid;
        let examId = this.$route.query.exam_id;
        let randomNumber = this.$route.query.random;

        var redirectUrl = 'http://bm.ptgzrc.com/loginComplete?exam_id=' + examId + '&random=' + randomNumber;

        const path = encodeURIComponent(redirectUrl)
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + path + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect')
      },
      loginCancel() {
        Message.info("取消微信登录")
      }
    },
    destroyed() {
    }
  }
</script>

<style lang="less" scoped>
  .confirm-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem .1rem;

    & > div {
      text-align: center;
      padding-bottom: .3rem;
    }
  }
</style>
