<template>
	<div class="examinee-information" v-wechat-title="$route.meta.title">
		<menuListOpen></menuListOpen>
		<Drawer placement="left" :closable="false" v-model="istabPopupOpen">
			<div class="tabs-popup">
				<div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
					@click="changeTabsIndex(index)" :key="index">{{item}}
				</div>
			</div>
		</Drawer>


		<!--    tabBar-->
		<div class="examinee-information-tabs">
			<div v-for="(item,index) in tabs" class="examinee-information-tabs-item" @click="changeTabsIndex(index)"
				:key="index">{{item}}
			</div>
		</div>
		<div class="examinee-information-announcement">
			<div class="examinee-information-announcement-main">当前：{{examineeInformationAnnouncement}}</div>
		</div>

		<div class="examinee-information-title">
			<div v-if="mainTabsNowIndex===0" class="examinee-information-title-main">
				考生界面
			</div>
			<div v-if="mainTabsNowIndex===1" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===2" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===3" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===4" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===5" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<!--      <div v-if="mainTabsNowIndex===6" class="examinee-information-title-main">-->
			<!--        {{mainTabs[mainTabsNowIndex]}}-->
			<!--      </div>-->
			<div v-if="mainTabsNowIndex===6" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===7" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===8" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
			<div v-if="mainTabsNowIndex===9" class="examinee-information-title-main">
				{{mainTabs[mainTabsNowIndex]}}
			</div>
		</div>

		<!--   ------------------ 适配手机端考生界面左侧tab---------------------------------->
		<div class="examinee-information-content__tab">
			<div class="examinee-information-content__tab-main">
				<div class="examinee-information-content__tab-main-item"
					:class="{'examinee-information-content__tab-main-item-active':mainTabsNowIndex==mainTabsIndex}"
					@click="changeMainTabsItem(mainTabsIndex)" :key="mainTabsIndex"
					v-for="(item,mainTabsIndex) in mainTabs">
					<span>{{item}}</span>
				</div>
			</div>
		</div>

		<!--    <ul v-if="mainTabsNowIndex===0" class="examinee-information-tip">-->
		<!--      <li>请认真阅读招考公告及常见问题。</li>-->
		<!--      <li>请先填写完整的考生信息，按规格上传照片，点选岗位报考，选择合适的岗位。</li>-->
		<!--      <li>报名流程：选择岗位&ndash;&gt;审核通过&ndash;&gt;缴费成功&ndash;&gt;报名成功（等待打印准考证）。</li>-->
		<!--      <li>&ndash;&gt;审核不通过&ndash;&gt;申诉&ndash;&gt;申诉审核通过&ndash;&gt;缴费成功&ndash;&gt;报名成功</li>-->
		<!--      <li>&ndash;&gt;申诉审核不通过&ndash;&gt;报名失败</li>-->
		<!--      <li>&ndash;&gt;照片不合格，请重新上传照片（照片不合格的无法打印准考证）</li>-->
		<!--      <li>打印准考证流程： 点击“岗位报考”菜单&ndash;&gt;在列表中选择要打印的岗位&ndash;&gt;在“操作栏”点击“打印准考证”&ndash;&gt;在打印准考证页面点击“下载”</li>-->
		<!--    </ul>-->
		<div v-if="mainTabsNowIndex===0" class="examinee-information-tip" v-html="totalDetailNotice"></div>
		<div v-if="mainTabsNowIndex===1" class="examinee-information-tip" v-html="wechabind"></div>
		<div v-if="mainTabsNowIndex===2" class="examinee-information-tip" v-html="userInfoText"></div>
		<div v-if="mainTabsNowIndex===3" class="examinee-information-tip" v-html="pictureText"></div>
		<div v-if="mainTabsNowIndex===4" class="examinee-information-tip" v-html="certificateText"></div>
		<div v-if="mainTabsNowIndex===5" class="examinee-information-tip" v-html="applyJobText"></div>
		<div v-if="mainTabsNowIndex===6" class="examinee-information-tip" v-html="msgText"></div>

		<div class="examinee-information-main">
			<div class="examinee-information-main-left">
				<div class="examinee-information-main-left-item"
					:class="{'examinee-information-main-left-item-active':mainTabsNowIndex==mainTabsIndex}"
					@click="changeMainTabsItem(mainTabsIndex)" :key="mainTabsIndex"
					v-for="(item,mainTabsIndex) in mainTabs">
					<img v-if="mainTabsIndex==0&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/general_situation_active_icon.png">
					<img v-if="mainTabsIndex==0&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/general_situation_icon.png">
					<img v-if="mainTabsIndex==1&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/qrcode_active_icon.png">
					<img v-if="mainTabsIndex==1&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/qrcode_icon.png">
					<img v-if="mainTabsIndex==2&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/examinee_active_icon.png">
					<img v-if="mainTabsIndex==2&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/examinee_icon.png">
					<img v-if="mainTabsIndex==3&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/photo_active_icon.png">
					<img v-if="mainTabsIndex==3&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/photo_icon.png">
					<img v-if="mainTabsIndex==4&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/certificates_active_icon.png">
					<img v-if="mainTabsIndex==4&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/certificates_icon.png">
					<img v-if="mainTabsNowIndex==mainTabsIndex&&mainTabsIndex==5"
						src="../../assets/images/examinee_information/post_management_active_icon.png">
					<img v-if="mainTabsNowIndex!==mainTabsIndex&&mainTabsIndex==5"
						src="../../assets/images/examinee_information/post_management_icon.png">
					<!--          <img v-if="mainTabsIndex==6&&mainTabsNowIndex==mainTabsIndex"-->
					<!--               src="../../assets/images/examinee_information/feedback_active_icon.png">-->
					<!--          <img v-if="mainTabsIndex==6&&mainTabsNowIndex!==mainTabsIndex"-->
					<!--               src="../../assets/images/examinee_information/feedback_icon.png">-->
					<img v-if="mainTabsIndex==6&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/leaving_a_message_active_icon.png">
					<img v-if="mainTabsIndex==6&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/leaving_a_message_icon.png">
					<img v-if="mainTabsIndex==7&&mainTabsNowIndex==mainTabsIndex"
						src="../../assets/images/examinee_information/operation_record_active_icon.png">
					<img v-if="mainTabsIndex==7&&mainTabsNowIndex!==mainTabsIndex"
						src="../../assets/images/examinee_information/operation_record_icon.png">
					<!--          <img v-if="mainTabsIndex==8&&mainTabsNowIndex==mainTabsIndex"-->
					<!--               src="../../assets/images/examinee_information/password_icon.png">-->
					<Icon v-if="mainTabsIndex==8&&mainTabsNowIndex==mainTabsIndex" type="ios-lock-outline" size="18" />
					<Icon v-if="mainTabsIndex==8&&mainTabsNowIndex!==mainTabsIndex" type="ios-lock" />
					<img v-if="mainTabsIndex==9" src="../../assets/images/examinee_information/sign_out_icon.png">
					<span>{{item}}</span>
				</div>
			</div>

			<!--      总体情况-->
			<div v-if="mainTabsNowIndex===0" class="examinee-information-main-right">
				<div class="examinee-information-main-right-photo">
					<img v-if="picture_url!==''" :src="'http://'+picture_url">
					<img v-if="picture_url===''" src="@/assets/images/examinee_information/no_photo.png">
				</div>
				<div class="examinee-information-main-right-body">
					<div>报名序号：{{userTotalInfo.sign_up_no}}</div>
					<div>身份证号：{{userTotalInfo.id_cards}}</div>
					<div>姓 名：{{userTotalInfo.username}}</div>
					<div>电 话：{{userTotalInfo.mobile}}</div>
					<div>电子邮箱：{{userTotalInfo.email}}</div>
					<div>
						考生信息：
						<i v-if="userTotalInfo.exam_user_info_status===0" @click="changeMainTabsItem(2)">填写考生信息</i>
						<em v-if="userTotalInfo.exam_user_info_status===1">已填写</em>
					</div>
					<div>照片状态：
						<i v-if="picture_url===''" @click="changeMainTabsItem(3)">未上传 请上传照片</i>
						<em v-if="picture_url!==''">已上传</em>
					</div>
					<div>微信绑定：
						<span v-if="isBindWx">已绑定</span>
						<span v-if="!isBindWx">未绑定</span>
						，昵称：{{wxNickName}}
					</div>
					<div>报考情况：{{userTotalInfo.exam_count}}个岗位，{{userTotalInfo.pass_count}}个通过审核</div>
				</div>
			</div>

			<!--      微信绑定-->
			<div v-if="mainTabsNowIndex===1" class="wechat-bind">
				<div v-if="isBindWx==0" class="wechat-bind-binding">
					<div class="wechat-bind-binding-title">
						第一步
						<span>关注公众号</span>
					</div>
					<img class="wechat-bind-binding-img" src="../../assets/images/examinee_information/wx_icon.jpg">
					<div class="wechat-bind-binding-tip">
						<div>为提高通知时效性，本网站使用微信进行消息通知。</div>
						<div>
							微信公众号名称是：
							<span>福建省国资人才报名网</span>
						</div>
						<div>请先使用手机扫描二维码关注此公众号。</div>
					</div>
					<img class="wechat-bind-binding-btn" @click="toWxbind"
						src="@/assets/images/examinee_information/follow_btn.png">
				</div>
				<div v-if="isBindWx==1" class="wechat-bind-binding">
					<div class="wechat-bind-binding-title">
						第二步
						<span>绑定微信号</span>
					</div>
					<vue-qr :text="qrCodeUrl" :size="200"></vue-qr>
				</div>
				<div v-if="isBindWx==2" class="wechat-bind-binded">
					<div>已绑定微信，微信昵称为：{{wxNickName}}</div>
					<div>审核情况将通过微信实时通知。</div>
					<img :src="wxAvatar">
					<Button type="error" @click="removeWxBind">解除绑定</Button>
				</div>
			</div>

			<!--      考生信息-->
			<div v-if="mainTabsNowIndex===2" class="information-body">
				<div class="information-body-content">
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							报名序号
						</div>
						<input readonly="true" v-model="signUpNo" placeholder="必填，输入报名序号"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							电子邮箱
						</div>
						<input readonly="true" v-model="email" placeholder="必填，输入电子邮箱"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							姓名
						</div>
						<input v-model="userName" :readonly="userInfo.status===1||userInfo.status===2"
							placeholder="必填，输入姓名"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							性别
						</div>
						<Select v-model="sexItem" placeholder="未选择" :disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; border: none;font-size:.12rem;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in sexList" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							籍贯
						</div>
						<div class="area-select-input" id="closeSelect41" @click="areaSelectMainOpen">
							{{nativePlace}}
						</div>
						<div v-show="isAreaSelectMainOpen" class="area-select-main">
							<div class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="firstAreaListItemClick(item,index)" id="closeSelect42"
										v-for="(item,index) in firstAreaList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
							<div v-show="secondAreaListOpen" class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="secondAreaListItemClick(item,index)" id="closeSelect43"
										v-for="(item,index) in secondAreaList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
							<div v-show="thirdAreaListOpen" class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="thirdAreaListItemClick(item,index)" id="closeSelect44"
										v-for="(item,index) in thirdAreaList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							民族
						</div>
						<Select v-model="nationItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%;font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in nationList" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							出生日期
						</div>
						<input readonly="true" :value="showBirthday" placeholder="必填，输入出生日期"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							身份证号
						</div>
						<input readonly="true" v-model="idCards" placeholder="必填，输入身份证号"></input>
					</div>
					<div class="information-body-content-item" @click="addressPopupClose">
						<div class="information-body-content-item-tip">
							<span>*</span>
							户籍所在地
						</div>
						<ThreeSelectPage :selectedValueIdStr="selectedIdStr"
							:disabled="userInfo.status===1||userInfo.status===2" ref="threeSelectPage"
							@changeFirstListItem="changeFirstSelectedItem"
							@changeSecondListItem="changeSecondSelectedItem"
							@changeThirdListItem="changeThirdSelectedItem">
						</ThreeSelectPage>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							政治面貌
						</div>
						<Select v-model="politicCountenanceItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%;font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in politicCountenance" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							考生来源
						</div>
						<Select v-model="sourceOfCandidatesItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in sourceOfCandidates" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							手机号码
						</div>
						<input v-model="phoneNumber" type="number" :readonly="userInfo.status===1||userInfo.status===2"
							placeholder="必填，输入手机号码"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip" style="padding-left: .12rem;">
							家庭电话
						</div>
						<input v-model="homePhone" type="number" :readonly="userInfo.status===1||userInfo.status===2"
							placeholder="固话或手机号码"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							紧急联系人
						</div>
						<input v-model="emergencyContact" type="number"
							:readonly="userInfo.status===1||userInfo.status===2" placeholder="固话或手机号码"></input>
					</div>
					<div class="information-body-content-item" id="closeSelect31">
						<div class="information-body-content-item-tip">
							<span>*</span>
							通信地址
						</div>
						<div class="area-select-input" @click="addressSelectMainOpen" id="closeSelect32"
							style="display: flex;align-items: center;min-height: .2rem">
							<div id="closeSelect33">{{correspondenceAddress}}</div>
						</div>

						<div v-show="isAddressSelectMainOpen" class="area-select-main">
							<div class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="firstAddressListItemClick(item,index)" id="closeSelect34"
										v-for="(item,index) in firstAddressList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
							<div v-show="secondAddressListOpen" class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="secondAddressListItemClick(item,index)" id="closeSelect35"
										v-for="(item,index) in secondAddressList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
							<div v-show="thirdAddressListOpen" class="area-select-main-first">
								<div class="area-select-main-first-list">
									<div class="area-select-main-first-list-item"
										@click="thirdAddressListItemClick(item,index)" id="closeSelect36"
										v-for="(item,index) in thirdAddressList" :key="index">{{item.name}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							详细街道门牌号
						</div>
						<input v-model="detailedStreet" placeholder="请填写详细街道门牌号"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip" style="padding-left: .12rem;">
							邮 编
						</div>
						<input v-model="postCode" placeholder="选填"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
				</div>
				<div class="information-body-content">
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							学 历
						</div>
						<Select v-model="educationalBackgroundItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in educationalBackground" :value="item" :key="item">{{ item }}
							</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							学 位
						</div>
						<Select v-model="degreeListItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in degreeList" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							学历类别
						</div>
						<Select v-model="educationalBackgroundCategoryItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in educationalBackgroundCategory" :value="item" :key="item">
								{{item}}
							</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							婚姻状况
						</div>
						<Select v-model="maritalStatusItem" placeholder="未选择"
							:disabled="userInfo.status===1||userInfo.status===2"
							style="width: 100%; font-size:.12rem;border: none;border-bottom: 1px solid #CCCCCC;padding-bottom: .08rem;padding-left: .08rem;">
							<Option v-for="item in maritalStatus" :value="item" :key="item">{{ item }}</Option>
						</Select>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							毕业院校
						</div>
						<input v-model="graduateSchool" placeholder="输入毕业院校（系）名称"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							所学专业
						</div>
						<input v-model="major" placeholder="须与毕业证书专业名称一字不差"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip">
							<span>*</span>
							毕业时间
						</div>
						<DatePicker v-model="graduationTime" @onClick="changeGraduationTime" shortcuts="shortcuts"
							:disabled="userInfo.status===1||userInfo.status===2" type="month"
							placeholder="graduationTime"
							style="width: 100%;font-size:.12rem;padding-top: .1rem;color: #999999;">
						</DatePicker>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip" style="padding-left: .12rem;">
							工作时间
						</div>
						<DatePicker v-model="workTime" @click="changeWorkTime"
							:disabled="userInfo.status===1||userInfo.status===2" type="month" placeholder="workTime"
							style="font-size:.12rem;width: 100%;padding-top: .1rem;color: #999999;">
						</DatePicker>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip" style="padding-left: .12rem;">
							专长
						</div>
						<input v-model="expertise" placeholder="选填"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
					<div class="information-body-content-item">
						<div class="information-body-content-item-tip" style="padding-left: .12rem;">
							身高(cm)
						</div>
						<input v-model="height" placeholder="选填" type="number"
							:readonly="userInfo.status===1||userInfo.status===2"></input>
					</div>
				</div>
				<div class="information-body-resume">
					<div class="information-body-resume-title" style="padding-left: .12rem;">
						主要简历
						<i>(必填，限2000字)</i>
					</div>
					<div class="information-body-resume-main">
						<textarea maxlength="2000" v-model="mainResume"
							:disabled="userInfo.status===1||userInfo.status===2"></textarea>
					</div>
				</div>

				<div class="information-body-family">
					<div class="information-body-family-title" style="padding-left: .12rem;">
						家庭成员
						<i>(必填，限2000字)</i>
					</div>
					<div class="information-body-family-tip">
						本人承诺：本人的家庭成员及其他社会关系对本人所报考岗位，不构成所报单位回避关系。现将主要家庭成员名单填写如下：
					</div>
					<div class="information-body-family-main">
						<textarea maxlength="2000" v-model="familyMembers"
							:disabled="userInfo.status===1||userInfo.status===2"></textarea>
					</div>
				</div>

				<div class="information-body-achievement">
					<div class="information-body-achievement-title">
						主要业绩、奖惩情况等
						<i>(选填，限200字)</i>
					</div>
					<div class="information-body-achievement-main">
						<textarea maxlength="200" v-model="mainAchievements"
							:disabled="userInfo.status===1||userInfo.status===2"></textarea>
					</div>
				</div>
				<img class="information-body-btn" @click="informationCommit"
					src="@/assets/images/examinee_information/confirm_btn.png">
			</div>

			<!--      照片上传-->
			<div v-if="mainTabsNowIndex===3" class="upload-photos">
				<div v-if="examInfoStatus===0" class="upload-photos-notice">
					<img src="../../assets/images/examinee_information/warning_icon.png">
					你还未填写考生信息，不可上传照片，请先
					<i @click="changeMainTabsItem(2)">填写考生信息</i>
				</div>
				<div v-if="examInfoStatus===1" class="upload-photos-left">
					<img v-if="picture_url!==''" :src="'http://'+picture_url">
					<img v-if="picture_url===''" src="@/assets/images/examinee_information/no_photo.png">
				</div>
				<div v-if="examInfoStatus===1" class="upload-photos-right">
					<div class="upload-photos-right-tip">
						照片可上传时间段：
						<div>{{examineeInformation.sign_up_time}} - {{examineeInformation.sign_up_end}}</div>
					</div>
					<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
						:data="{img_type:'face_img',exam_id:this.examId,token:this.token}" :on-success="uploadSuccess"
						:on-error="uploadError" :show-upload-list="false">
						<img src="@/assets/images/examinee_information/upload_photos_btn.png">
					</Upload>
				</div>
			</div>

			<!--      证件上传-->
			<div v-if="mainTabsNowIndex===4" class="document-upload">
				<div v-if="examInfoStatus===0" class="document-upload-notice">
					<img src="../../assets/images/examinee_information/warning_icon.png">
					你还未填写考生信息，不可上传证件，请先
					<i @click="changeMainTabsItem(2)">填写考生信息</i>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">考生身份证</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'id_card_front',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="id_card_front===''"
								src="../../assets/images/examinee_information/idcard_positive.png">
							<img v-if="id_card_front!==''" :src="'http://'+id_card_front">
						</Upload>
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'id_card_negative',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="id_card_back===''"
								src="../../assets/images/examinee_information/idcard_back.png">
							<img v-if="id_card_back!==''" :src="'http://'+id_card_back">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">毕业证书（学历证书）</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'diploma_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="diploma_url===''"
								src="../../assets/images/examinee_information/graduation_certificate.png">
							<img v-if="diploma_url!==''" :src="'http://'+diploma_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">学位证书</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'degree_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="degree_url===''" src="../../assets/images/examinee_information/diploma.png">
							<img v-if="degree_url!==''" :src="'http://'+degree_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">学信网有效期内学历证明截图</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'xuexin_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="xuexin_url===''"
								src="../../assets/images/examinee_information/academic_certificate.png">
							<img v-if="xuexin_url!==''" :src="'http://'+xuexin_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">职称（职业资格）证书（可选）</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'zhicheng_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="zhicheng_url===''"
								src="../../assets/images/examinee_information/post_certificate.png">
							<img v-if="zhicheng_url!==''" :src="'http://'+zhicheng_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">其他1（可选）</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'other_one_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="other_one_url===''" src="../../assets/images/examinee_information/other_one.png">
							<img v-if="other_one_url!==''" :src="'http://'+other_one_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">其他2（可选）</div>
					<div class="document-upload-item-body">
						<Upload action="http://admin.ptgzrc.com/api/user/uploadfile"
							:data="{img_type:'other_two_url',exam_id:this.examId,token:this.token}"
							:on-success="uploadSuccess" :on-error="uploadError" :show-upload-list="false">
							<img v-if="other_two_url===''" src="../../assets/images/examinee_information/other_two.png">
							<img v-if="other_two_url!==''" :src="'http://'+other_two_url">
						</Upload>
					</div>
				</div>
				<div v-if="examInfoStatus===1" class="document-upload-item">
					<div class="document-upload-item-title">上传PDF（可选，如需重新上传，再次点击pdf图标，选择完再点击上传按钮，上传成功后即可替换原PDF文件；上传后可预览）</div>
					<div class="document-upload-item-body" style="margin-bottom: 20px;">
						<!-- <label style="font-weight: bold;margin-right: 10px;">文 件:</label>
						<el-input v-model="fileName" size="mini" style="width:52%; margin-left: 18px"></el-input>
						 -->
						<el-upload class="upload-demo" ref="upload" action="http://admin.ptgzrc.com/api/user/uploadfile"
							:show-file-list="false" :before-upload="beforeUpload">
							<el-button slot="trigger" v-if="pdf_url==''" size="mini" type="danger">选取文件</el-button>
							<img style="width: 98px;height: 98px;" v-if="pdf_url!==''"
								src="../../assets/images/pdf_icon.png">
						</el-upload>
					</div>
					<div style="text-align: center;">

						<el-button size="mini" @click="viewfile">预览</el-button>
						<el-button size="mini" type="primary" @click="upLoadpdf()">上传</el-button>
					</div>
				</div>
			</div>
			<!--      岗位报考-->
			<div v-show="mainTabsNowIndex===5" class="post-management">
				<div v-if="pictureStatus===0" class="post-management-item">
					<img src="../../assets/images/examinee_information/warning_icon.png">
					照片，身份证，毕业证，学信网有效期内学历证明截图未上传，不可报考，请先上传
					<!--          <i @click="changeMainTabsItem(3)"></i>-->
				</div>
				<div v-if="examInfoStatus===0" class="post-management-item">
					<img src="../../assets/images/examinee_information/warning_icon.png">
					你还未填写考生信息，不可报考，请先
					<i @click="changeMainTabsItem(2)">填写考生信息</i>
				</div>
				<!--        <div v-if="isBindWx!==2" class="post-management-item">-->
				<!--          <img src="../../assets/images/examinee_information/warning_icon.png">-->
				<!--          你还未绑定微信，不可报考，请先-->
				<!--          <i @click="changeMainTabsItem(1)">绑定微信</i>-->
				<!--        </div>-->

				<Button v-if="!isDetailsOpen&&examInfoStatus===1&&pictureStatus===1"
					style="width: 26%;background: #1f4db6;margin-bottom: .1rem;font-size: .12rem;" type="primary"
					@click="toPostInquiry">添加报考岗位
				</Button>
				<div v-if="!isDetailsOpen&&examInfoStatus===1&&pictureStatus===1" class="post-management-main">
					<div class="post-management-main-title">已选岗位列表</div>
					<div class="post-management-main-list">
						<div v-for="(item,index) in registrationPositionList" :key="index"
							class="post-management-main-list-item">
							<div class="post-management-main-list-item-header">
								<div>{{item.job_unit_name}}({{item.unit_code}})</div>
								<div>{{item.job_name}}({{item.position_code}})</div>
							</div>
							<div class="post-management-main-list-item-body">
								<div>{{statusList[item.status]}}</div>
								<div>
									<span v-if="item.no_exam==1">免笔试</span>
								</div>
								<div>考试地：{{item.address}}</div>
							</div>
							<div class="post-management-main-list-item-bottom">
								<Button type="primary"
									style="background: #1f4db6;margin-right: .2rem;margin-bottom:.1rem;font-size: .12rem;"
									@click="viewDetails(item.id)">查看详细(申诉)
								</Button>
								<Button type="primary"
									style="background: #1f4db6;margin-right: .2rem;margin-bottom:.1rem;font-size: .12rem;"
									@click="checkApplyJob(item.id)"
									:disabled="item.status!==0&&item.exam_user_status!==0">确认报考
								</Button>
								<Button type="primary"
									style="background: #1f4db6;margin-right: .2rem;margin-bottom:.1rem;font-size: .12rem;"
									:disabled="item.printing_status!==1" @click="printAdmissionCard">笔试准考证打印
								</Button>
								<Button type="primary" :disabled="!(item.pay_status == 0 && item.status == 4)"
									style="background: #1f4db6;margin-right: .2rem;margin-bottom:.1rem;font-size: .12rem;"
									@click="openPayPopup(item.id)">支付
								</Button>
								<Button type="primary" :disabled="item.status!==0&&item.status!==3"
									style="background: #1f4db6;margin-right: .2rem;margin-bottom:.1rem;font-size: .12rem;"
									@click="deleteUserJob(item.id)">
									删除
								</Button>
								<Button type="primary"
									style="background: #1f4db6;margin-bottom:.1rem;font-size: .12rem;"
									:disabled="item.printing_interview_status==0" @click="toInterviewList">面试准考证打印
								</Button>
							</div>
						</div>

					</div>
				</div>

				<!--        查看详情（申诉）-->
				<div v-if="isDetailsOpen" class="view-details">
					<div class="view-details-title">岗位报考详情</div>
					<table border="1" cellspacing="0">
						<tr>
							<td class="view-details-tables-tip">单位(代码)</td>
							<td>{{applyDetailInfoJob.unit_name}}
								<span v-if="applyDetailInfoJob.unit_code!=='' && applyDetailInfoJob.unit_code!=null">
									({{applyDetailInfoJob.unit_code}})</span>
							</td>
							<td>姓名: {{applyDetailInfo.real_name}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">岗位(代码)</td>
							<td>{{applyDetailInfoJob.job_title}}
								<span
									v-if="applyDetailInfoJob.position_code!=='' && applyDetailInfoJob.position_code!=null">
									({{applyDetailInfoJob.position_code}})</span>
							</td>
							<td colspan="2">身份证号：{{applyDetailInfo.id_cards}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">
								户籍要求
							</td>
							<td>{{applyDetailInfoJob.household_register}}</td>
							<td>{{applyDetailInfo.huji}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">年龄要求</td>
							<td>{{applyDetailInfoJob.age_limit_text}}</td>
							<td>
								<div>{{applyDetailInfo.age}}，</div>
								生日：{{applyDetailInfo.birthday}}
							</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">性别要求</td>
							<td>{{applyDetailInfoJob.sex_text}}</td>
							<td>{{applyDetailInfo.sex_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">民族要求</td>
							<td>{{applyDetailInfoJob.nation_limit_text}}</td>
							<td>{{applyDetailInfo.minzu_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">政治面貌要求</td>
							<td>{{applyDetailInfoJob.politics_text}}</td>
							<td>{{applyDetailInfo.politics_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">学历类别要求</td>
							<td>{{applyDetailInfoJob.degree_category_text}}</td>
							<td>{{applyDetailInfo.degree_category_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">学历要求</td>
							<td>{{applyDetailInfoJob.academic_degre_text}}</td>
							<td>{{applyDetailInfo.education_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">学位要求</td>
							<td>{{applyDetailInfoJob.degree_limit_text}}</td>
							<td>{{applyDetailInfo.academic_degree_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">应届要求</td>
							<td>{{applyDetailInfoJob.is_current_text}}</td>
							<td>{{applyDetailInfo.user_origin_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">专门岗位要求</td>
							<td>{{applyDetailInfoJob.special_job_text}}</td>
							<td>{{applyDetailInfo.user_origin_text}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">专业要求</td>
							<td>{{applyDetailInfoJob.major_limit}}</td>
							<td>{{applyDetailInfo.major}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">其他要求</td>
							<td>{{applyDetailInfoJob.other_limit}}</td>
							<td></td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">备 注</td>
							<td colspan="2">{{applyDetailInfoJob.remark}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">联系方式</td>
							<td colspan="2">{{applyDetailInfoJob.contact}}</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">考生信息</td>
							<td colspan="2">
								<div>籍贯：{{applyDetailInfo.jiguan}}</div>
								<div>毕业院校：{{applyDetailInfo.graduated_from}}</div>
								<div>毕业时间：{{applyDetailInfo.graduation_time_text}}</div>
								<div>婚姻状况：{{applyDetailInfo.marital_status_text}}</div>
								<div>专长：{{applyDetailInfo.expertise}}</div>
								<div>个人简历：{{applyDetailInfo.main_resume}}</div>
								<div>家庭状况：{{applyDetailInfo.family}}</div>
								<div>主要业绩：{{applyDetailInfo.rewards_punishments}}</div>
							</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">报考补充信息</td>
							<td colspan="2">
								<div>
									<textarea maxlength="100%"
										:readonly="applyDetailInfo.status===1||applyDetailInfo.status===2"
										v-model="supplementaryInformation"></textarea>
									<Button type="primary" style="background: #1f4db6;"
										:disabled="applyDetailInfo.status===1||applyDetailInfo.status===2"
										@click="changeSupplementaryInformation">修改补充信息
									</Button>
								</div>
								<div>限200字；确认报考前可自行修改补充信息；
									请填写获得的有关资质材料（如职称证书，通过司法考试，英语、计算机等级等），若是少数民族请填写具体民族，若是民主党派请说明具体党派
								</div>
							</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">选择考试地</td>
							<td colspan="2">
								<div>
									<Select v-model="examinationLocation" placeholder="选择考试地"
										style="width: 30%;padding-left: .08rem;margin-right: .1rem;border-radius: .04rem">
										<Option v-for="item in examinationLocationList" :value="item" :key="item">
											{{ item }}
										</Option>
									</Select>
									<Button type="primary" style="background: #1f4db6;"
										@click="changeAddress()">修改考试地</Button>
								</div>
								<div>{{applyDetailInfoExam.appeal_end}}前可自行修改考试地。</div>
							</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">当前状态</td>
							<td colspan="2">
								<div>{{statusList[applyDetailInfo.status]}}</div>
								<div v-show="applyDetailInfo.status==3">未通过理由：{{applyDetailInfo.remark}}</div>
							</td>
						</tr>
						<tr>
							<td class="view-details-tables-tip">申诉理由</td>
							<td colspan="2">
								<div>
									<textarea maxlength="100%" :readonly="applyDetailInfo.status===2"
										v-model="reasonsForAppeal"></textarea>
									<Button type="primary" style="background: #1f4db6;"
										:disabled="applyDetailInfo.status!=1 &&applyDetailInfo.status!=3"
										@click="commitAppeal">提交申诉
									</Button>
								</div>
								<div>可申诉时间段：{{applyDetailInfoExam.appeal_time}} - {{applyDetailInfoExam.appeal_end}}
									限200字；仅在初审不通过的情况下可进行申诉；
									因资料不全（或有误）而未通过审核的，可自行补充修改资料后提交申诉(申诉理由为已补充资料)。
									申诉次数有限，请务必认真填写，如不确定如何填写请先与用人单位电话沟通；
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<Button type="primary" style="background: #1f4db6;" size="large"
									@click="changeMainTabsItem(5)">
									返回已选岗位列表
								</Button>
							</td>
						</tr>
					</table>
				</div>

				<!--        确认报考弹窗-->
				<Modal v-model="isCheckApplyJobPopupOpen" :footer-hide="true" title="岗位确认">
					<div class="check-apply-popup-item">报名序号：{{postConfirmation.sign_up_no}}</div>
					<div class="check-apply-popup-item">身份证号：{{postConfirmation.id_cards}}</div>
					<div class="check-apply-popup-item">考生姓名：{{postConfirmation.real_name}}</div>
					<div class="check-apply-popup-item">报考单位：
						{{postConfirmation.job_unit_name}}
						<span v-if="postConfirmation.unit_code!==''||postConfirmation.unit_code!=null">
							({{postConfirmation.unit_code}})</span>
					</div>
					<div class="check-apply-popup-item">报考岗位：
						{{postConfirmation.job_name}}
						<span v-if="postConfirmation.position_code!==''||postConfirmation.position_code!=null">
							({{postConfirmation.position_code}})</span>
					</div>
					<div class="check-apply-popup-item">考 试 地：{{postConfirmation.address}}</div>
					<!--          <div class="check-apply-popup-item">考试科目：</div>-->
					<div class="check-apply-popup-item" style="margin-bottom: .12rem">缴费金额：{{postConfirmation.price}}元
					</div>
					<div class="check-apply-popup-item check-apply-popup-item-tip"
						style="background: #FCEFE7;padding: .04rem;margin-bottom: .4rem;border-radius: .04rem">
						<div>特别提醒：
							<i style="color: #F32547">报考确认后考生信息及所报岗位将不可修改</i>
							，请先认真检查。
						</div>
						<div>主要简历必须
							<i style="color: #F32547">严格按照要求填写</i>
							，否则
							<i style="color: #F32547">无法通过审核</i>
							。
						</div>
					</div>
					<div style="width: 100%;display: flex;justify-content: center">
						<Button :loading="confirmApplyBtnLoading" :disabled="postConfirmation.already_exist==1"
							@click="toConfirmApply">确认
						</Button>
					</div>
				</Modal>

				<!--        支付弹窗-->
				<Modal v-model="payPopupOpen" footer-hide scrollable title="考试缴费">
					<div class="pay-popup" style="display: flex;justify-content: space-around">
						<div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;"
							@click="toPay('alipay')">
							<img src="../../assets/images/index/Alipay.png" style="width: .6rem">
							<div>支付宝支付</div>
						</div>
						<div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;"
							@click="toPay('wechat')">
							<img src="../../assets/images/index/WeChatPay.png" style="width: .6rem;height: .6rem">
							<div>微信支付</div>
						</div>
					</div>
				</Modal>
			</div>

			<!--      调查反馈-->
			<!--      <div v-if="mainTabsNowIndex===6" class="feedback">-->
			<!--        <div class="feedback-item">-->
			<!--          <img src="../../assets/images/examinee_information/warning_icon.png">-->
			<!--          在招聘录用不同阶段将根据需要发布调查问卷，请关注！-->
			<!--        </div>-->
			<!--      </div>-->

			<!--      留言咨询-->
			<div v-if="mainTabsNowIndex===6" class="message-consultation">
				<div class="message-consultation-header">
					<div class="message-consultation-header-tip">留言类型</div>
					<div class="message-consultation-header-box">
						<Select v-model="messageTypeItem" placeholder="-未选择-"
							style="width: 3.2rem; border: none;padding-left: .08rem;">
							<Option v-for="(item,index) in messageTypeList" :value="item.name" :key="index">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>
				<div class="message-consultation-content">
					<div class="message-consultation-content-tip">留言内容</div>
					<textarea placeholder="请输入留言内容，字数不少于5个，不超过200个" v-model="messageContent" minlength="5"
						maxlength="200"></textarea>
				</div>
				<img src="../../assets/images/examinee_information/commit_btn.png" @click="commitMessage">
				<div v-if="msgList != null && msgList.length != 0" class="message-list">
					<div class="message-list-item" v-for="(item,index) in msgList" :key="index">
						<div class="message-list-item-top">
							<span>留言类型：{{item.type_name}}</span>
							<span>留言时间：{{item.create_time}}</span>
						</div>
						<div class="message-list-item-content">
							留言内容：
							<span v-html="item.content"></span>
						</div>
						<div class="message-list-item-reply">
							<div v-if="item.reply_content !== '' && item.reply_content != null">
								回复内容：<span v-html="item.reply_content"></span>
							</div>
							<span v-if="item.reply_content === '' || item.reply_content == null">暂无回复</span>
						</div>
					</div>
				</div>
			</div>

			<!--      操作记录-->
			<div v-if="mainTabsNowIndex===7" class="operation-record">
				<div class="operation-record-item" v-for="(item,index) in userLogList" :key="index">
					<div class="operation-record-item-top">
						{{item.title}}
					</div>
					<div class="operation-record-item-bottom">
						<span>{{new Date(parseInt(item.createtime) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ')}}</span>
						<span>IP:{{item.ip}}</span>
					</div>
				</div>
				<PageBtns :pageLength="totalPage" v-if="userLogList != null && userLogList.length != 0"
					style="margin: 0 auto" @changePageNumber="changeUserLogListCurrentPage"></PageBtns>
			</div>

			<!--      修改密码-->
			<div v-if="mainTabsNowIndex===8" class="change-password">
				<div class="change-password-main">
					<div class="change-password-main-body">
						<div class="change-password-main-body-item">
							<div class="change-password-main-body-item-tip">原密码</div>
							<div class="change-password-main-body-item-input">
								<input placeholder="输入原登录密码" type="password" v-model="oldPassword"></input>
							</div>
						</div>
						<div class="change-password-main-body-item">
							<div class="change-password-main-body-item-tip">新密码</div>
							<div class="change-password-main-body-item-input">
								<input placeholder="输入新登录密码" type="password" v-model="password"></input>
							</div>
						</div>
						<div class="change-password-main-body-item">
							<div class="change-password-main-body-item-tip">确认密码</div>
							<div class="change-password-main-body-item-input">
								<input placeholder="再次输入新登录密码" type="password" v-model="confirmPassword"></input>
							</div>
						</div>
					</div>
					<Button type="primary" style="background: #1f4db6;" @click="changePassword">修改密码
					</Button>
				</div>

			</div>
			<div v-if="mainTabsNowIndex===9"></div>
		</div>

		<!--    退出登录弹窗-->
		<Modal v-model="loginOut" @on-ok="toLoginOut" title="退出登录" @on-cancel="cancel">
			<div style="text-align: center">是否退出登录</div>
		</Modal>

	</div>
</template>

<script>
	import {
		Icon,
		Button,
		Cascader,
		DatePicker,
		Drawer,
		Message,
		Modal,
		Option,
		Select,
		Upload
	} from 'view-design';
	import SelectPage from "../../components/SelectPage";
	import ThreeSelectPage from "../../components/ThreeSelectPage";
	import PageBtns from "../../components/PageBtns";
	import menuListOpen from "../../components/menuListOpen";
	import vueQr from 'vue-qr';
	import AppPdf from '../../components/vue-pdf.vue'

	import {
		setInterval,
		clearInterval
	} from "timers";

	import {
		apiUpLoadFile,
		apiAddExamUser,
		apiAppealJob,
		apiChangeAddress,
		apiCheckApplyJob,
		apiDeleteUserJob,
		apiExamContent,
		apiGetApplyDetail,
		apiGetArea,
		apiGetUserPage,
		apiGetBottom,
		apiGetConfig,
		apiGetImg,
		apiGetMsgList,
		apiGetMsgType,
		apiGetUserInfo,
		apiGetUserJobList,
		apiGetUserLog,
		apiGetUserTotal,
		apiGetWxResult,
		apiGetWxUser,
		apiLogout,
		apiRemoveWxBind,
		apiResetPwdExam,
		apiSaveUserMsg,
		apiSubmitApply,
	} from "../../request/api";
	import axios from 'axios'; // 引入axios
	export default {

		components: {
			AppPdf,
			Select,
			Option,
			DatePicker,
			Upload,
			Modal,
			Message,
			Drawer,
			Cascader,
			Button,
			SelectPage,
			ThreeSelectPage,
			PageBtns,
			vueQr,
			Icon,
			menuListOpen,
		},
		inject: ['reload'],

		data() {
			return {
				file: '',
				fileData: '',
				fileName: '',
				messageLabel: '',

				// 总体信息小提示
				totalDetailNotice: '',
				//微信绑定小提示
				wechabind: '',
				//考生信息填写小提示
				userInfoText: '',
				//照片上传小提示
				pictureText: '',
				//证件上传小提示
				certificateText: '',
				//岗位报考小提示
				applyJobText: '',
				// 留言咨询小提示
				msgText: '',

				applyJobId: "",

				//总页数
				totalPage: 0,
				//是否开启支付弹窗
				payPopupOpen: false,

				qrCodeUrl: "",

				// 最终确认报考按钮loading
				confirmApplyBtnLoading: false,

				//微信头像
				wxAvatar: "",
				//微信昵称
				wxNickName: "",
				//是否已经绑定微信
				isBindWx: 0,

				randomNumber: "",

				//当前点击的报考岗位的id
				clickJobId: "",
				//确认报考弹窗的按钮
				confirmApply: false,
				//岗位确认信息
				postConfirmation: {},
				//确认报考的弹窗显示
				isCheckApplyJobPopupOpen: false,
				//确认报考按钮是否禁用
				checkApplyJobBtn: false,
				//删除按钮是否禁用
				deleteBtn: false,
				//考生报考状态
				statusList: [
					"考生未确认报考",
					"确认报考 等待审核",
					"报考成功",
					"审核未通过",
					"审核通过，等待缴费"
				],
				//个人照片
				picture_url: "",
				//身份证正面
				id_card_front: "",
				//身份证背面
				id_card_back: "",
				//毕业证书（学历证书）
				diploma_url: "",
				//学位证书
				degree_url: "",
				//学信网有效期内学历证明截图
				xuexin_url: "",
				//职称（职业资格）证书
				zhicheng_url: "",
				//其他1
				other_one_url: "",
				//其他2
				other_two_url: "",

				fileCont: '',
				fileId: '',
				tabs: this.GLOBAL.examHeaderTabs,
				//当前选中的tabs的下标
				nowIndex: 1,
				//考生信息
				userInfo: {},
				mainTabs: ["总体情况", "微信绑定", "考生信息", "照片上传", "证件上传", "岗位报考/申诉",
					"留言咨询", "操作记录", "修改密码", "退出登录"
				],
				mainTabsNowIndex: 0,

				//登录次数
				loggedNumber: '',

				//顶部通知
				examineeInformationAnnouncement: '',
				//报名序号
				signUpNo: '',
				email: '',
				userName: '',
				sexList: [],
				sexItem: '',
				pdf_url: '',

				//*籍      贯
				nativePlace: '未选择',
				//*民族列表
				nationList: [],
				//*民族项
				nationItem: '',
				birthday: '',

				//显示的生日
				showBirthday: '',
				idCards: '',

				//*政治面貌列表
				politicCountenance: [],
				//政治面貌项
				politicCountenanceItem: '',

				//*考生来源列表
				sourceOfCandidates: [],
				//*考生来源项
				sourceOfCandidatesItem: '',
				phoneNumber: '',

				//*家庭电话
				homePhone: '',
				//  *紧急联系人
				emergencyContact: '',
				//*通信地址
				correspondenceAddress: '未选择',
				//*通信地址一级的选中值
				firstAddress: '未选择',
				//*通信地址一级列表
				firstAddressList: '',
				//*通信地址二级的选中值
				secondAddress: '未选择',
				//*通信地址二级列表
				secondAddressList: '',
				//*通信地址三级选中值
				thirdAddress: '未选择',
				//*通信地址三级列表
				thirdAddressList: '',
				//地区三级联动是否显示
				isAddressSelectMainOpen: false,
				//地区第二级是否显示
				secondAddressListOpen: false,
				//地区第三级是否显示
				thirdAddressListOpen: false,
				//详细街道
				detailedStreet: "",
				//邮编
				postCode: '',
				//学历列表
				educationalBackground: [],
				//学历
				educationalBackgroundItem: '',
				//学位列表
				degreeList: [],
				//学位
				degreeListItem: '',
				//*学历类别列表
				educationalBackgroundCategory: [],
				//*学历类别
				educationalBackgroundCategoryItem: '',
				//*婚姻状况列表
				maritalStatus: [],
				//*婚姻状况
				maritalStatusItem: '',
				//*毕业院校
				graduateSchool: '',
				//*所学专业
				major: '',
				//毕业时间
				graduationTime: '',
				//工作时间
				workTime: '',
				//专长
				expertise: '',
				//主要简历
				mainResume: '',
				//家庭成员
				familyMembers: '',
				//主要业绩
				mainAchievements: '',
				//留言列表
				msgList: [],
				//留言类型列表
				messageTypeList: [],
				//留言内容
				messageContent: "",
				//留言类型
				messageTypeItem: '',
				//留言类型id
				messageTypeItemId: '',
				oldPassword: '',

				//新密码
				password: '',
				confirmPassword: '',
				loginOut: false,
				token: "",
				//手机端的tab弹窗控制器
				istabPopupOpen: false,
				//获取后的图片信息
				imgUrl: {},
				//籍贯三级联动是否显示
				isAreaSelectMainOpen: false,
				//籍贯第二级是否显示
				secondAreaListOpen: false,
				//籍贯第三级是否显示
				thirdAreaListOpen: false,
				//籍贯
				firstAreaList: [],
				//籍贯
				secondAreaList: [],
				//籍贯
				thirdAreaList: [],
				// 籍贯
				firstArea: "未选择",
				// 籍贯
				secondArea: "未选择",
				// 籍贯
				thirdArea: "未选择",
				//查看详情（申诉）是否显示
				isDetailsOpen: false,
				//考试地
				examinationLocation: "",
				//考试地列表
				examinationLocationList: [],
				//申诉理由
				reasonsForAppeal: "",
				//报考补充信息
				supplementaryInformation: "",
				examId: "",
				userId: "",
				//报考岗位列表
				registrationPositionList: [],
				//查看详细（申诉）信息
				applyDetailInfo: {},
				//查看详细（申诉）-岗位信息
				applyDetailInfoJob: {},
				//查看详细（申诉）-考试信息
				applyDetailInfoExam: {},
				//考生总体信息
				userTotalInfo: {},
				//是否填写考生信息
				examInfoStatus: '',
				//是否上传照片
				pictureStatus: '',
				//控制是否增加classname  full-height,解决内容不足，高度不撑满
				fullHeightAdd: false,
				//户籍所在地
				firstSelectedItem: "",
				//户籍所在地
				secondSelectedItem: "",
				//户籍所在地
				thirdSelectedItem: "",
				//户籍所在地id字符串
				selectedIdStr: "",
				//当前页号
				currentPage: 1,
				userLogListCurrentPage: 1,
				//每页大小
				pageSize: 5,
				userLogListPageSize: 10,
				//操作记录
				userLogList: [],
				examineeInformation: {},

				//身高
				height: '',
				getWxRwsult: null,
			}
		},
		created() {
			// window.clearInterval(getWxRwsult)
			let _exam_id = localStorage.getItem('exam_id');
			let _shop_id = localStorage.getItem("shop_id");
			var shopId_examId = _shop_id + "_" + _exam_id;

			let _user_id = localStorage.getItem('user_id' + "_" + _shop_id + "_" + _exam_id);

			let _examineeInformation = '';
			apiExamContent({
				id: localStorage.getItem('exam_id')
			}).then((res) => {
				_examineeInformation = res.data;
				this.examineeInformation = _examineeInformation;

				this.examineeInformationAnnouncement = _examineeInformation.exam_name;

			})
			// randomNumber
			apiGetUserPage().then((res) => {
				this.totalDetailNotice = res.data.totalDetail;
				this.wechabind = res.data.wechabind;
				this.userInfoText = res.data.userInfoText;
				this.pictureText = res.data.pictureText;
				this.certificateText = res.data.certificateText;
				this.applyJobText = res.data.applyJobText;
				this.msgText = res.data.msgText;
			})

			this.getQrCode();

			this.examId = _exam_id;
			this.userId = _user_id;
			this.token = localStorage.getItem('token' + "_" + shopId_examId);

			//调用考生信息配置项接口
			apiGetConfig()
				.then((res) => {
					this.sexList = res.data.sexList;
					this.sourceOfCandidates = res.data.userOriginList;
					this.educationalBackground = res.data.educationList;
					this.degreeList = res.data.academicDegreeList;
					this.maritalStatus = res.data.maritalStatusList;
					this.politicCountenance = res.data.politicsList;
					this.educationalBackgroundCategory = res.data.degreeCategoryList;
					this.nationList = res.data.minzuList;
				});

			this.getUserTotalInfo();

			this.getImg();
			if (localStorage.getItem("isPostInquiryEnter")) {
				this.changeMainTabsItem(5)
				this.examId = localStorage.getItem("exam_id");
				this.userId = localStorage.getItem('user_id' + "_" + shopId_examId);
				this.loggedNumber = localStorage.getItem('logged_number' + "_" + localStorage.getItem('user_id' + "_" +
					shopId_examId));
			}

			if (localStorage.getItem('token' + "_" + shopId_examId)) {
				this.getUserTotalInfo();

				this.getImg();
				this.examId = localStorage.getItem("exam_id");
				this.userId = localStorage.getItem('user_id' + "_" + shopId_examId);
				this.loggedNumber = localStorage.getItem('logged_number' + '_' +
					localStorage.getItem('user_id' + "_" + shopId_examId)
				);

			} else {
				this.$router.push({
					name: 'login',
				})
				this.$router.push(
					`/login/login?shop_id=${localStorage.getItem("shop_id")}&&exam_id=${localStorage.getItem("exam_id")}`
				)

			}


		},
		mounted() {

			document.addEventListener('click', (e) => {

				if (e.target.id !== 'closeSelect41' && e.target.id !== 'closeSelect42' && e.target.id !==
					'closeSelect43' &&
					e.target.id !== 'closeSelect44') {
					this.isAreaSelectMainOpen = false;

				}

			});
			document.addEventListener('click', (e) => {

				if (e.target.id !== 'closeSelect31' && e.target.id !== 'closeSelect32' && e.target.id !==
					'closeSelect33' &&
					e.target.id !== 'closeSelect34' && e.target.id !== 'closeSelect35' && e.target.id !==
					'closeSelect36') {
					this.isAddressSelectMainOpen = false;

				}

			});
		},
		computed: {
			// changeCurrentTables() {
			//   let {currentPage, pageSize} = this;
			//   return this.msgList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
			// },
			// changeUserLogList() {
			//   let {userLogListCurrentPage, userLogListPageSize} = this;
			//   return this.userLogList.slice((userLogListCurrentPage - 1) * userLogListPageSize, userLogListCurrentPage * userLogListPageSize);
			// },
		},
		methods: {
			toWxbind() {
				this.isBindWx = 1;
			},
			getQrCode() {
				var _exam_id = localStorage.getItem('exam_id');
				var _shop_id = localStorage.getItem("shop_id");
				var shopId_examId = _shop_id + "_" + _exam_id;

				var _user_id = localStorage.getItem('user_id' + "_" + _shop_id + "_" + _exam_id);

				var _examineeInformation = '';
				apiExamContent({
					id: localStorage.getItem('exam_id')
				}).then((res) => {
					_examineeInformation = res.data;
					let random = "";
					for (var i = 0; i < 6; i++) //6位随机数，用以替换时间戳前6位。
					{
						random += Math.floor(Math.random() * 10);
					}
					var _randomNumber = random + new Date().getTime().toString().slice(6)
					this.randomNumber = _randomNumber;
					// console.log("_randomNumber==", _randomNumber)
					localStorage.setItem("random", _randomNumber);

					this.qrCodeUrl = 'http://bm.ptgzrc.com/wxloginbind?exam_id=' + _exam_id + '&random=' +
						_randomNumber +
						'&user_id=' + _user_id + '&exam_name=' + res.data.exam_name

				})

			},

			//头部tab切换
			changeTabsIndex(index) {

				this.nowIndex = index;
				switch (index) {
					case 0:
						this.$router.push({
							name: 'index'
						})
						break;
					case 1:
						this.$router.push(
							`/currentHome/currentHome?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 2:
						this.$router.push(
							`/recruitmentNewsList/recruitmentNewsList?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 3:
						this.$router.push(
							`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 4:
						this.$router.push(
							`/policyList/policyList?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 5:
						this.$router.push(
							`/postInquiry/postInquiry?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 6:
						this.$router.push(
							`/scoreInquiry/scoreInquiry?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
					case 7:
						this.$router.push(
							`/login/login?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
						)

						break;
				}
			},
			//调用考生总体信息接口
			getUserTotalInfo() {
				apiGetUserTotal({
					user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
						localStorage.getItem("exam_id")),
					exam_id: localStorage.getItem("exam_id"),
				}).then((res) => {
					this.userTotalInfo = res.data;
					this.signUpNo = res.data.sign_up_no;
					this.email = res.data.email;
					this.userName = res.data.username;
					this.idCards = res.data.id_cards;
					this.phoneNumber = res.data.mobile;
					this.wxNickName = res.data.wx_nickname;
					this.examInfoStatus = res.data.exam_user_info_status;
					if (res.data.wx_nickname != null && res.data.wx_nickname != '' && res.data.openid != null &&
						res.data
						.openid != '') {
						this.isBindWx = 2;
					}
				});
			},
			//调用获取图片接口
			getImg() {
				apiGetImg({
					exam_id: localStorage.getItem("exam_id")
				}).then((res) => {
					if (res.code !== 0) {
						this.imgUrl = res.data;
						if (res.data.picture_url) {
							this.picture_url = res.data.picture_url;
						}
					}
				});
			},
			//定时器:定时请求接口，做轮询
			openTimer() {
				this.getWxRwsult = window.setInterval(() => {
					apiGetWxResult({
						type: "wxloginbind",
						client_id: this.randomNumber
					}).then((apiGetWxResultRes) => {
						clearInterval(this.getWxRwsult);

						if (apiGetWxResultRes.code == 1) {


							if (apiGetWxResultRes.status == 'true') {
								this.isBindWx = 2;
								apiGetWxUser().then((apiGetWxUserRes) => {
									// //微信头像
									// wxAvatar: "",
									//   //微信昵称console.log("index != 1==", index != 1)
									//   wxNickName: "",
									if (apiGetWxUserRes.code == 1) {
										this.wxNickName = apiGetWxUserRes.wx_nickname;
										this.wxAvatar = apiGetWxUserRes.headimgurl;

										this.changeMainTabsItem(1);
									}
								})

							}
						}
					}).catch(() => {

					})
				}, 1500)

				// this.getWxRwsult = setInterval(()=>{
				//  // console.log("this.randomNumber==", this.randomNumber)
				//  apiGetWxResult({
				//    type: "wxloginbind",
				//    client_id: this.randomNumber
				//  }).then((apiGetWxResultRes) => {
				//    clearInterval(this.getWxRwsult);

				//    if (apiGetWxResultRes.code == 1) {


				//      if (apiGetWxResultRes.status == 'true') {
				//        this.isBindWx = 2;
				//        apiGetWxUser().then((apiGetWxUserRes) => {
				//          // //微信头像
				//          // wxAvatar: "",
				//          //   //微信昵称console.log("index != 1==", index != 1)
				//          //   wxNickName: "",
				//          if (apiGetWxUserRes.code == 1) {
				//            this.wxNickName = apiGetWxUserRes.wx_nickname;
				//            this.wxAvatar = apiGetWxUserRes.headimgurl;

				//            this.changeMainTabsItem(1);
				//          }
				//        })

				//      }
				//    }
				//  }).catch(()=>{

				//   })
				// },1000)
			},
			//主体内容的tab切换
			changeMainTabsItem(index) {
				this.mainTabsNowIndex = index;
				// console.log("index", index)
				// console.log("index != 1==", index != 1)
				if (index != 1) {
					if (this.isBindWx != 0 && this.isBindWx != 2) {
						this.isBindWx = 0
					}
					if (this.getWxRwsult) {
						window.clearInterval(this.getWxRwsult);
						this.getWxRwsult = null
					}
				}
				if (index != 5) {
					localStorage.removeItem("isPostInquiryEnter");
				}
				switch (index) {
					case 0:
						this.getUserTotalInfo();
						this.getImg();
						break;
					case 1:
						// this.initWebSocket();
						let that = this;
						apiGetWxUser().then((res) => {
							// //微信头像
							// wxAvatar: "",
							//   //微信昵称
							//   wxNickName: "",
							if (res.code == 1) {

								if (res.data.openid && res.data.wx_nickname && res.data.headimgurl) {
									this.wxNickName = res.data.wx_nickname;
									this.wxAvatar = res.data.headimgurl;
									this.isBindWx = 2;
									// this.changeMainTabsItem(1);
								} else {

									that.openTimer()
								}
							}

						})
						break;
					case 2:
						this.backToTop();
						apiGetArea({
							id: 0
						}).then((res) => {
							this.firstAreaList = res.data;
							this.firstAddressList = res.data;
						});
						//调用考生信息配置项接口
						apiGetConfig()
							.then((res) => {
								this.sexList = res.data.sexList;
								this.sourceOfCandidates = res.data.userOriginList;
								this.educationalBackground = res.data.educationList;
								this.degreeList = res.data.academicDegreeList;
								this.maritalStatus = res.data.maritalStatusList;
								this.politicCountenance = res.data.politicsList;
								this.educationalBackgroundCategory = res.data.degreeCategoryList;
								this.nationList = res.data.minzuList;
							});
						apiGetUserInfo({
							user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
								localStorage.getItem("exam_id")),
						}).then((res) => {
							this.selectedIdStr = res.data.huji;
							localStorage.setItem("selectedIdStr", res.data.huji);
							this.$refs.threeSelectPage.getInitInfo();
							var _id_cards = res.data.id_cards;
							if (_id_cards != null || _id_cards !== "") {
								this.birthday = _id_cards.slice(6, 14);
								this.showBirthday = _id_cards.slice(6, 10) + "-" + _id_cards.slice(10, 12) + "-" +
									_id_cards.slice(
										12, 14)

								if (_id_cards.slice(16, 17) % 2 === 0) {
									this.sexItem = "女"
								} else {
									this.sexItem = "男"
								}
							}

							this.userInfo = res.data;
							if (res.data.jiguan) {
								this.nativePlace = res.data.jiguan;
							}
							if (res.data.minzu_text) {
								this.nationItem = res.data.minzu_text;
							}

							this.idCards = _id_cards;
							if (res.data.politics_text) {
								this.politicCountenanceItem = res.data.politics_text;
							}
							if (res.data.user_origin_text) {
								this.sourceOfCandidatesItem = res.data.user_origin_text;
							}
							this.homePhone = res.data.family_phone;
							this.emergencyContact = res.data.emergency_contact;
							if (res.data.postal_address) {
								this.correspondenceAddress = res.data.postal_address;
							}

							this.detailedStreet = res.data.postal_address_street;
							this.postCode = res.data.post_code;
							this.educationalBackgroundItem = res.data.education_text;
							this.degreeListItem = res.data.academic_degree_text;
							this.educationalBackgroundCategoryItem = res.data.degree_category_text;
							this.maritalStatusItem = res.data.marital_status_text;
							this.graduateSchool = res.data.graduated_from;
							this.major = res.data.major;
							if (res.data.graduation_time_text) {
								this.graduationTime = res.data.graduation_time_text.slice(0, 7);
							}
							if (res.data.work_time_text) {
								this.workTime = res.data.work_time_text.slice(0, 7);
							}

							this.expertise = res.data.expertise;
							this.mainResume = res.data.main_resume;
							this.familyMembers = res.data.family;
							this.mainAchievements = res.data.rewards_punishments;
						});
						break;
					case 3:

						apiGetImg({
							exam_id: localStorage.getItem("exam_id")
						}).then((res) => {
							if (res.code !== 0) {
								this.examInfoStatus = res.code;
								this.imgUrl = res.data;
								this.picture_url = res.data.picture_url;
							}
						});
						break;
					case 4:
						apiGetImg({
							exam_id: localStorage.getItem("exam_id")
						}).then((res) => {
							if (res.code !== 0) {
								this.examInfoStatus = res.code;
								this.imgUrl = res.data;
								this.picture_url = res.data.picture_url;
								this.id_card_front = res.data.id_card_front;
								this.id_card_back = res.data.id_card_back;
								this.diploma_url = res.data.diploma_url;
								this.degree_url = res.data.degree_url;
								this.xuexin_url = res.data.xuexin_url;
								this.zhicheng_url = res.data.zhicheng_url;
								this.other_one_url = res.data.other_one_url;
								this.other_two_url = res.data.other_two_url;
								this.pdf_url = res.data.pdf_url
							}

						});
						break;
					case 5:
						this.isDetailsOpen = false;
						this.getUserJobList();
						break;
					case 6:
						apiGetMsgList({
							user_id: localStorage.getItem("user_id" + "_" + localStorage.getItem("shop_id") +
								"_" + localStorage.getItem("exam_id")),
							exam_id: localStorage.getItem("exam_id"),
						}).then((res) => {
							this.msgList = res.data;
						});
						apiGetMsgType({
							shop_id: localStorage.getItem("shop_id"),
							exam_id: localStorage.getItem("exam_id"),
						}).then((res) => {
							this.messageTypeList = res.data
						});
						break;
					case 7:
						apiGetUserLog({
							user_id: localStorage.getItem("user_id" + "_" + localStorage.getItem("shop_id") +
								"_" + localStorage.getItem("exam_id")),
							page: this.userLogListCurrentPage
						}).then((res) => {
							this.userLogList = res.data.list;
							this.totalPage = res.data.page;
						});
						break;
					case 9:
						if (document.getElementsByClassName("examinee-information-main")[0].clientHeight < 400) {
							// this.fullHeightAdd = true;
						}
						this.showLoginOutPopup();
						break;
				}

			},
			//解除微信绑定
			removeWxBind() {
				apiRemoveWxBind().then((res) => {
					if (res.code == 1) {
						this.isBindWx = '';
						// this.reload();
						this.getQrCode();
						Message.info(res.msg);
						this.changeMainTabsItem(1);

					}
					if (res.code == 0) {
						Message.info(res.msg)
					}
				})
			},
			//上传PDF文件之前
			beforeUpload(file) {
				console.log("文件", file);
				this.file = file;
				this.fileName = file.name;
				// this.fileSize = file.size;
				const extension = file.name.split('.').slice(-1) == 'pdf'
				if (!extension) {
					this.$message.warning('上传模板只能是pdf格式!')
					return
				}
				let reader = new FileReader();
				reader.readAsDataURL(file);
				let that = this;
				reader.onload = function() {
					
					that.fileData = reader.result;
				};
				return false; // 返回false不会自动上传
			},
			successUpload(file, fileList) {
				// console.log('response==',response)
				console.log('file==', file)
				console.log('fileList==', fileList)
			},
			//预览文件
			viewfile() {
				// console.log("viewFile",this.fileData);
				// console.log('this.file==', this.file.size)
				// console.log('this.pdf_url==',this.pdf_url)
				let pdf_url = '';
				if (this.pdf_url) {
					pdf_url = 'http://' + this.pdf_url
				} else {
					pdf_url = this.fileData;
				}
				// window.open(pdf_url,'_blank')
				// window.location.href = pdf_url; 
				// win.document.write(
				// 	'<body style="margin:0px;"><embed type="application/pdf" src="' +
				// 	pdf_url +
				// 	'" width="100%" height="100%" /></body>'
				// );
				let win = window.open();
				win.document.write(
					'<body style="margin:0px;"><embed type="application/pdf" src="' +
					pdf_url +
					'" width="100%" height="100%" /></body>'
				);
				// if (this.file.size < 1400000) {
				// 	let win = window.open();
				// 	// win.document.write(
				// 	// 	'<body style="margin:0px;"><embed type="application/pdf" src="' +
				// 	// 	pdf_url +
				// 	// 	'" width="100%" height="100%" /></body>'
				// 	// );
					
				// 	win.document.write(
				// 		'<body style="margin:0px;"><object data="' +
				// 		pdf_url +
				// 		'" type="application/pdf" width="100%" height="100%">&lt;iframex src="' +
				// 		pdf_url +
				// 		'" scrolling="no" width="100%" height="100%" frameborder="0" >&lt;/iframex&gt;</object></body>'
				// 	);
				// } else {
				// 	this.$message.warning('请上传后在预览!')
				// 	return false;
				// }



				// win.document.write(
				// 	'<body style="margin:0px;"><iframe src="' +
				// 	pdf_url +
				// 	'" width="100%" height="100%" /></body>'
				// );


				// win.document.write(
				// 	'<body style="margin:0px;"><object data="' +
				// 	pdf_url +
				// 	'" type="application/pdf" width="100%" height="100%">&lt;iframex src="' +
				// 	pdf_url +
				// 	'" scrolling="no" width="100%" height="100%" frameborder="0" >&lt;/iframex&gt;</object></body>'
				// );
			},
			//上传文件按钮
			upLoadpdf() {
				if (this.fileName === '') {
					this.$message.warning('请选择要上传的文件！');
					return false
				}
				// if(this.pdfForm.announcementType === ''){
				//   this.$message.warning('请选择公告类型');
				//   return false
				// }
				// if(this.pdfForm.fileLevel === ''){
				//   this.$message.warning('请选择公告级别');
				//   return false
				// }
				//后端需要三个参数file、announcementType、fileLevel，用fileFormData包起来防止
				// 出现 headers:  'multipart/form-data'等错误
				// 若后端只需一个参数  添加
				//  let requestConfig = {
				//headers: {
				// 'Content-Type': 'multipart/form-data'
				//},
				//}
				//this.axios.post('/market/upload',formData,requestConfig).then((res)=>

				let fileFormData = new FormData();
				fileFormData.append("img_type", 'pdf_url');
				fileFormData.append("exam_id", this.examId);
				fileFormData.append("token", this.token);
				fileFormData.append("file", this.file);
				console.log('this.file==', this.file)
				// fileFormData.append('announcementType',this.pdfForm.announcementType)
				// fileFormData.append('fileLevel',this.pdfForm.fileLevel)

				// apiUpLoadFile(fileFormData,
				// {headers: {
				//     'Content-Type': 'multipart/form-data'
				//   }})
				let that = this;
				axios.post('/api/user/uploadfile', fileFormData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					console.log('res.msg', res.data.msg)
					Message.info(res.data.msg);
					apiGetImg({
						exam_id: localStorage.getItem("exam_id")
					}).then((res2) => {
						if (res.code !== 0) {
							that.file=''
							that.pdf_url = res2.data.pdf_url;
						}
					});
					// if (res.data.status===1){
					//   // this.showDataImportDialog=false
					// }else{
					//   this.messageLabel = this.$message({
					//     message:res.data.msg,
					//     type:'error',
					//     showClose:true,
					//     duration:0,
					//   });
					// }
				})
			},
			//考生信息录入
			informationCommit() {
				for (let key in this.sourceOfCandidates) {
					if (this.sourceOfCandidates[key] === this.sourceOfCandidatesItem) {
						var userOriginIndex = key
					}
				}
				for (let key in this.politicCountenance) {
					if (this.politicCountenance[key] === this.politicCountenanceItem) {
						var politicsIndex = key.toString()
					}
				}
				for (let key in this.educationalBackground) {
					if (this.educationalBackground[key] === this.educationalBackgroundItem) {
						var educationIndex = key
					}
				}
				for (let key in this.degreeList) {
					if (this.degreeList[key] === this.degreeListItem) {
						var academicDegreeIndex = key
					}
				}
				for (let key in this.educationalBackgroundCategory) {
					if (this.educationalBackgroundCategory[key] === this.educationalBackgroundCategoryItem) {
						var degreeCategoryIndex = key.toString()
					}
				}
				for (let key in this.maritalStatus) {
					if (this.maritalStatus[key] === this.maritalStatusItem) {
						var maritalStatusIndex = key
					}
				}
				for (let key in this.nationList) {
					if (this.nationList[key] === this.nationItem) {
						var nationItemIndex = key
					}
				}
				let _householdRegisterId = this.firstSelectedItem.id + "," + this.secondSelectedItem.id + "," + this
					.thirdSelectedItem.id;
				if (this.userName === '' || this.userName == null) {
					Message.info("姓名未填写")
					return false;
				}
				if (this.sexItem === '' || this.sexItem == null) {
					Message.info("性别未选择");
					return false;
				}
				if (this.nativePlace === '' || this.nativePlace == null || this.nativePlace === '未选择') {
					Message.info("籍贯未选择");
					return false;
				}
				if (this.nativePlace.includes('未选择')) {
					Message.info("籍贯未选择完整");
					return false;
				}

				if (this.nationItem === '' || this.nationItem == null) {
					Message.info("民族未选择");
					return false;
				}

				if (this.firstSelectedItem.id === '' || this.firstSelectedItem.id == null) {
					Message.info("户籍所在地省级未选择");
					return false;
				}
				if (this.secondSelectedItem.id === '' || this.secondSelectedItem.id == null) {
					Message.info("户籍所在地市级未选择");
					return false;
				}
				if (this.thirdSelectedItem.id === '' || this.thirdSelectedItem.id == null) {
					Message.info("户籍所在地县级未选择");
					return false;
				}
				if (this.politicCountenanceItem === '' || this.politicCountenanceItem == null) {
					Message.info("政治面貌未选择");
					return false;
				}

				if (this.sourceOfCandidatesItem === '' || this.sourceOfCandidatesItem == null) {
					Message.info("考生来源未选择");
					return false;
				}
				if (this.phoneNumber === '' || this.phoneNumber == null) {
					Message.info("手机号码未填写");
					return false;
				}

				if (this.emergencyContact === '' || this.emergencyContact == null) {
					Message.info("紧急联系人未填写");
					return false;
				}
				if (this.correspondenceAddress === '' || this.correspondenceAddress == null || this
					.correspondenceAddress ===
					"未选择") {
					Message.info("通信地址省市县未选择");
					return false;
				}
				// else {
				//   if (this.thirdAddress === '' || this.thirdAddress == null || this.thirdAddress === "未选择") {
				//     Message.info("通信地址省市县未选择完整");
				//     return false;
				//   }
				// }

				if (this.detailedStreet === '' || this.detailedStreet == null) {
					Message.info("详细街道门牌号未填写");
					return false;
				}

				if (this.educationalBackgroundItem === '' || this.educationalBackgroundItem == null) {
					Message.info("学历未选择");
					return false;
				}
				if (this.degreeListItem === '' || this.degreeListItem == null) {
					Message.info("学位未选择");
					return false;
				}
				if (this.educationalBackgroundCategoryItem === '' || this.educationalBackgroundCategoryItem == null) {
					Message.info("学历类别未选择");
					return false;
				}
				if (this.maritalStatusItem === '' || this.maritalStatusItem == null) {
					Message.info("婚姻状况未选择");
					return false;
				}
				if (this.graduateSchool === '' || this.graduateSchool == null) {
					Message.info("毕业院校未填写");
					return false;
				}
				if (this.major === '' || this.major == null) {
					Message.info("所学专业未填写");
					return false;
				}
				if (this.graduationTime === '' || this.graduationTime == null) {
					Message.info("毕业时间未选择");
					return false;
				}
				if (this.mainResume === '' || this.mainResume == null) {
					Message.info("主要简历未填写");
					return false;
				}
				// familyMembers
				if (this.familyMembers === '' || this.familyMembers == null) {
					Message.info("家庭成员未填写");
					return false;
				}

				if (this.sexItem === '男') {
					var sexIndex = 1
				} else if (this.sexItem === '女') {
					var sexIndex = 2

				}
				if (this.userInfo.status !== 1 && this.userInfo.status !== 2) {
					//考生信息录入提交
					apiAddExamUser({
						real_name: this.userName,
						sex: sexIndex,
						jiguan: this.nativePlace,
						email: this.email,
						birthday: this.birthday,
						id_cards: this.idCards,
						huji: _householdRegisterId,
						user_origin: userOriginIndex,
						mobile: this.phoneNumber,
						emergency_contact: this.emergencyContact,
						family_phone: this.homePhone,
						education: educationIndex,
						academic_degree: academicDegreeIndex,
						marital_status: maritalStatusIndex,
						graduated_from: this.graduateSchool,
						major: this.major,
						graduation_time: this.graduationTime,
						work_time: this.workTime,
						expertise: this.expertise,
						main_resume: this.mainResume,
						family: this.familyMembers,
						postal_address: this.correspondenceAddress,
						postal_address_street: this.detailedStreet,
						post_code: this.postCode,
						politics: politicsIndex,
						exam_id: localStorage.getItem("exam_id"),
						degree_category: degreeCategoryIndex,
						rewards_punishments: this.mainAchievements,
						minzu: nationItemIndex,
						height: this.height,
						user_id: this.userId,
					}).then((res) => {
						if (res.code === 0) {
							Message.info(res.msg);
						} else {
							Message.info("提交成功");
							this.backToTop();
							this.changeMainTabsItem(3);
						}

					})
				}

			},
			//上传图片成功
			uploadSuccess(res) {
				if (res.code === 0) {
					Message.info(res.msg);
				} else {
					Message.info("上传成功");
				}

				apiGetImg({
					exam_id: localStorage.getItem("exam_id")
				}).then((res) => {
					this.imgUrl = res.data;
					this.picture_url = res.data.picture_url;
					this.id_card_front = res.data.id_card_front;
					this.id_card_back = res.data.id_card_back;
					this.diploma_url = res.data.diploma_url;
					this.degree_url = res.data.degree_url;
					this.xuexin_url = res.data.xuexin_url;
					this.zhicheng_url = res.data.zhicheng_url;
					this.other_one_url = res.data.other_one_url;
					this.other_two_url = res.data.other_two_url;
				});
			},
			//上传失败
			uploadError() {
				Message.info("上传失败，请重新上传");
			},
			//打开籍贯的三级联动
			areaSelectMainOpen() {
				if (this.userInfo.status !== 1 && this.userInfo.status !== 2) {
					this.isAreaSelectMainOpen = !this.isAreaSelectMainOpen;
				}
			},
			//选择籍贯第一级
			firstAreaListItemClick(firstAreaItem) {
				this.secondAreaListOpen = true;
				this.isAreaSelectMainOpen = true;
				this.firstArea = firstAreaItem.name;
				this.nativePlace = this.firstArea + this.secondArea + this.thirdArea;
				apiGetArea({
					id: firstAreaItem.id
				}).then((res) => {
					this.secondAreaList = res.data;
				});
			},
			//选择籍贯第二级
			secondAreaListItemClick(secondAreaItem) {
				this.thirdAreaListOpen = true;
				this.isAreaSelectMainOpen = true;
				this.secondArea = secondAreaItem.name;
				apiGetArea({
					id: secondAreaItem.id
				}).then((res) => {
					this.nativePlace = this.firstArea + this.secondArea + this.thirdArea;
					this.thirdAreaList = res.data;
				});
			},
			//选择籍贯第三级
			thirdAreaListItemClick(thirdAreaItem) {

				this.thirdArea = thirdAreaItem.name;
				this.isAreaSelectMainOpen = false;
				apiGetArea({
					id: thirdAreaItem.id
				}).then((res) => {
					this.nativePlace = this.firstArea + this.secondArea + this.thirdArea;
				});
			},

			changeFirstSelectedItem(item) {
				this.firstSelectedItem = item
			},
			changeSecondSelectedItem(item) {
				this.secondSelectedItem = item
			},
			changeThirdSelectedItem(item) {
				this.thirdSelectedItem = item
			},
			//打开通信地址的三级联动
			addressSelectMainOpen() {
				if (this.userInfo.status !== 1 && this.userInfo.status !== 2) {
					this.isAddressSelectMainOpen = !this.isAddressSelectMainOpen;
				}
			},
			//选择通信地址第一级
			firstAddressListItemClick(firstAddressItem) {
				this.secondAddressListOpen = true;
				this.isAddressSelectMainOpen = true;
				this.firstAddress = firstAddressItem.name;
				this.correspondenceAddress = this.firstAddress + this.secondAddress + this.thirdAddress;
				apiGetArea({
					id: firstAddressItem.id
				}).then((res) => {
					this.secondAddressList = res.data;
				});
			},
			//选择通信地址第二级
			secondAddressListItemClick(secondAddressItem) {
				this.thirdAddressListOpen = true;
				this.isAddressSelectMainOpen = true;
				this.secondAddress = secondAddressItem.name;
				apiGetArea({
					id: secondAddressItem.id
				}).then((res) => {
					this.correspondenceAddress = this.firstAddress + this.secondAddress + this.thirdAddress;
					this.thirdAddressList = res.data;
				});
			},
			//选择通信地址第三级
			thirdAddressListItemClick(thirdAddressItem) {

				this.thirdAddress = thirdAddressItem.name;
				this.isAddressSelectMainOpen = false;
				apiGetArea({
					id: thirdAddressItem.id
				}).then((res) => {
					this.correspondenceAddress = this.firstAddress + this.secondAddress + this.thirdAddress;
				});
			},
			//关闭籍贯和通信地址的下拉列表
			addressPopupClose() {
				this.isAreaSelectMainOpen = false;
				this.isAddressSelectMainOpen = false;
			},
			//查看详细（申报）
			viewDetails(id) {

				this.isDetailsOpen = !this.isDetailsOpen;
				apiGetApplyDetail({
					id: id,
					// token: localStorage.getItem("token")
				}).then((res) => {
					this.applyDetailInfo = res.data;
					this.examinationLocationList = res.data.address;
					this.applyDetailInfoJob = res.data.job;
					this.applyDetailInfoExam = res.data.exam;
					this.examinationLocation = res.data.address[res.data.apply_job_address];
				});
				apiGetUserInfo({
					user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
						localStorage.getItem("exam_id")),
					// token: localStorage.getItem("token")
				}).then((res) => {
					this.supplementaryInformation = res.data.supplement_info;
				})
			},
			//修改补充信息
			changeSupplementaryInformation() {
				apiAddExamUser({
					user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
						localStorage.getItem("exam_id")),
					supplement_info: this.supplementaryInformation
				}).then((res) => {
					Message.info(res.msg);
				})
			},
			//修改考试地
			changeAddress() {

				for (let key in this.examinationLocationList) {
					if (this.examinationLocationList[key] === this.examinationLocation) {
						var examinationLocationId = key
					}
				}
				apiChangeAddress({
					// token: localStorage.getItem("token"),
					id: this.applyDetailInfo.apply_job_id,
					address_id: examinationLocationId
				}).then((res) => {
					Message.info(res.msg);
				})
			},
			//提交申诉
			commitAppeal() {

				apiAppealJob({
					apply_job_id: this.applyDetailInfo.apply_job_id,
					appeal_content: this.reasonsForAppeal,
				}).then((res) => {
					Message.info(res.msg);
				})
			},
			//点击确认报考按钮
			checkApplyJob(id) {
				apiCheckApplyJob({
					id: id,
				}).then((res) => {
					if (res.code === 0) {
						Message.info(res.msg);
					} else {
						this.checkApplyJobBtn = true;
						this.deleteBtn = true;
						this.clickJobId = id;
						this.isCheckApplyJobPopupOpen = true;
						this.postConfirmation = res.data;
					}

				})
			},
			//最终确认报考
			toConfirmApply() {
				this.confirmApplyBtnLoading = true;

				apiSubmitApply({
					id: this.clickJobId,
				}).then((res) => {
					setTimeout(() => {
						this.confirmApplyBtnLoading = false;
					}, 1000)

					this.getUserJobList();
					Message.info(res.msg);
					this.isCheckApplyJobPopupOpen = false;
				})
			},
			//跳转到打印准考证页面
			printAdmissionCard() {


				this.$router.push(
					`/examineeInformation/admissionCard/admissionCard?shop_id=${localStorage.getItem("shop_id")}&&exam_id=${localStorage.getItem("exam_id")}`
				)


			},
			//打开支付选择弹窗
			openPayPopup(id) {
				this.payPopupOpen = true;
				this.applyJobId = id;
				// console.log(this.token)
			},
			// 选择支付
			toPay(type) {
				// this.payPopupOpen = true
				window.location.href = 'http://admin.ptgzrc.com/api/pay/create_order?apply_job_id=' + this.applyJobId +
					'&type=' + type + '&token=' + this.token
				// window.location.href = 'http://admin.ptgzrc.com/api/pay/create_order?apply_job_id=78&type=wechat&token=68508382-5093-4886-b4d5-1d3db7adb30a' + this.token
				// apiCreateOrder({
				//   apply_job_id: this.applyJobId,
				//   type: type,
				//   token: this.token
				// }).then((res) => {
				//   // console.log(res)
				//   Message.info(res.msg)
				// })
			},
			//删除已添加的岗位
			deleteUserJob(id) {
				apiDeleteUserJob({
					id: id,
				}).then((res) => {
					if (res.code === 0) {
						Message.info(res.msg);
					} else {
						Message.info("删除成功");
						apiGetUserJobList({
							user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem(
									"shop_id") + "_" +
								localStorage.getItem("exam_id")),
							shop_id: localStorage.getItem("shop_id")
						}).then((res) => {
							this.registrationPositionList = res.data.list;
							this.changeMainTabsItem(5);
						});
					}

				})
			},
			//调取获取报考岗位列表的接口
			getUserJobList() {
				apiGetUserJobList({
					user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
						localStorage.getItem("exam_id")),
					shop_id: localStorage.getItem("shop_id")
				}).then((res) => {
					this.registrationPositionList = res.data.list;
					this.examInfoStatus = res.data.status.exam_info_status;
					this.pictureStatus = res.data.status.picture_status;
				});
			},
			//跳转到面试列表页面
			toInterviewList() {

				this.$router.push(
					`/interviewAdmissionList/interviewAdmissionList?shop_id=${localStorage.getItem('shop_id')}&&exam_id=${localStorage.getItem('exam_id')}`
				)

			},

			//毕业时间
			changeGraduationTime(date) {
				this.graduationTime = date;
			},
			//工作时间
			changeWorkTime(date) {
				this.workTime = date;
			},
			// changeCurrentPage(index) {
			//   this.currentPage = index + 1;
			// },
			changeUserLogListCurrentPage(index) {
				this.userLogListCurrentPage = index + 1;
				let _page = index + 1;
				apiGetUserLog({
					user_id: localStorage.getItem("user_id" + "_" + localStorage.getItem("shop_id") +
						"_" + localStorage.getItem("exam_id")),
					page: _page
				}).then((res) => {
					this.userLogList = res.data.list;
				});
			},
			//提交留言
			commitMessage() {
				if (this.messageTypeItem === "" || this.messageTypeItem == null) {
					Message.info("留言类型未选择")
					return false;
				}
				if (this.messageContent === "" || this.messageContent == null) {
					Message.info("留言内容未填写")
					return false;
				}
				this.messageTypeList.forEach((item, index) => {
					if (item.name === this.messageTypeItem) {

						apiSaveUserMsg({
							type_id: item.id,
							shop_id: localStorage.getItem("shop_id"),
							exam_id: localStorage.getItem("exam_id"),
							content: this.messageContent,
						}).then((res) => {
							if (res.code == 1) {
								apiGetMsgList({
									user_id: localStorage.getItem("user_id" + "_" + localStorage
										.getItem("shop_id") +
										"_" + localStorage.getItem("exam_id")),
									exam_id: localStorage.getItem("exam_id")
								}).then((res) => {
									this.msgList = res.data;
								});

							}
							Message.info(res.msg);

						})
					}
				})
			},
			//修改密码
			changePassword() {
				apiResetPwdExam({
					exam_id: localStorage.getItem("exam_id"),
					user_id: localStorage.getItem('user_id' + "_" + localStorage.getItem("shop_id") + "_" +
						localStorage.getItem("exam_id")),
					password: this.oldPassword,
					new_password: this.password,
					confirm_password: this.confirmPassword,
				}).then((res) => {
					if (res.code === 0) {
						Message.info(res.msg);
					} else {
						localStorage.removeItem('token' + "_" + localStorage.getItem("shop_id") + "_" +
							localStorage.getItem("exam_id"));

						this.$router.push(
							`/login/login?shop_id=${localStorage.getItem("shop_id")}&&exam_id=${localStorage.getItem("exam_id")}`
						)

					}

				})
			},
			//控制退出登录的弹窗显示
			showLoginOutPopup() {
				this.loginOut = !this.loginOut;
			},
			//跳转到岗位查询页面
			toPostInquiry() {

				this.$router.push(
					`/postInquiry/postInquiry?isAddPost=true&shop_id=${localStorage.getItem("shop_id")}&&exam_id=${localStorage.getItem("exam_id")}`
				)

			},
			//退出登录
			toLoginOut() {
				apiLogout().then((res) => {
					if (res.code === 0) {
						Message.info(res.msg);
					} else {
						localStorage.removeItem('token' + "_" + localStorage.getItem("shop_id") + "_" +
							localStorage.getItem("exam_id"));
						this.$router.push(
							`/login/login?shop_id=${localStorage.getItem("shop_id")}&&exam_id=${localStorage.getItem("exam_id")}`
						)

					}

				})

			},
			//取消退出登录
			cancel() {
				this.loginOut = false;
				this.changeMainTabsItem(0);
				this.backToTop();
			},
			//控制移动端tab是否显示
			changeIstabPopupOpen() {
				this.istabPopupOpen = !this.istabPopupOpen
			},
		},
		destroyed() {
			localStorage.removeItem("mainTabsIndex");
			localStorage.removeItem("isPostInquiryEnter");
			if (this.getWxRwsult) {
				window.clearInterval(this.getWxRwsult);
				this.getWxRwsult = null
			}
		}
	}
</script>

<style lang="less" scoped>
	i:hover {
		cursor: pointer;
	}

	.pos-absolute {
		position: absolute !important;
	}

	.examinee-information {
		padding-bottom: .46rem;

		&-tabs {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			font-size: .18rem;
			color: #333333;
			padding: 0 2rem;
			margin-bottom: .2rem;

			&-item {
				padding: .16rem .03rem;
				border-bottom: .03rem solid #FFFFFF;
			}

			&-item:hover {
				cursor: pointer;
			}

			.item_click {
				color: #F93454;
				border-color: #F93454;
			}
		}

		&-announcement {
			margin-bottom: .17rem;

			&-main {
				width: 100%;
				font-size: .14rem;
				padding: .14rem 2rem;
				color: #F32547;
				background: #b8deff;
			}
		}

		&-title {
			display: flex;
			justify-content: space-between;
			margin: 0 2rem .2rem;
			padding-bottom: .2rem;
			border-bottom: .02rem solid #b1b8c7;

			&-main {
				font-size: .2rem;
				color: #333333;
				font-family: PingFang-SC-Bold;
				padding-left: .2rem;
				border-left: .06rem solid #1f4db6;
			}
		}

		&-tip {
			/*display: flex;*/
			/*flex-direction: column;*/
			/*width: 100%;*/
			/*font-size: .14rem;*/
			padding: .14rem 2rem .16rem;
			margin-bottom: .2rem;
			/*color: #246FB0;*/
			/*background: #fcefe7;*/
		}

		&-content__tab {
			width: 100%;
			overflow-x: auto;
			display: none;

			&-main {
				display: flex;
				font-size: .16rem;
				padding-bottom: .1rem;

				&-item {
					display: flex;
					flex: 1 0 auto;
					justify-content: center;
					align-items: center;
					padding: .02rem 0;
					transform: scale(.9);


					span {
						padding-left: .09rem;
						border-bottom: .02rem solid rgba(255, 255, 255, .1);
					}
				}

				&-item:hover {
					cursor: pointer;
				}

				&-item-active {
					color: #1F4DB6;
					background: #ffffff;

					&>span {
						border-color: #1F4DB6;

					}
				}
			}

		}

		&-main {
			display: flex;
			padding: 0 2rem;

			&-left {
				display: flex;
				flex-direction: column;
				background: #f2faff;
				font-size: .16rem;

				&-item {
					display: flex;
					width: 1.6rem;
					align-items: center;
					padding: .16rem;

					&>img {
						width: .16rem;
						height: .16rem;
					}

					span {
						padding-left: .09rem;
					}
				}

				&-item:hover {
					cursor: pointer;
				}

				&-item-active {
					color: #ffffff;
					background: #1f4db6;
				}
			}

			&-right {
				display: flex;
				padding: 0 .2rem;

				&-photo {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2.36rem;
					height: 3.55rem;
					margin-right: .2rem;
					background-image: url("../../assets/images/examinee_information/photo_bg.png");
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: 100% auto;
					overflow: hidden;

					&>img {
						width: 1.57rem;
						height: 1.74rem;
					}
				}

				&-body {
					display: flex;
					flex-direction: column;
					font-size: .16rem;

					&>div {
						display: flex;
						white-space: nowrap;
						margin-bottom: .12rem;

						&>i {
							color: #1F4DB6;
						}
					}
				}
			}

			.wechat-bind {

				&-binding {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: .2rem .8rem;

					&-img {
						width: 2rem;
						height: 2rem;
						margin-bottom: .5rem;
					}

					&-title {
						font-size: .18rem;
						margin-bottom: .2rem;

						&>span {
							font-size: .14rem;
						}
					}

					&-tip {
						font-size: .14rem;
						margin-bottom: .5rem;

						&>div>span {
							color: #1F4DB6;
						}
					}

					&-btn {
						width: 3rem;
						/*height: .48rem;*/
					}
				}

				&-binded {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: .2rem 1rem 0;

					&>div {
						font-size: .16rem;
						margin-bottom: .1rem;
					}

					&>img {
						width: 1.6rem;
						height: 1.6rem;
						margin-bottom: .5rem;
						border: .01rem solid #CCCCCC;
						padding: 0;
					}
				}
			}


			.information-body {
				display: flex;
				flex-direction: column;
				padding: 0 1.2rem 0 .2rem;

				&-content {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: .06rem 0;
					margin-bottom: .12rem;
					box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

					&-item {
						width: 50%;
						color: #555555;
						padding: .06rem .12rem;
						position: relative;

						&-tip {
							font-size: .16rem;

							&>span {
								color: #FF0000;
							}
						}


						input {
							display: inline-block;
							width: 100%;
							font-size: .12rem;
							color: #666666;
							border: none;
							border-bottom: 1px solid #CCCCCC;
							padding: .12rem 0 .12rem .08rem;
						}

						.area-select-input {
							width: 100%;
							font-size: .12rem;
							min-height: .4rem !important;
							color: #666666;
							border: none;
							border-bottom: 1px solid #CCCCCC;
							padding: .1rem 0 .12rem .08rem;
						}

						@keyframes changeHeight {
							0% {
								height: 0;
								min-width: 0;
								opacity: 0;
							}

							100% {
								height: 1rem;
								min-width: 1rem;
								opacity: 1;
							}
						}

						@-webkit-keyframes changeHeight

						/* Safari 和 Chrome */
							{
							0% {
								height: 0;
								min-width: 0;
								opacity: 0;
							}

							100% {
								height: 1rem;
								min-width: 1rem;
								opacity: 1;
							}
						}

						.area-select-main {
							position: absolute;
							z-index: 2;
							display: flex;
							font-size: .12rem;
							box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
							background: #FFFFFF;
							animation: changeHeight 1s infinite;
							-moz-animation: changeHeight 1s infinite;
							/* Firefox */
							-webkit-animation: changeHeight 1s infinite;
							/* Safari and Chrome */
							-o-animation: changeHeight 1s infinite;
							/* Opera */
							animation-iteration-count: 1;
							animation-fill-mode: forwards;

							&-first {
								flex: 1;
								height: 1rem;
								overflow-y: auto;
								animation: changeHeight 1s infinite;
								-moz-animation: changeHeight 1s infinite;
								/* Firefox */
								-webkit-animation: changeHeight 1s infinite;
								/* Safari and Chrome */
								-o-animation: changeHeight 1s infinite;
								/* Opera */
								animation-iteration-count: 1;
								animation-fill-mode: forwards;

								&-list {
									display: flex;
									flex-direction: column;
									background: #FFFFFF;
									animation: changeHeight 1s infinite;
									-moz-animation: changeHeight 1s infinite;
									/* Firefox */
									-webkit-animation: changeHeight 1s infinite;
									/* Safari and Chrome */
									-o-animation: changeHeight 1s infinite;
									/* Opera */
									animation-iteration-count: 1;
									animation-fill-mode: forwards;

									&-item {
										min-width: 1rem;
										padding: .04rem .08rem;
									}

									&-item:hover {
										background: #f3f3f3;
									}

									.selected {
										color: #2d8cf0;
									}
								}
							}

						}

						.household-register-input {
							width: 100%;
							font-size: .14rem;
							color: #999999;
							border: none;
							border-bottom: 1px solid #CCCCCC;
							padding: .21rem 0 .21rem .08rem;
						}

						.household-register-main {
							position: absolute;
							top: 1.2rem;
							z-index: 2;
							display: flex;
							border: .01rem solid rgba(0, 0, 0, .2);
							box-shadow: 0 .02rem .08rem .01rem rgba(0, 0, 0, .2);

							&-first {
								flex: 1;
								height: 1rem;
								overflow-y: auto;

								&-list {
									display: flex;
									flex-direction: column;
									background: #FFFFFF;

									&-item {
										padding: .02rem;
									}
								}
							}
						}

						.area-select-input,
						.area-select-main,
						.household-register-input,
						.household-register-main:hover {
							cursor: pointer;
						}
					}
				}

				&-resume {
					padding: .14rem .12rem;
					margin-bottom: .12rem;
					box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

					&-title {
						font-size: .16rem;
						color: #666666;
						margin-bottom: .14rem;

						&>span {
							color: #FF0000;
						}

						&>i {
							font-size: .14rem;
						}

						&>em {
							font-size: .14rem;
							color: #1F4DB6;
							padding-left: .25rem;
						}
					}

					&-main {
						width: 100%;

						&>textarea {
							width: 100%;
							min-height: 4.09rem;
							font-size: .14rem;
							color: #666666;
							border-radius: .04rem;
							padding: .06rem;
						}
					}
				}

				&-family {
					padding: .14rem .12rem;
					margin-bottom: .12rem;
					box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

					&-title {
						font-size: .16rem;
						color: #666666;
						margin-bottom: .12rem;

						&>span {
							color: #FF0000;
						}

						&>i {
							font-size: .14rem;
						}

						&>em {
							font-size: .14rem;
							color: #1F4DB6;
							padding-left: .25rem;
						}
					}

					&-tip {
						font-size: .11rem;
						color: #FF0000;
						margin-bottom: .12rem;
					}

					&-main {
						width: 100%;

						&>textarea {
							width: 100%;
							min-height: 4.09rem;
							font-size: .14rem;
							color: #666666;
							border-radius: .04rem;
							padding: .06rem;
						}
					}
				}

				&-achievement {
					padding: .14rem .12rem;
					margin-bottom: .12rem;
					box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

					&-title {
						font-size: .16rem;
						color: #666666;
						margin-bottom: .12rem;

						&>span {
							color: #FF0000;
						}

						&>i {
							font-size: .14rem;
						}

						&>em {
							font-size: .16rem;
							color: #1F4DB6;
							padding-left: .25rem;
						}
					}

					&-main {
						width: 100%;

						&>textarea {
							width: 100%;
							min-height: 4.09rem;
							font-size: .14rem;
							color: #666666;
							border-radius: .04rem;
							padding: .06rem;
						}
					}
				}

				&-btn {
					width: 2.55rem;
					/*height: 1.04rem;*/
					padding-top: .2rem;
					margin: 0 auto;
				}
			}

			.upload-photos {
				display: flex;
				padding: 0 .2rem;

				&-notice {
					display: flex;
					align-items: center;
					height: .3rem;
					font-size: .16rem;
					color: #333333;
					background: #FCEFE7;
					padding: .12rem .13rem;
					margin-bottom: .17rem;
					border-radius: .08rem;

					img {
						width: .17rem;
						height: .17rem;
						margin-right: .07rem;
					}

					&>i {
						color: #246FB0;
					}
				}

				&-left {
					display: flex;
					flex-basis: auto;
					justify-content: center;
					align-items: center;
					width: 2.36rem;
					height: 3.55rem;
					margin-right: .2rem;
					background-image: url("../../assets/images/examinee_information/photo_bg.png");
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: 100% auto;
					overflow: hidden;

					&>img {
						width: 1.57rem;
						height: 1.74rem;
					}
				}


				&-right {
					display: flex;
					flex: 1;
					flex-direction: column;

					&-tip {
						font-size: .16rem;
						color: #333333;
						margin-bottom: 2.37rem;

						&>div {
							white-space: nowrap;
						}
					}

					img {
						width: 3rem;
						/*height: .63rem;*/
						margin: 0 auto;
					}
				}
			}

			.document-upload {
				display: flex;
				flex-direction: column;
				padding: 0 .2rem;

				&-notice {
					display: flex;
					align-items: center;
					height: .3rem;
					font-size: .16rem;
					color: #333333;
					background: #FCEFE7;
					padding: .12rem .13rem;
					margin-bottom: .17rem;
					border-radius: .08rem;

					img {
						width: .17rem;
						height: .17rem;
						margin-right: .07rem;
					}

					&>i {
						color: #246FB0;
					}
				}

				&-item {
					display: flex;
					flex-direction: column;
					padding-bottom: .3rem;

					&-title {
						font-size: .16rem;
						color: #333333;
						padding-bottom: .18rem;
					}

					&-body {
						display: flex;

						img {
							width: 3.2rem;
							height: 1.96rem;
							margin-right: .2rem;
							border: .01rem solid #999999;
						}
					}
				}
			}

			.post-management {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 .2rem;

				&-item {
					display: flex;
					align-items: center;
					width: 100%;
					font-size: .14rem;
					color: #333333;
					background: #FCEFE7;
					padding: .12rem .13rem;
					margin-bottom: .17rem;
					border-radius: .08rem;

					img {
						width: .17rem;
						height: .17rem;
						margin-right: .07rem;
					}

					&>i {
						color: #246FB0;
					}
				}

				&-tip {
					display: flex;
					flex-direction: column;
					width: 100%;
					font-size: .14rem;
					color: #333333;
					background: #FCEFE7;
					padding: .12rem .13rem;
					margin-bottom: .17rem;
					border-radius: .08rem;

					&>i {
						color: #F32547;
					}
				}

				&-main {
					display: flex;
					flex-direction: column;
					font-size: .12rem;
					color: #333333;
					margin-bottom: .17rem;
					border-radius: .08rem;

					&-title {
						padding: .04rem .04rem .2rem;
						font-size: .14rem;
					}

					&-list {
						display: flex;
						flex-direction: column;

						&-item {
							display: flex;
							flex-direction: column;
							/*background: #f2faff;*/
							padding: .1rem;
							margin-bottom: .2rem;
							box-shadow: 0 0.02rem 0.08rem 0.01rem rgba(177, 177, 177, 0.63);


							&-header {
								display: flex;
								flex-wrap: wrap;
								align-items: center;

								&>div {
									margin-right: .2rem;
								}
							}

							&-body {
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								padding: .12rem .08rem .12rem 0;

								&>div {
									margin-right: .2rem;
								}
							}

							&-bottom {
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								padding: .12rem .08rem .12rem 0;
							}
						}
					}


				}

				.view-details {
					&-title {
						font-size: .16rem;
						padding: .12rem .13rem .12rem 0;

					}

					&>table {
						.view-details-tables-tip {
							width: 1.36rem;
							background: #C5EBD8;
						}

						tr {
							td {
								font-size: .14rem;
								padding: .04rem .13rem;

								&>div {
									display: flex;
									align-items: flex-end;

									&>textarea {
										width: 40%;
										font-size: .14rem;
										color: #666666;
										border-radius: .04rem;
										padding: .06rem;
										margin-right: .1rem;
									}
								}

							}
						}

					}

				}

				.pay-popup {
					display: flex;

					&>img {
						width: .1rem;
					}
				}
			}

			.feedback {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 .2rem;

				&-item {
					display: flex;
					align-items: center;
					width: 100%;
					font-size: .16rem;
					color: #333333;
					background: #FCEFE7;
					padding: .12rem .13rem;
					margin-bottom: .17rem;
					border-radius: .08rem;

					img {
						width: .17rem;
						height: .17rem;
						margin-right: .07rem;
					}
				}
			}

			.message-consultation {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 .2rem;

				&-header {
					display: flex;
					align-items: center;
					color: #333333;
					padding-bottom: .3rem;

					&-tip {
						font-size: .14rem;
						padding-right: .14rem;
					}

					&-box {
						border: 1px solid #C6CED4;
						overflow: hidden;
					}
				}

				&-content {
					display: flex;
					color: #333333;
					padding-bottom: .3rem;

					&-tip {
						font-size: .14rem;
						padding-right: .14rem;
					}

					&>textarea {
						flex: 1;
						min-height: 1.4rem;
						font-size: .12rem;
						color: #666666;
						border: 1px solid #C6CED4;
						border-radius: .04rem;
						padding: .08rem;
					}
				}

				&>img {
					width: 1.6rem;
					/*height: .51rem;*/
					margin: 0 auto;
				}

				.message-list {
					display: flex;
					flex-direction: column;
					padding-top: .2rem;
					font-size: .12rem;

					&-item {
						display: flex;
						flex-direction: column;
						/*background: #f2faff;*/
						background: #FCEFE7;
						padding: .1rem;
						margin-bottom: .1rem;
						/*box-shadow: 0 0.02rem 0.08rem 0.01rem rgba(177, 177, 177, 0.63);*/


						&-top {
							display: flex;
							flex-wrap: wrap;
							align-items: center;

							&>span {
								padding-right: .2rem;
							}
						}

						&-reply {
							&>div {
								display: flex;
								align-items: center;
							}
						}
					}
				}
			}

			.operation-record {
				display: flex;
				width: 80%;
				flex-direction: column;
				flex: 1;
				padding: 0 .2rem;

				&-item {
					display: flex;
					flex-direction: column;
					width: 100%;
					font-size: .12rem;
					color: #333333;
					background: #FCEFE7;
					padding: .1rem .13rem;
					/*margin-right: .3rem;*/
					margin-bottom: .1rem;
					/*border-radius: .08rem;*/

					&-top {
						padding-bottom: .1rem;
					}

					&-bottom {
						display: flex;
						font-size: .12rem;

						&>span {
							padding-right: .53rem;
						}
					}
				}
			}

			.change-password {
				display: flex;
				flex-direction: column;
				flex: 1;
				/*padding: .12rem 2.3rem;*/
				/*margin-bottom: 1.48rem;*/
				/*background-image: url("../../assets/images/login/login_bg.png");*/
				/*background-position: 0 0;*/
				/*background-repeat: no-repeat;*/
				/*background-size: 100% auto;*/
				/*overflow: hidden;*/
				/*box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);*/
				padding: .3rem 2rem 0 0.36rem;

				&-main {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;

					&-body {
						display: flex;
						flex-direction: column;
						padding: 0 .28rem .2rem;
						width: 100%;

						&-item {
							display: flex;
							flex-direction: column;
							margin-bottom: .1rem;

							&-tip {
								font-size: .14rem;
								color: #666666;
								padding-left: .06rem;
								padding-bottom: .1rem;
							}

							&-input {
								width: 100%;
								font-size: .12rem;
								padding: .06rem;
								border: .01rem solid #CCCCCC;

								&>input {
									display: inline-block;
									width: 100%;
									color: #666666;
									padding-left: .04rem;
									background: rgba(255, 255, 255, .1);
									border: 0;
								}
							}
						}

					}

					&-btn {
						width: 2rem;
						height: .48rem;
						line-height: .48rem;
						text-align: center;
						font-size: .14rem;
						color: #FFFFFF;
						background: #1f4db6;
						margin: 0 auto;
					}

					&-btn:hover {
						cursor: pointer;
					}
				}
			}

		}

	}

	.tabs-popup {
		display: flex;
		flex-direction: column;

		&-item {
			padding: .4rem .1rem;
			border-bottom: .01rem solid #999999;
		}
	}

	/*-----------------------适配移动端-----------------*/
	@media screen and (max-width: 1024px) {

		.examinee-information {
			overflow: hidden;
			padding: .22rem 0 0;

			.full-height {
				width: 100%;
				height: 38vh;
			}

			.examinee-information-tabs {
				display: none;
			}

			.examinee-information-announcement {
				padding-top: 0;
			}

			.examinee-information-content__tab {
				display: flex;
			}

			&-container,
			.examinee-information-announcement-main,
			.examinee-information-tip {
				padding: .07rem .22rem;
			}

			.examinee-information-container {
				padding: 0;
			}

			.examinee-information-main {
				width: 100%;
				padding: .2rem 0;

				&-top {
					padding-bottom: .4rem;
				}

				.wechat-bind {
					width: 100%;
				}

				.examinee-information-main-left {
					display: none;
				}

				.upload-photos-right-tip {
					&>div {
						white-space: normal;
					}
				}

				.information-body {
					padding: 0 .14rem;
				}

				.change-password {
					padding: .34rem .53rem;
				}
			}

			.message-consultation-header-box {
				width: 50%;
			}

			&-title {
				padding-bottom: .12rem;
				margin: 0 .24rem .2rem;
			}

		}

	}
</style>