<template>
  <div class="page-index">
    <menuListOpen></menuListOpen>

    <!-- <div class="page-index-adv-col" style="left: .1rem;" @click="toAdvListPage">
      <img :src="systemInfo&&systemInfo.adv_left"/>
    </div>
    <div class="page-index-adv-col" style="right: 0.1rem;" @click="toAdvListPage">
      <img :src="systemInfo&&systemInfo.adv_right"/>
    </div> -->
    <!--    tabBar-->
    <div class="page-index-container">
      <div class="page-index-tabs">
        <div v-for="(item,index) in tabs" class="page-index-tabs-item" :class="{item_click:nowIndex==index}"
          @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </div>
    <div class="page-index-adv" @click="toAdvListPage">
      <span style="margin-bottom: 8rpx;">国资人才招聘一体化平台即将全面升级，敬请关注!
      <i></i>
      </span>
      <span style="border: none;">省属及地市国资国企招聘岗位汇总</span>
      <!-- <img :src="systemInfo&&systemInfo.adv_top"/> -->
    </div>

    <!--      网络报名进行中-->
    <div class="page-index-container">
      <div class="container-top">
        <ListTitle :message="'网络报名进行中'"></ListTitle>

        <div v-if="signUpExamList.length !== 0 && signUpExamList !== null" class="container-top-list">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
            <ListItem v-for="(item,index) in signUpExamList" :examStatus="'isSignUp'" :key="index" :listItem="item">
            </ListItem>
          </div>

        </div>
        <NoData v-if="signUpExamListNodata" :dataTip="'暂无数据'"></NoData>
      </div>
    </div>

    <!--    网络报名结束等待打印准考证-->
    <div class="page-index-container">
      <div class="container-top">
        <!--        <div class="container-top-title">网络报名结束等待打印准考证</div>-->
        <ListTitle :message="'网络报名结束等待打印准考证'"></ListTitle>

        <div v-if="printExamList.length !== 0 && printExamList !== null" class="container-top-list">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
            <ListItem v-for="(item,index) in printExamList" :examStatus="'isPrint'" :key="index" :listItem="item">
            </ListItem>
          </div>

        </div>
        <NoData v-if="printExamListNodata" :dataTip="'暂无数据'"></NoData>

      </div>

    </div>


    <!--    已发布成绩-->
    <div class="page-index-container">
      <div class="container-top">
        <!--        <div class="container-top-title">已发布成绩</div>-->
        <ListTitle :message="'已发布成绩'"></ListTitle>

        <div v-if="finishExamList.length !== 0 && finishExamList !== null" class="container-top-list">
          <div class="container-top-list-body">
            <ListItem v-for="(item,index) in finishExamList" :examStatus="'isFinish'" :key="index" :listItem="item">
            </ListItem>
          </div>

        </div>
        <NoData v-if="finishExamListNodata" :dataTip="'暂无数据'"></NoData>

      </div>
    </div>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
          @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import {
    Drawer,
    TabPane,
    Tabs,
    Icon
  } from 'view-design';
  import ListItem from "../../components/ListItem";
  import ListItemScoreInquiry from "../../components/ListItemScoreInquiry";
  import ListTitle from "../../components/ListTitle";
  import PageBtns from "../../components/PageBtns";
  import NoData from "../../components/NoData";
  import menuListOpen from "../../components/menuListOpen";
  import {
    apiGetBottom,
    apiGetExam
  } from "../../request/api";
  import axios from "axios";

  export default {
    components: {
      Tabs,
      TabPane,
      ListItem,
      ListItemScoreInquiry,
      ListTitle,
      NoData,
      Drawer,
      PageBtns,
      Icon,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.indexTabs,
        nowIndex: 0,

        //报名列表
        signUpExamList: [],
        signUpExamListNodata: false,

        //待打印的列表
        printExamList: [],
        printExamListNodata: false,

        //已发布成绩的列表
        finishExamList: [],
        finishExamListNodata: false,

        //报名列表当前页号
        signUpExamCurrentPage: 1,

        //待打印的列表当前页号
        printExamCurrentPage: 1,

        //已发布成绩的列表当前页号
        finishExamCurrentPage: 1,

        //每页大小
        pageSize: 4,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,

        systemInfo: null,
      }
    },
    created() {

      apiGetExam().then((res) => {
        if (res.data.sign_up.length == 0) {
          this.signUpExamListNodata = true;
        }
        if (res.data.print.length == 0) {
          this.printExamListNodata = true;
        }
        if (res.data.finish.length == 0) {
          this.finishExamListNodata = true;
        }
        this.signUpExamList = res.data.sign_up;
        this.printExamList = res.data.print;
        this.finishExamList = res.data.finish;
      });

      var head = document.getElementsByTagName('head')[0];
      var ico;
      // var title;
      //创建一个<link>标签
      var linkTag = document.createElement('link');

      // apiGetBottom().then((res) => {      //    this.systemInfo = res.data;
      //   // ico = res.icon;
      //   //更改标题文字
      //   // title = res.data.unit_name;
      //   // title = "hhhhj";
      //   //配置<link>标签的相关属性
      //   linkTag.setAttribute("rel", "Shortcut Icon")
      //   linkTag.setAttribute("type", "image/x-icon")
      //   // //更改标题文字
      //   document.getElementsByTagName("title")[0].innerText = "";
      //   document.getElementsByTagName("title")[0].innerText = res.data.name;
      //   //配置之前创建<link>标签的引用图标路径
      //   // ico = "./static/logo.png";
      //   ico = res.data.icon;
      //   linkTag.href = ico;
      //   //添加到<head>头部中
      //   head.appendChild(linkTag)
      // })
	  linkTag.setAttribute("rel", "Shortcut Icon")
	  linkTag.setAttribute("type", "image/x-icon")
	  // //更改标题文字
	  document.getElementsByTagName("title")[0].innerText = "";
	  if (localStorage.getItem('page_name')) {
	  	document.getElementsByTagName("title")[0].innerText = localStorage.getItem('page_name');
	  }
	  //配置之前创建<link>标签的引用图标路径
	  // ico = "./static/logo.png";
	  // ico = res.data.icon;
	  // linkTag.href = ico;
	  //添加到<head>头部中
	  head.appendChild(linkTag)
    },
    computed: {
      //获取单页可报名的考试列表
      changeSignUpExamCurrentTables() {
        let {
          signUpExamCurrentPage,
          pageSize
        } = this;
        return this.signUpExamList.slice((signUpExamCurrentPage - 1) * pageSize, signUpExamCurrentPage * pageSize);
      },
      //获取单页可打印准考证的考试列表
      changePrintExamCurrentTables() {
        let {
          printExamCurrentPage,
          pageSize
        } = this;
        return this.printExamList.slice((printExamCurrentPage - 1) * pageSize, printExamCurrentPage * pageSize);
      },
      //获取单页已发布成绩的考试列表
      changeFinishExamCurrentTables() {
        let {
          finishExamCurrentPage,
          pageSize
        } = this;
        return this.finishExamList.slice((finishExamCurrentPage - 1) * pageSize, finishExamCurrentPage * pageSize);
      },
    },
    methods: {
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
      changeTabsIndex(index) {
        this.nowIndex = index;

        switch (index) {
          case 0:
            this.$router.push({
              name: 'index'
            });
            apiGetExam().then((res) => {
              this.signUpExamList = res.data.sign_up;
              this.printExamList = res.data.print;
              this.finishExamList = res.data.finish;
            });
            break;
          case 1:
            this.$router.push({
              name: 'newsInformation'
            })
            break;
          case 2:

            this.$router.push(`/policyList/policyList?isIndexTabEnter=true`);
            break;
          case 3:
            this.$router.push({
              name: 'previousRegistration'
            })
            break;
          case 4:
            this.$router.push({
              name: 'commonProblem'
            })
            break;
          case 5:
            this.$router.push({
              name: 'scoreInquiry'
            })
            break;
          case 6:
            this.$router.push({
              name: 'applicationForUse'
            })
            break;
          case 7:
            this.$router.push({
              name: 'contactUs'
            })
            break;
        }
      },

      toAdvListPage() {
        this.$router.push("/advListPage/advListPage")
      },
      //跳转到当前首页
      toCurrentHome(examItem) {
        localStorage.setItem('exam_id', examItem.id);
        localStorage.setItem('shop_id', examItem.shop_id);

        this.$router.push(`/currentHome/currentHome?shop_id=${examItem.shop_id}&&exam_id=${examItem.exam_id}`)

      },
      //改变可报名考试列表的当前页码
      changeSignUpExamCurrentPage(index) {
        this.signUpExamList = index + 1;
      },
      //改变可打印准考证考试列表的当前页码
      changePrintExamCurrentPage(index) {
        this.printExamCurrentPage = index + 1;
      },
      //改变已发布成绩考试列表的当前页码
      changeFinishExamCurrentPage(index) {
        this.finishExamCurrentPage = index + 1;
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .page-index-adv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: .7rem;
    font-size: .2rem;
    margin: 0.1rem 2rem 0.1rem;
    cursor: pointer;
    border-radius: .15rem;
    overflow: hidden;
    background-image: url("../../assets/images/index/adv_bg2.jpg");
    background-position: 0 0;
    background-repeat: no-repeat;     background-size: 100% 100%;
     padding-right: .1rem;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      // width: 100%;
      // height: .8rem;
      // line-height: .8rem;
      // text-align: center;
      // border-radius: .15rem;
      // overflow: hidden;
      color: #fff;
      letter-spacing: .06rem;
      // border-bottom: .02rem solid #fff;
      // background-color: #9adcfd;
    }

    i{
      display: inline-block;
      width: .3rem;
      height: .01rem;
      background-color: #fff;
      margin-right: .1rem;
    }
  }

  .page-index-adv-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1rem;
    padding: .2rem 0;
    font-size: .3rem;
    position: absolute;
    border-radius: .15rem;
    overflow: hidden;
    top: 0;
    // border-radius: .15rem;
    // background: linear-gradient(180deg,#92c0ef,#ddd4d4);
    z-index: 40;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: .15rem;
      height: 7.6rem;
    }

    // span{
    //   width: .3rem;
    //   text-align: center;
    //   word-break: break-all;
    //   color: #1460ae;
    //   text-shadow: 0.02rem 0.02rem #92c0ef;
    // }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  .setBackground {
    width: 100%;
    background-image: url("../../assets/images/header_bg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    overflow: hidden;
  }

  .page-index {
    position: relative;

    .index-container {
      display: flex;
      justify-content: center;
      color: #333333;
    }

    .page-index-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-header {
      .setBackground();
      height: 2.64rem;
    }

    &-container {
      display: flex;
      justify-content: center;
      color: #333333;
      margin: 0 2rem;
    }
  }

  .container-top {
    width: 100%;
    padding-top: .2rem;


    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      /*height: 1.65rem;*/
      background: #FFFFFF;
      border-radius: 6px;
      padding: .3rem 0;

      &-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &-page-btn {
        display: flex;
        justify-content: center;
        /*align-items: center;*/
        padding: .1rem 0;
      }
    }
  }

  .payment-institutions {
    .setBackground();
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    padding: .2rem 2.84rem;
    background-image: url("../../assets/images/index/payment_institutions_bg.png");


    &-title {
      font-size: .18rem;
    }

    &-list {
      display: flex;
      justify-content: space-around;
      width: 100%;
      font-size: .18rem;
      padding-top: .3rem;


      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-logo {
          width: .6rem;
          height: .6rem;
          margin-bottom: .2rem;
        }
      }
    }
  }

  .recent-hot {
    .setBackground();
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    padding: .2rem 2.84rem;
    background-image: url("../../assets/images/index/recent_hot_bg.png");

    &-list {
      font-size: .18rem;
      padding-top: .3rem;
    }
  }

  .page-index-bottom {
    .setBackground();
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    padding: .24rem 0;
    background-image: url("../../assets/images/index/bottom_bg.png");

    &-main {
      display: flex;
      justify-content: space-between;
      width: 5.25rem;
      font-size: .16rem;
      margin-bottom: .51rem;

      &-left {
        width: 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
          margin-bottom: .19rem;
        }

        &-img {
          width: 1.39rem;
          height: 1.34rem;
          margin-bottom: .17rem;
          background: #FFFFFF;
        }
      }

      &-right {
        width: 1.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
          margin-bottom: .2rem;
        }

        &-list {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &-tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .13rem;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .page-index-adv {
      margin: 0.1rem 0;
    }

    .page-index {

      .page-index-tabs {
        display: none;
      }

      .page-index-container {
        margin: 0;

        .container-top {
          padding-top: .18rem;
        }
      }

      .container-top-list-item-main {
        flex-wrap: wrap;

      }

      .payment-institutions-list-item-logo {
        width: .6rem;
        height: .6rem;
      }

      .payment-institutions,
      .recent-hot {
        padding: .25rem .17rem .28rem;
        background-size: 100% 100%;
      }

      .container-top-list-page-btn {
        padding: .14rem 0;
      }
    }

  }
</style>
