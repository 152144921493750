import { render, staticRenderFns } from "./admissionCard.vue?vue&type=template&id=51e2cf4a&scoped=true&"
import script from "./admissionCard.vue?vue&type=script&lang=js&"
export * from "./admissionCard.vue?vue&type=script&lang=js&"
import style0 from "./admissionCard.vue?vue&type=style&index=0&id=51e2cf4a&prod&lang=less&scoped=true&media=print&"
import style1 from "./admissionCard.vue?vue&type=style&index=1&id=51e2cf4a&prod&media=print&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e2cf4a",
  null
  
)

export default component.exports