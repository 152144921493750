<template>
  <div class="list-item">
    <div class="list-item-title">{{listItem.exam_name}}</div>
    <div class="list-item-main">
      <div>报名时间：{{listItem.sign_up_time+" 至 "+listItem.sign_up_end}}</div>
      <div>打印准考证：{{listItem.printing_time||"待通知"}}</div>
      <div>考试：{{listItem.exam_time||"待通知"}}</div>
    </div>
    <div class="line"></div>
    <div class="list-item-btns">
      <div class="list-item-btns-btn btn-enter" @click="toCurrentHome(examStatus)">进入</div>
      <div class="list-item-btns-btn btn-notice" @click="toRecruitmentNews(examStatus)">公告(方案)</div>
      <div class="list-item-btns-btn btn-post-list" @click="toPostInformationTable(examStatus)">岗位表</div>
      <div class="list-item-btns-btn btn-population-statistics" @click="toCandidates(examStatus)">人数统计</div>
    </div>
  </div>

</template>

<script>
  export default {
    props: [
      'listItem',
      'examStatus',
      'isSignUp',
      'isPrint',
      'isFinish'
    ],
    name: "ListItem",
    data() {
      return {}
    },
    methods: {
      //跳转到当前首页
      toCurrentHome(examStatus) {
        localStorage.setItem('examStatus', examStatus);
        localStorage.setItem('exam_id', this.listItem.id);
        localStorage.setItem('shop_id', this.listItem.shop_id);

        this.$router.push(`/currentHome/currentHome?shop_id=${this.listItem.shop_id}&&exam_id=${this.listItem.id}&examStatus=${examStatus}`)


      },
      //跳转到招聘动态详情
      toRecruitmentNews(examStatus) {
        localStorage.setItem('examStatus', examStatus)

        localStorage.setItem('exam_id', this.listItem.id);
        localStorage.setItem('shop_id', this.listItem.shop_id);
        sessionStorage.setItem('exam_id', this.listItem.id);
        localStorage.setItem('create_time', this.listItem.create_time);

        this.$router.push(`/recruitmentNewsList/recruitmentNews/recruitmentNews?recruitmentNewsId=${this.listItem.id}&Notice=Notice&exam_id=${this.listItem.id}&shop_id=${this.listItem.shop_id}`)
      },
      // 跳转到报考人数统计
      toCandidates(examStatus) {
        localStorage.setItem('examStatus', examStatus);
        localStorage.setItem('exam_id', this.listItem.id);
        localStorage.setItem('shop_id', this.listItem.shop_id);

        this.$router.push(`/candidates/candidates?shop_id=${this.listItem.shop_id}&&exam_id=${this.listItem.id}`)

      },
      // 跳转到岗位信息表
      toPostInformationTable(examStatus) {
        localStorage.setItem('examStatus', examStatus);
        localStorage.setItem('exam_id', this.listItem.id);
        localStorage.setItem('shop_id', this.listItem.shop_id);

        this.$router.push(`/postInformationTable/postInformationTable?shop_id=${this.listItem.shop_id}&&exam_id=${this.listItem.id}`)
      },
    }
  }
</script>

<style lang="less" scoped>
  .list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
    padding: .2rem;
    margin-bottom: .28rem;
    font-size: .12rem;
    color: #636363;
    box-shadow: 0 .02rem .08rem .01rem rgba(177, 177, 177, 0.63);

    &-title {
      font-size: .16rem;
      color: #1F4DB6;
      padding-left: .12rem;
      margin-bottom: .2rem;
      font-family: PingFang-SC-Medium;
    }

    &-main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 .12rem;
      margin-bottom: .2rem;
    }

    .line {
      width: 100%;
      height: .01rem;
      background: #BFBFBF;
      margin-bottom: .2rem;
      box-sizing: border-box;
    }

    &-btns {
      display: flex;
      justify-content: space-between;
      padding: .1rem .34rem;

      &-btn {
        min-width: .7rem;
        /*height: .28rem;*/
        /*line-height: .22rem;*/
        text-align: center;
        padding: .01rem .02rem;
        /*padding: .07rem .09rem;*/
        border: .02rem solid #EB6100;
        border-radius: .03rem;
        cursor: pointer;
      }

      .btn-enter {
        color: #EB6100;
      }

      .btn-enter:hover {
        color: #FFFFFF;
        background: #EB6100;
      }

      .btn-notice {
        /*padding-left: .08rem;*/
        color: #FF2E4C;
        border-color: #EB6100;
      }

      .btn-notice:hover {
        color: #FFFFFF;
        background: #FF2E4C;
      }

      .btn-post-list {
        color: #1F4DB6;
        border-color: #1F4DB6;
      }

      .btn-post-list:hover {
        color: #FFFFFF;
        background: #1F4DB6;
      }

      .btn-population-statistics {
        color: #657FFB;
        border-color: #657FFB;
      }

      .btn-population-statistics:hover {
        color: #FFFFFF;
        background: #657FFB;
      }

      .btn-score-inquiry {
        color: #13CF21;
        border-color: #13CF21;
      }

      .btn-score-inquiry:hover {
        color: #FFFFFF;
        background: #13CF21;
      }

    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1110px) {
    .list-item {
      width: 100%;
      margin-bottom: .12rem;

      .list-item-btns-btn {
        min-width: 1rem;
        height: 0.4rem;
        line-height: 0.34rem;
        border-radius: .08rem;
      }
    }
  }
</style>
