<template>
  <div class="header" @click="toIndex">
    <div class="header-title">
      <!-- <span>福建省国资国企招聘平台</span> -->
      <h1>福建省国有企业招聘考试报名网</h1>
      <h2>({{title}})</h2>
    </div>
    <div class="header-tip">
      <div>公开</div>
      <div>平等</div>
      <div>竞争</div>
      <div>择优</div>
    </div>
  </div>
</template>

<script>
  import {apiGetBottom} from "../request/api";
  export default {
    name: "pageHeader",
    data() {
      return {
        title: '',
        link:'',
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData(){
		  
		  if(localStorage.getItem('page_name')){
			  this.title = localStorage.getItem('page_name');
		  }else{
			  apiGetBottom().then((res) => {
			    this.title = res.data.name;
			  });
		  }
        
      },
      toIndex() {
        this.$router.push({name: 'index'})
      },
    }
  }
</script>

<style lang="less" scoped>
  .header {
    position: relative;
    width: 100%;
    height: 2rem;
    background: linear-gradient(180deg,#92c0ef,#fff);
    overflow: hidden;
    // box-shadow: 0 0.04rem 0.08rem -0.04rem #ccc;
    &-title{
      display: flex;
      flex-direction: column;
      padding: .3rem .6rem 0;
      span{
        font-size: .17rem;
        font-weight: bolder;
        margin-bottom: .1rem;
        // font-family: '楷体';
        color: #1460ae;
        text-shadow: 0.02rem 0.02rem #92c0ef;
      }
      h1{
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: .35rem;
        text-shadow: 0.02rem 0.02rem #92c0ef;
        color: #000;
        margin-bottom: .1rem;
        letter-spacing: .1rem;
      }
      h2{
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: .28rem;
        text-shadow: 0.02rem 0.02rem #92c0ef;
        color: #000;
        margin-bottom: .1rem;
        letter-spacing: .1rem;
      }
    }
    &-tip{
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 6rem;
      font-family: '楷体';
      color:#1460ae;
      div{
        font-size: .28rem;
        font-weight: bold;
        margin: 0 0.32rem;
      }
     }
  }

  @media screen and (max-width: 1024px) {
    .header {
      background-image: url("../assets/images/banner-bg.jpg");
      &-title{
        h1{
          font-size: .3rem;
          font-weight: bold;
        }
      }
      &-tip{
        right: .5rem;
        color: rgba(0,0,0,.6);
      }
    }
  }
</style>
