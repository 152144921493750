<template>
  <div class="recruitment-announcement" v-wechat-title="$route.meta.title">
    <menuListOpen></menuListOpen>
    <Drawer placement="left" :closable="false" v-model="istabPopupOpen">
      <div class="tabs-popup">
        <div v-for="(item,index) in tabs" class="tabs-popup-item" :class="{item_click:nowIndex==index}"
             @click="changeTabsIndex(index)" :key="index">{{item}}
        </div>
      </div>
    </Drawer>

    <!--    ------------------------------------------------------------------------ -->


    <!--    tabBar-->
    <div class="recruitment-announcement-tabs">
      <div v-for="(item,index) in tabs" class="recruitment-announcement-tabs-item" :class="{item_click:nowIndex==index}"
           @click="changeTabsIndex(index)" :key="index">{{item}}
      </div>
    </div>
    <PageTitle :message="'招聘公示'"></PageTitle>
    <div v-if="jobNoticeList.length!==0" class="recruitment-announcement-main">
      <div class="recruitment-announcement-main-item"
           v-for="(item,index) in jobNoticeList"
           :key="index" @click="toRecruitmentAnnouncementItem(item)">
        <div class="recruitment-announcement-main-item-main">◆{{item.title}}
        </div>
        <div class="recruitment-announcement-main-item-time">{{item.create_time}}</div>
      </div>
    </div>
    <NoData v-if="jobNoticeListNodata" :dataTip="'暂无数据'"></NoData>
    <div v-if="jobNoticeList.length!==0" class="recruitment-announcement-box">
      <!--      <Page v-if="jobNoticeList.length!==0" :total="jobNoticeList.length"-->
      <!--            :page-size="4" @on-change="changeCurrentPage"/>-->
      <PageBtns :pageLength="totalPage"
                @changePageNumber="changeCurrentPage"></PageBtns>
    </div>
  </div>
</template>

<script>
  import {Drawer} from 'view-design';
  import {apiGetRecruitmentAnnouncement} from "../../request/api";
  import NoData from "../../components/NoData";
  import PageBtns from "../../components/PageBtns";
  import PageTitle from "../../components/PageTitle";
  import menuListOpen from "../../components/menuListOpen";

  export default {
    components: {
      Drawer,
      PageBtns,
      NoData,
      PageTitle,
      menuListOpen,
    },
    data() {
      return {
        tabs: this.GLOBAL.examHeaderTabs,
        nowIndex: 3,

        //手机端的tab弹窗控制器
        istabPopupOpen: false,
        jobNoticeList: [],
        jobNoticeListNodata: false,

        //当前页号
        currentPage: 1,
        //每页大小
        pageSize: 10,
        //总页数
        totalPage: 0,
        //控制是否增加classname  full-height,解决内容不足，高度不撑满
        fullHeightAdd: false,
      }
    },
    created() {
      this.getRecruitmentAnnouncementList(1);
    },
    mounted() {
      if (document.getElementsByClassName("recruitment-announcement")[0].clientHeight < 400) {
        // this.fullHeightAdd = true
      }
    },
    computed: {
      // changeCurrentTables() {
      //   let {currentPage, pageSize} = this;
      //   return this.jobNoticeList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
      // },
    },
    methods: {
      changeTabsIndex(index) {

        this.nowIndex = index;
        switch (index) {
          case 0:
            this.$router.push({name: 'index'});
            break;
          case 1:
            this.$router.push(`/currentHome/currentHome?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 2:
            this.$router.push(`/recruitmentNewsList/recruitmentNewsList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 3:
            this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncement?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            this.getRecruitmentAnnouncementList(1);
            break;
          case 4:
            localStorage.removeItem("isIndexTabEnter");

            this.$router.push(`/policyList/policyList?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 5:
            this.$router.push(`/postInquiry/postInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 6:
            this.$router.push(`/scoreInquiry/scoreInquiry?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
          case 7:
            this.$router.push(`/login/login?shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)

            break;
        }
      },
      changeCurrentPage(index) {
        this.currentPage = index + 1;
        this.getRecruitmentAnnouncementList(index + 1);
        this.backToTop();
      },
      getRecruitmentAnnouncementList(_page) {
        apiGetRecruitmentAnnouncement({
          shop_id: this.$route.query.shop_id,
          exam_id: this.$route.query.exam_id,
          page: _page
        }).then((res) => {
          if (res.data.list.length == 0 || res.data.list == null) {
            this.jobNoticeListNodata = true;
          }
          this.jobNoticeList = res.data.list;
          this.totalPage = res.data.page
        });
      },
      toRecruitmentAnnouncementItem(item) {

        this.$router.push(`/recruitmentAnnouncement/recruitmentAnnouncementItem/recruitmentAnnouncementItem?recruitmentAnnouncementItemId=${item.id}&shop_id=${this.$route.query.shop_id}&exam_id=${this.$route.query.exam_id}`)
      },
      //控制移动端tab是否显示
      changeIstabPopupOpen() {
        this.istabPopupOpen = !this.istabPopupOpen
      },
    }
  }
</script>

<style lang="less" scoped>
  .recruitment-announcement {
    padding: 0 0 .3rem;

    &-tabs {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      font-size: .18rem;
      color: #333333;
      margin: 0 2rem .3rem;

      &-item {
        padding: .16rem .03rem;
        border-bottom: .03rem solid #FFFFFF;
      }

      &-item:hover {
        cursor: pointer;
      }

      .item_click {
        color: #F93454;
        border-color: #F93454;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem .63rem;

      &-item {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-main {
          width: 7.25rem;
          font-size: .16rem;
          color: #1F4DB6;
          font-family: PingFang-SC-Bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-time {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 auto;
        }
      }
    }

    &-box {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .tabs-popup {
    display: flex;
    flex-direction: column;

    &-item {
      padding: .4rem .1rem;
      border-bottom: .01rem solid #999999;
    }
  }

  /*-----------------------适配移动端-----------------*/
  @media screen and (max-width: 1024px) {
    .full-height {
      height: 50vh;
    }

    .recruitment-announcement {
      padding: .19rem .24rem .32rem;

      &-tabs {
        display: none;
      }

      &-title {
        padding-bottom: .12rem;
        margin-bottom: .1rem;
      }

      .recruitment-announcement-main {
        padding: .17rem 0 .14rem;

        &-item-time {
          /*width: 4rem;*/
          transform: scale(.8);
        }

        .recruitment-announcement-main-item-main {
          width: 4.48rem;
        }
      }

    }

  }
</style>
